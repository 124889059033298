/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import { trigger } from 'redial';
import { createBrowserHistory } from 'history';
import { loadableReady } from '@loadable/component';
// import { CookieStorage } from 'redux-persist-cookie-storage';
// import Cookies from 'cookies-js';
import { createApp, socket } from 'app';
import createStore from 'redux/create';
import apiClient from 'helpers/apiClient';
import localForage from 'localforage';
import routes from 'routes';
import isOnline from 'utils/isOnline';
import asyncMatchRoutes from 'utils/asyncMatchRoutes';
import Provider from 'components/Provider/Provider';
import RouterTrigger from 'components/RouterTrigger/RouterTrigger';
import getPageContext from 'components/Context/createThemeContext';
import closest from 'utils/polyfill/closest';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'npro... Remove this comment to see the full error message
import NProgress from 'nprogress';
import { setCookie, setUser, setToken } from 'utils/auth/helpers';
import cookie from 'js-cookie';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'raf'... Remove this comment to see the full error message
import raf from 'raf';

raf.polyfill();
// Disable auto highlighting
// https://github.com/PrismJS/prism/issues/765
if ((process as any).browser) {
  window.Prism = window.Prism || {};
  (window.Prism as any).manual = true;
}

// polyfill localstorage
const isStorage = () => {
  try {
    return 'localStorage' in window && window.localStorage != null;
  } catch (e) {
    return false;
  }
};

if (!isStorage()) {
  // @ts-expect-error ts-migrate(2540) FIXME: Cannot assign to 'localStorage' because it is a re... Remove this comment to see the full error message
  window.localStorage = {
    _data: {},
    setItem(id: any, val: any) {
      this._data[id] = String(val);
      return this._data[id];
    },
    getItem(id: any) {
      // eslint-disable-next-line no-prototype-builtins
      return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
    },
    removeItem(id: any) {
      return delete this._data[id];
    },
    clear() {
      this._data = {};
      return this._data;
    },
  };
}

NProgress.configure({ trickleSpeed: 200 });

closest(window);

(async () => {
  if (!global.Buffer) {
    const getBuffer = () => import(/* webpackChunkName: 'buffer-polyfill' */ 'buffer');
    global.Buffer = await getBuffer().then((r) =>
      (r as any).__esModule ? r.default.Buffer : r.Buffer
    );
  }

  if (global.Intl) {
    // // Determine if the built-in `Intl` has the locale data we need.
    // if (!areIntlLocalesSupported(localesMyAppSupports)) {
    //     // `Intl` exists, but it doesn't have the data we need, so load the
    //     // polyfill and patch the constructors we need with the polyfill's.
    //     var IntlPolyfill    = require('intl');
    //     Intl.NumberFormat   = IntlPolyfill.NumberFormat;
    //     Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
    // }
  } else {
    // No `Intl`, so use and load the polyfill.
    // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'intl... Remove this comment to see the full error message
    const getIntl = () => import(/* webpackChunkName: 'intl-polyfill' */ 'intl');
    global.Intl = await getIntl().then((r) => (r.__esModule ? r.default : r));
  }

  // import IntlPolyfill from 'intl';
  // import 'intl/locale-data/jsonp/es';
  // Intl.NumberFormat = IntlPolyfill.NumberFormat;
  // Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
  const { locale, messages } = (window as any).App;

  const persistConfig = {
    key: 'root',
    storage: localForage,
    // storage: new CookieStorage(Cookies, {
    //   expiration: {
    //     default: 365 * 86400, // Cookies expire after one year
    //   },
    // }),
    // @ts-expect-error ts-migrate(6133) FIXME: 'inboundState' is declared but its value is never ... Remove this comment to see the full error message
    stateReconciler(inboundState: any, originalState: any) {
      // Ignore state from cookies, only use preloadedState from window object
      return originalState;
    },
    whitelist: ['auth', 'settings', 'info', 'chat'],
  };

  const dest = document.getElementById('content');

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  const app = createApp();
  const restApp = createApp('rest');
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  const client = apiClient();
  const providers = {
    app,
    restApp,
    client,
    intl: { lang: locale, messages },
  };

  const initSocket = () => socket;

  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  global.socket = initSocket();

  const online = (window as any).__data ? true : await isOnline();

  let onlineState = {};
  if (online) {
    socket.open();

    app.on('login', (response) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      setCookie({ app })(response);
      setToken({ client, app, restApp })(response);
      setUser({ app, restApp })(response);
    });

    app.on('logout', () => {
      setToken({ client, app, restApp })({ accessToken: null });
      cookie.remove('feathers-jwt');
    });
    const result = await app
      .reAuthenticate()
      .then((r) => {
        app.set('accessToken', r.accessToken);
        app.set('user', r.user);
        return r;
      })
      .catch((e) => {
        console.log(e, 'error login');
        if (
          e.name === 'NotAuthenticated' &&
          (window as any).__data &&
          (window as any).__data.auth
        ) {
          setToken({ client, app, restApp })({ accessToken: null });
          cookie.remove('feathers-jwt');
          (window as any).__data.auth.user = null;
        }
      });
    if ((window as any).__data && result) {
      (window as any).__data.auth.user = result.user;
    }
  } else {
    onlineState = {
      online: {
        isOnline: false,
      },
    };
  }

  const history = createBrowserHistory();

  const store = createStore({
    history,
    data: {
      ...(window as any).__data,
      ...onlineState,
    },
    helpers: providers,
    persistConfig,
  });

  function sleep(ms: any) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  // eslint-disable-next-line no-async-promise-executor
  await new Promise(async (resolve) => {
    while (!(store as any).isReady) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(10);
    }
    // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
    resolve();
  });

  const prefersDarkMode =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'getState' does not exist on type 'void'.
  const { settings } = store.getState();
  const paletteType =
    // eslint-disable-next-line no-nested-ternary
    settings.uiTheme.paletteType === 'system'
      ? prefersDarkMode
        ? 'dark'
        : 'light'
      : settings.uiTheme.paletteType;

  const pageContext = getPageContext({ ...settings.uiTheme, paletteType });

  const triggerHooks =
    (_routes: any) => async (pathname: string, _history: any, _location: any) => {
      NProgress.start();

      const { components, match, params } = await asyncMatchRoutes(_routes, pathname);
      const triggerLocals = {
        ...providers,
        store,
        match,
        params,
        history: _history || history,
        location: _location || history.location,
      };

      await trigger('inject', components, triggerLocals);

      // Don't fetch data for initial route, server has already done the work:
      if ((window as any).__PRELOADED__) {
        // Delete initial data so that subsequent data fetches can occur:
        delete (window as any).__PRELOADED__;
      } else {
        // Fetch mandatory data dependencies for 2nd route change onwards:
        await trigger('fetch', components, triggerLocals);
      }
      await trigger('defer', components, triggerLocals);

      NProgress.done();
    };

  const hydrate = async (_routes: any) => {
    const element = (
      <Provider store={store} pageContext={pageContext} {...providers}>
        <RouterTrigger trigger={triggerHooks(_routes)} />
      </Provider>
    );

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (dest.hasChildNodes()) {
      ReactDOM.hydrate(element, dest);
    } else {
      ReactDOM.render(element, dest);
    }
  };
  // await Loadable.preloadReady();
  await loadableReady();
  hydrate(routes);
  // Hot reload
  if ((module as any).hot) {
    (module as any).hot.accept('./routes', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRoutes = require('./routes');
      hydrate(nextRoutes.__esModule ? nextRoutes.default : nextRoutes);
    });
  }
  // enable debugger
  // if (process.env.NODE_ENV !== 'production') {
  //   // @ts-expect-error ts-migrate(2686) FIXME: 'React' refers to a UMD global, but the current fi... Remove this comment to see the full error message
  //   window.React = React; // enable debugger
  // }

  // const test = true;
  // Service worker
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__DEVELOPMENT__'.
  if (!__DEVELOPMENT__ && 'serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js', {
        scope: '/',
      });
      registration.onupdatefound = () => {
        // The updatefound event implies that reg.installing is set; see
        // https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
        const installingWorker = registration.installing;

        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        installingWorker.onstatechange = () => {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          switch (installingWorker.state) {
            case 'installed':
              if (navigator.serviceWorker.controller) {
                // At this point, the old content will have been purged and the fresh content will
                // have been added to the cache.
                // It's the perfect time to display a "New content is available; please refresh."
                // message in the page's interface.
                if (console && console.log) {
                  console.log('New or updated content is available.');
                }
              } else if (console && console.log) {
                // At this point, everything has been precached.
                // It's the perfect time to display a "Content is cached for offline use." message.
                console.log('Content is now available offline!');
              }
              break;
            case 'redundant':
              if (console && console.error) {
                console.error('The installing service worker became redundant.');
              }
              break;
            default:
          }
        };
      };
    } catch (error) {
      if (console && console.log) {
        console.log('Error registering service worker: ', error);
      }
    }

    await navigator.serviceWorker.ready;
    if (console && console.log) {
      console.log('Service Worker Ready');
    }
  }
})().catch((e) => {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__DEVELOPMENT__'.
  if (!__DEVELOPMENT__) {
    (window as any).__wm_injectImgEvent(
      `${e.toString()} => ${window.location.host + window.location.pathname}`
    );
  }
});
