const LOAD = 'redux-webmediums/me/translate/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/me/translate/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/me/translate/LOAD_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  result: {},
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: (action as any).result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };
    default:
      return state;
  }
}

export function load(params: any) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: any) => {
      const { timeout } = app.service('translate');
      app.service('translate').timeout = 60000;
      return app
        .service('translate')
        .find({ query: params })
        .then((result: any) => {
          app.service('translate').timeout = timeout;
          return result;
        });
    },
  };
}
