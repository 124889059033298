import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { isLoadePost, loadPost, loadExistPost } from 'redux/modules/stories';
import { isLoadedPublication, getPublication } from 'redux/modules/publications';

const Stories = loadable(() => import(/* webpackChunkName: 'stories' */ './Stories'));

const StoriesComp = (props: any) => {
  return <Stories {...props} />;
};

export default provideHooks({
  fetch: async ({ params, store: { dispatch, getState }, location }: any) => {
    const state = getState();
    const promises = [];

    if (params.publication && !isLoadedPublication(getState(), params.publication)) {
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
      if (!__CLIENT__) {
        promises.push(dispatch(getPublication(params.publication)).catch(() => null));
      } else {
        dispatch(getPublication(params.publication)).catch(() => null);
      }
    }

    if (params.slug) {
      const slugParts = params.slug.split('-');
      const postId = slugParts[slugParts.length - 1];
      const { state: stateLocation } = location;
      if (location?.state?.resetStory === postId || !isLoadePost(state, postId, stateLocation)) {
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
        if (!__CLIENT__) {
          promises.push(dispatch(loadPost(postId)).catch(() => null));
        } else {
          dispatch(loadPost(postId)).catch(() => null);
        }
      } else {
        dispatch(loadExistPost(postId));
      }
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }
  },
})(StoriesComp);
