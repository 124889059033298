// import React from 'react';
import { connectedRouterRedirect } from 'utils/redux-auth-wrapper/history4/redirect';
// import Redirect from 'react-router/Redirect';
import App from 'containers/App/App';
import Home from 'containers/Home/Loadable';
import NotFound from 'containers/NotFound/Loadable';
import Topic from 'containers/Topic/Loadable';
import Login from 'containers/Login/Loadable';
import LoginSuccess from 'containers/LoginSuccess/Loadable';
import Register from 'containers/Register/Loadable';
import Me from 'containers/Me/Loadable';
import MeDashboard from 'containers/Me/Dashboard/Loadable';
import Publication from 'containers/Publication/Loadable';
import MePublications from 'containers/Me/PublicationsLoadable';
import PublicationSettings from 'containers/Publication/SettingsLoadable';
import PublicationAbout from 'containers/Publication/AboutLoadable';
import PublicationLatest from 'containers/Publication/LatestLoadable';
import PublicationArchive from 'containers/Publication/ArchiveLoadable';
import MeStoriesEdit from 'containers/Me/Stories/EditLoadable';
import MeStories from 'containers/Me/Stories/Loadable';
import Stories from 'containers/Stories/Loadable';
// import Coronavirus from 'containers/Coronavirus/Loadable';
// import CoronavirusCountry from 'containers/Coronavirus/LoadableCountry';
import MeTopicsEdit from 'containers/Me/Topics/EditLoadable';
import MeTopics from 'containers/Me/Topics/Loadable';
import MeUsersEdit from 'containers/Me/Users/EditLoadable';
import MeUsers from 'containers/Me/Users/Loadable';
import Tag from 'containers/Tags/TagLoadable';
import Search from 'containers/Search/Loadable';
import ProfileEdit from 'containers/Me/ProfileEditLoadable';
import UserSettings from 'containers/Me/UserSettingsLoadable';
import Profile from 'containers/Me/ProfileLoadable';
import canCreatePublication from '../api/utils/canCreatePublication';
import canAll from '../api/utils/canAll';

const isAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state: any) => !!state.auth.user,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const canCreatePublications = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state: any) => canCreatePublication(state.auth),
  wrapperDisplayName: 'UserCanCreateNew',
  allowRedirectBack: false,
});

const canCreateAll = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state: any) => canAll(state.auth),
  wrapperDisplayName: 'UserCanCreateNew',
  allowRedirectBack: false,
});

const isNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state: any) => state.auth.user === null,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
});

// const redirect = () => <Redirect to="/en/" />;

const MeEditWithPermission = isAuthenticated(MeStoriesEdit);
const MeStoriesAuth = isAuthenticated(MeStories);
const MeEditTopicsWithPermission = canCreateAll(MeTopicsEdit);
const MeTopicsWithPermission = canCreateAll(MeTopics);

const MeEditUsersWithPermission = canCreateAll(MeUsersEdit);
const MeUsersWithPermission = canCreateAll(MeUsers);

const RegisterAuth = isNotAuthenticated(Register);
const LoginSuccessAuth = isAuthenticated(LoginSuccess);

const MeAuth = isAuthenticated(Me);

const PublicationSettingsAuth = canCreatePublications(PublicationSettings);
const PublicationSettingsAuthBasic = isAuthenticated(PublicationSettings);
const ProfileEditAuth = isAuthenticated(ProfileEdit);

const routes = [
  {
    element: <App />,
    path: '/',
    children: [
      // { path: '/',  element: redirect },
      { path: '/', element: <Home /> },
      { path: '/espanol', element: <Home /> },
      { path: '/english', element: <Home /> },
      { path: '/login', element: <Login noFooter /> },
      { path: '/login/:type', element: <Login noFooter /> },
      { path: '/login/:type/:slug', element: <Login noFooter /> },
      { path: '/login-success', element: <LoginSuccessAuth /> },
      { path: '/register', element: <RegisterAuth /> },
      /* this routes not are good is only of the last project */
      { path: '/search', element: <Search /> },
      { path: '/search/:type', element: <Search /> },
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      { path: '/topic/:topic', element: <Topic topicPath="topic" /> },
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      { path: '/tema/:topic', element: <Topic topicPath="tema" /> },
      { path: '/tag/:tag', element: <Tag /> },
      { path: '/s/story/:slug', element: <Stories /> },
      // { path: '/coronavirus', element: <Coronavirus /> },
      // { path: '/coronavirus/:country', element: <CoronavirusCountry /> },
      /* this routes not are good is only of the last project */
      {
        path: '/me',
        element: <MeAuth />,
        children: [
          {
            path: '/me/topics/create',
            element: <MeEditTopicsWithPermission />,
          },
          {
            path: '/me/topics/edit/:uid',
            element: <MeEditTopicsWithPermission />,
          },
          {
            path: '/me/topics',
            element: <MeTopicsWithPermission />,
          },
          {
            path: '/me/users/create',
            element: <MeEditUsersWithPermission />,
          },
          {
            path: '/me/users/edit/:uid',
            element: <MeEditUsersWithPermission />,
          },
          {
            path: '/me/users',
            element: <MeUsersWithPermission />,
          },
          {
            path: '/me/stories/edit/:uid',
            element: <MeEditWithPermission />,
          },
          {
            path: '/me/stories/create',
            element: <MeEditWithPermission />,
          },
          // {
          //   path: '/stories/oldeditor/:uid',
          //   element: MeEditWithPermission,
          //
          // },
          {
            path: '/me/stories/edit/:uid/revert/:revisionuid/:revision',
            element: <MeEditWithPermission />,
          },
          // {
          //   path: '/me/stories/create',
          //   element: canCreatePublications(MeStoriesEdit),
          //
          // },
          {
            path: '/me/stories',
            element: <MeStoriesAuth />,
          },
          {
            path: '/me/stories/:status',
            element: <MeStoriesAuth />,
          },
          {
            path: '/me/publications',
            element: <MePublications />,
          },
          {
            path: '/me/dashboard',
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'classes' is missing in type '{}' but req... Remove this comment to see the full error message
            element: <MeDashboard />,
          },
          {
            path: '/me/settings',
            element: <UserSettings />,
          },
        ],
      },
      {
        path: '/new-publication',
        element: <PublicationSettingsAuth />,
      },
      {
        path: '/@:username',
        element: <Profile />,
      },
      {
        path: '/@:username/edit',
        element: <ProfileEditAuth />,
      },
      {
        path: '/@:username/:slug',
        element: <Stories />,
      },
      {
        path: '/:publication',
        element: <Publication />,
      },
      {
        path: '/:publication/settings',
        element: <PublicationSettingsAuth />,
      },
      {
        path: '/:publication/about',
        element: <PublicationAbout />,
      },
      {
        path: '/:publication/latest',
        element: <PublicationLatest type="latest" />,
      },
      {
        path: '/:publication/trending',
        element: <PublicationLatest type="trending" />,
      },
      {
        path: '/:publication/archive',
        element: <PublicationArchive />,
      },
      {
        path: '/:publication/archive/:year',
        element: <PublicationArchive />,
      },
      {
        path: '/:publication/archive/:year/:month',
        element: <PublicationArchive />,
      },
      {
        path: '/:publication/archive/:year/:month/:day',
        element: <PublicationArchive />,
      },
      {
        path: '/:publication/:slug',
        element: <Stories />,
      },
      {
        path: '/:publication/:feature/home',
        element: <PublicationSettingsAuthBasic />,
      },
      {
        path: '/:publication/stories/edit/:uid',
        element: <MeEditWithPermission />,
      },
      {
        path: '/:publication/stories/create',
        element: <MeEditWithPermission />,
      },
      {
        path: '/:publication/stories',
        element: <MeStoriesAuth />,
      },
      {
        path: '/:publication/stories/:status',
        element: <MeStoriesAuth />,
      },
      { path: '*', element: <NotFound is404 /> },
    ],
  },
];

export default routes;
