import { FORM_ERROR } from 'final-form';
import cookie from 'js-cookie';
import { setCookie, setUser, setToken } from '../../utils/auth/helpers';

const LOAD = 'redux-webmediums/auth/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/auth/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/auth/LOAD_FAIL';
const LOGIN = 'redux-webmediums/auth/LOGIN';
const LOGIN_SUCCESS = 'redux-webmediums/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'redux-webmediums/auth/LOGIN_FAIL';
const REGISTER = 'redux-webmediums/auth/REGISTER';
const REGISTER_SUCCESS = 'redux-webmediums/auth/REGISTER_SUCCESS';
const REGISTER_FAIL = 'redux-webmediums/auth/REGISTER_FAIL';
const LOGOUT = 'redux-webmediums/auth/LOGOUT';
const LOGOUT_SUCCESS = 'redux-webmediums/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'redux-webmediums/auth/LOGOUT_FAIL';

const initialState = {
  loaded: false,
  user: null,
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        accessToken: (action as any).result.accessToken,
        user: (action as any).result.user,
        error: false,
      };
    case LOAD_FAIL: {
      let failParams = {};
      if (
        (action as any).error &&
        ((action as any).error.message === 'invalid signature' ||
          (action as any).error.name === 'NotAuthenticated')
      ) {
        failParams = {
          loaded: true,
          user: false,
          accessToken: false,
        };
      }
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
        ...failParams,
      };
    }
    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        error: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loaded: true,
        accessToken: (action as any).result.accessToken,
        user: (action as any).result.user,
        error: false,
        loginError: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        loginError: (action as any).error,
      };
    case REGISTER:
      return {
        ...state,
        registeringIn: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registeringIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        registeringIn: false,
        registerError: (action as any).error,
      };
    case LOGOUT:
      return {
        ...state,
        loggingOut: true,
        logoutError: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        accessToken: null,
        user: null,
        logoutError: false,
        error: false,
        loginError: false,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: (action as any).error,
      };
    default:
      return state;
  }
}

const capitalize = (s: any) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const catchValidation = (error: any) => {
  if (error.message) {
    if (error.message === 'Validation failed' && error.data) {
      const errorMsg = Object.keys(error.data).map(
        (key) => `${capitalize(key)}: ${error.data[key]} \n`
      );
      const err = {
        [FORM_ERROR]: errorMsg,
      };
      return Promise.reject(err);
    }
    const err = {
      [FORM_ERROR]: error.message,
    };
    return Promise.reject(err);
  }
  return Promise.reject(error);
};

/*
 * Actions
 * * * * */

export function isLoaded(globalState: any) {
  return globalState.auth && globalState.auth.loaded;
}

export function load(force = false) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async ({ app, restApp, client }: any) => {
      const response = force
        ? await app.authentication.reAuthenticate(true)
        : await app.authenticate();
      await setCookie()(response);
      setToken({ client, app, restApp })(response);
      setUser({ app, restApp })(response);
      return response;
    },
  };
}

export function register(data: any) {
  return {
    types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
    promise: ({ app }: any) => app.service('users').create(data).catch(catchValidation),
  };
}

export function login(strategy: any, data: any) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: async ({ client, restApp, app }: any) => {
      try {
        const response = await app.authenticate({
          ...data,
          strategy,
        });
        await setCookie()(response);
        setToken({ client, app, restApp })(response);
        setUser({ app, restApp })(response);
        return response;
      } catch (error) {
        if (strategy === 'local') {
          return catchValidation(error);
        }
        throw error;
      }
    },
  };
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: async ({ client, app, restApp }: any) => {
      try {
        await app.logout();
        setToken({ client, app, restApp })({ accessToken: null });
        cookie.remove('feathers-jwt');
      } catch (e: any) {
        if (e.error === 'NotAuthenticated') {
          setToken({ client, app, restApp })({ accessToken: null });
          cookie.remove('feathers-jwt');
        }
      }
    },
  };
}
