// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import {
  isLoaded as isStoriesLoaded,
  load as loadStories,
  loadPopularStories,
  isLoadePopularStories,
} from 'redux/modules/stories';
// import { load as loadBatch } from 'redux/modules/batch';
import { load as loadTopics, isLoaded as isLoadedTopics } from 'redux/modules/topics';
import loadable from '@loadable/component';

const Home = loadable(() => import(/* webpackChunkName: 'home' */ './Home'));
const HomeContainer = (props: any) => {
  return <Home {...props} />;
};

export default provideHooks({
  fetch: async ({ intl, store: { dispatch, getState }, location }: any) => {
    const state = getState();
    const promises = [];

    const paramsSearchTop: any = {
      status: 'public',
      $skip: 0,
      isFeatured: 0,
      $limit: 6,
      $paginate: false,
      $exclude: ['content'],
      'Publication.slug': [
        'actualidad',
        'news',
        'deportes',
        'sports',
        'salud',
        'health',
        'apple-universe',
        'universo-apple',
      ],
      $sort: {
        publishDate: -1,
      },
    };
    const paramsSearch: any = {
      status: 'public',
      $skip: 0,
      $excludeTopStoriesHome: true,
      isFeatured: 0,
      $limit: 20,
      $paginate: false,
      $exclude: ['content'],
      $sort: {
        publishDate: -1,
      },
    };

    const paramsSearchFeatured: any = {
      publishDate: {
        $gt: new Date(new Date().getTime() - 1 * 86400000),
      },
      status: 'public',
      isFeatured: 1,
      featuredHome: true,
      $skip: 0,
      $limit: 5,
      $paginate: false,
      $exclude: ['content'],
      $sort: { relevance: -1 },
    };

    let lang = false;
    const pathsToLang: any = {
      '/english': 'en',
      '/espanol': 'es',
    };
    if (location.pathname && pathsToLang[location.pathname]) {
      lang = pathsToLang[location.pathname];

      paramsSearchFeatured.locale = lang;
      paramsSearch.locale = lang;
      paramsSearchTop.locale = lang;
    } else {
      paramsSearchTop.$distinct = true;
      paramsSearch.$distinct = true;
      paramsSearchFeatured.$distinct = true;
    }

    paramsSearch.$skip = 0;

    const keyTop = JSON.stringify(paramsSearchTop);
    const key = JSON.stringify(paramsSearch);

    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
    if (!__CLIENT__) {
      // const calls = [loadStories(paramsSearchFeatured, `featuredHome_${lang || ''}`).batchCall];
      // calls.push(loadStories(paramsSearchTop, keyTop).batchCall);
      // calls.push(loadStories(paramsSearch, key).batchCall);
      // calls.push(
      //   loadTopics(
      //     { $sort: { storiesCount: -1 }, $paginate: false, locale: lang || intl.lang },
      //     `featuredHome_${lang || intl.lang}`
      //   ).batchCall
      // );
      // await dispatch(loadBatch(calls));

      if (!isStoriesLoaded(state, `featuredHome_${lang || ''}`)) {
        promises.push(
          dispatch(loadStories(paramsSearchFeatured, `featuredHome_${lang || ''}`)).catch(
            () => null
          )
        );
      }

      if (!isStoriesLoaded(state, keyTop)) {
        promises.push(dispatch(loadStories(paramsSearchTop, keyTop)).catch(() => null));
      }

      if (!isStoriesLoaded(state, key)) {
        promises.push(dispatch(loadStories(paramsSearch, key)).catch(() => null));
      }

      if (!isLoadedTopics(state, `featuredHome_${lang || intl.lang}`)) {
        promises.push(
          dispatch(
            loadTopics(
              { $sort: { storiesCount: -1 }, $paginate: false, locale: lang || intl.lang },
              `featuredHome_${lang || intl.lang}`
            )
          ).catch(console.error)
        );
      }
    } else {
      if (!isStoriesLoaded(state, `featuredHome_${lang || ''}`)) {
        dispatch(loadStories(paramsSearchFeatured, `featuredHome_${lang || ''}`)).catch(() => null);
      }

      if (!isStoriesLoaded(state, keyTop)) {
        dispatch(loadStories(paramsSearchTop, keyTop)).catch(() => null);
      }

      if (!isStoriesLoaded(state, key)) {
        dispatch(loadStories(paramsSearch, key)).catch(() => null);
      }

      if (!isLoadedTopics(state, `featuredHome_${lang || intl.lang}`)) {
        dispatch(
          loadTopics(
            {
              storiesCount: {
                $gte: 10,
              },
              $sort: { storiesCount: -1 },
              $paginate: false,
              locale: lang || intl.lang,
            },
            `featuredHome_${lang || intl.lang}`
          )
        ).catch(() => null);
      }
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }
  },
  defer: async ({ store: { dispatch, getState } }: any) => {
    const state = getState();
    if (!isLoadePopularStories(state)) {
      dispatch(loadPopularStories()).catch(() => null);
    }

    // if (!isStoriesLoaded(state, key)) {
    //   paramsSearch.$skip = 0;
    //   dispatch(loadStories(paramsSearch, key)).catch(() => null);
    // }
  },
})(HomeContainer);
