import { Component } from 'react';
import ReactGA from 'react-ga';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NotFound from 'containers/NotFound/Loadable';

type State = any;

export default class ErrorBoundary extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, location: (props as any).location };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const { location: prevLocation } = prevState;

    if (prevState.hasError && nextProps.location !== prevLocation) {
      return {
        hasError: false,
        location: nextProps.location,
      };
    }

    return {
      location: nextProps.location,
    };
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: false });

    if (process.env.NODE_ENV !== 'production') {
      console.info(error);
      console.info(info);
    } else {
      const { location = {} } = this.props;
      ReactGA.exception({
        description: `${error.toString()} => ${location.pathname}`,
        fatal: true,
      });
    }
  }

  clearError = (e: any) => {
    e.preventDefault();
    const { history } = this.props;
    history.push(`${window.location.pathname}?redirect_for_error=1`);
  };

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Typography align="center" component="div">
            <br />
            <br />
            <Button
              onClick={this.clearError}
              variant="outlined"
              size="large"
              color="secondary"
              component="a"
              href="/?redirect_for_error=1"
            >
              Reload page
            </Button>
          </Typography>
          <NotFound error={{ code: 500 }} />
          <Typography align="center" component="div">
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              component="a"
              href="/?redirect_for_error=1"
              onClick={this.clearError}
            >
              Try to reload the page for clean the error
            </Button>
          </Typography>
        </>
      );
    }
    return this.props.children;
  }
}
