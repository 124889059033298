import { useRef, useState } from 'react';
import Input from '@mui/material/Input';
import SearchIcon from 'components/Icons/Search';
import { alpha } from '@mui/system/colorManipulator';
import { makeStyles } from '@mui/styles';
import classNames from 'clsx';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import type { Theme } from '@mui/material';

const useStyles = makeStyles(
  (theme: Theme) => ({
    inputHaveContent: {},
    root: {
      fontFamily: theme.typography.fontFamily,
      position: 'relative',
      // marginRight: theme.spacing(3),
      marginLeft: theme.spacing(1),
      borderRadius: 2,
      background: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        cursor: 'inherit',
        background: alpha(theme.palette.common.white, 0.25),
      },
      '& $inputInput': {
        cursor: 'pointer',
        transition: theme.transitions.create('width'),
        width: 0,
        '&:focus': {
          cursor: 'inherit',
          width: 220,
        },
        '&$inputHaveContent': {
          cursor: 'inherit',
          width: 220,
        },
      },
    },
    searchIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    search: {
      width: theme.spacing(3),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputInput: {
      padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(3)}`,
      fontSize: 16,
    },
    linkSearc: {
      display: 'flex',
      lineHeight: 1,
    },
  }),
  { name: 'WMSearch' }
);

type OwnProps = {
  placeholder?: string;
};
const defaultProps = {
  placeholder: false,
};
type Props = OwnProps & typeof defaultProps;

const Search = ({ placeholder }: Props) => {
  const classes = useStyles();
  const input = useRef();
  const navigate = useNavigate();

  const [state, setState] = useState({
    inputHaveContent: false,
  });

  const onKeyUp = (event: any) => {
    // if (event.target.value) {
    //   this.input.value = event.target.value.replace(/ /g, '');
    // }
    setState((prev) => ({ ...prev, inputHaveContent: event.target.value !== '' }));
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      navigate(`/search?q=${encodeURIComponent(event.target.value)}`);
    } else if (
      ['/', 's'].indexOf(event.key) !== -1 &&
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.activeElement.nodeName.toLowerCase() === 'body' &&
      document.activeElement !== input.current
    ) {
      event.preventDefault();
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      input.current.focus();
    }
  };

  const intl = useIntl();

  const { inputHaveContent } = state;

  const props = !placeholder
    ? {
        placeholder: intl.formatMessage({
          id: 'searchInWebMediums',
          defaultMessage: 'Search in Web Mediums',
        }),
      }
    : { placeholder };
  const inputClassname = classNames(classes.inputInput, {
    [classes.inputHaveContent]: inputHaveContent,
  });
  return (
    <div className={`${classes.root}`}>
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <div className={classes.search}>
          <SearchIcon className={classes.searchIcon} />
        </div>
        <Input
          type="search"
          disableUnderline
          autoComplete="off"
          onKeyDown={handleKeyDown}
          onKeyUp={onKeyUp}
          id="docsearch-input"
          ref={input}
          classes={{
            input: inputClassname,
          }}
          {...props}
        />
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Link className={classes.linkSearc} to="/search">
          <SearchIcon className={classes.searchIcon} />
        </Link>
      </Box>
    </div>
  );
};

Search.defaultProps = defaultProps;

export default Search;
