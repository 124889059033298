import { Component } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import authManagementReducer from 'redux/modules/account/authManagement';
import loadable from '@loadable/component';
import { getUser } from 'redux/modules/users';
import resolveAuth from 'utils/auth/resolveAuth';

const UserSettings = loadable(
  () => import(/* webpackChunkName: 'user-settings' */ './UserSettings')
);

@provideHooks({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'inject' implicitly has an 'any' t... Remove this comment to see the full error message
  inject: async ({ store: { inject } }) => {
    inject({ authManagement: authManagementReducer });
  },
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'app' implicitly has an 'any' type... Remove this comment to see the full error message
  fetch: async ({ app, store: { getState, dispatch } }) => {
    const state = getState();
    const user = await resolveAuth(state.auth, app);
    if (user) {
      await dispatch(getUser(user.username)).catch(() => null);
    }
  },
})
export default class UserSettingsContainer extends Component {
  render() {
    // @ts-expect-error ts-migrate(2740) FIXME: Type '{ children?: ReactNode; }' is missing the fo... Remove this comment to see the full error message
    return <UserSettings {...this.props} />;
  }
}
