// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import loadable from '@loadable/component';
import meTopicsReducer, { loadTopic } from 'redux/modules/me/topics';

const Edit = loadable(() => import(/* webpackChunkName: 'a-topic-edit' */ './Edit'));

const EditContainer = (props: any) => {
  return <Edit {...props} />;
};

export default provideHooks({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'params' implicitly has an 'any' t... Remove this comment to see the full error message
  fetch: async ({ params, store: { dispatch, inject, asyncReducers } }) => {
    if (!asyncReducers.meTopics) {
      inject({ meTopics: meTopicsReducer });
    }

    const promises = [];

    if (params.uid) {
      promises.push(dispatch(loadTopic(params.uid, { langsFormat: true })).catch(() => null));
    }

    await Promise.all(promises);
  },
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'inject' implicitly has an 'any' t... Remove this comment to see the full error message
  defer: async ({ store: { inject, asyncReducers } }) => {
    if (!asyncReducers.meTopics) {
      inject({ meTopics: meTopicsReducer });
    }
  },
})(EditContainer);
