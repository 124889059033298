const FOLLOW_PUBLICATION = 'redux-webmediums/follow/FOLLOW_PUBLICATION';
const FOLLOW_PUBLICATION_SUCCESS = 'redux-webmediums/follow/FOLLOW_PUBLICATION_SUCCESS';
const FOLLOW_PUBLICATION_FAIL = 'redux-webmediums/follow/FOLLOW_PUBLICATION_FAIL';

const UNFOLLOW_PUBLICATION = 'redux-webmediums/follow/UNFOLLOW_PUBLICATION';
const UNFOLLOW_PUBLICATION_SUCCESS = 'redux-webmediums/follow/UNFOLLOW_PUBLICATION_SUCCESS';
const UNFOLLOW_PUBLICATION_FAIL = 'redux-webmediums/follow/UNFOLLOW_PUBLICATION_FAIL';

const FOLLOW_USER = 'redux-webmediums/follow/FOLLOW_USER';
const FOLLOW_USER_SUCCESS = 'redux-webmediums/follow/FOLLOW_USER_SUCCESS';
const FOLLOW_USER_FAIL = 'redux-webmediums/follow/FOLLOW_USER_FAIL';

const UNFOLLOW_USER = 'redux-webmediums/follow/UNFOLLOW_USER';
const UNFOLLOW_USER_SUCCESS = 'redux-webmediums/follow/UNFOLLOW_USER_SUCCESS';
const UNFOLLOW_USER_FAIL = 'redux-webmediums/follow/UNFOLLOW_USER_FAIL';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case FOLLOW_PUBLICATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case FOLLOW_PUBLICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        publication: { [(action as any).id]: true },
      };
    }
    case FOLLOW_PUBLICATION_FAIL: {
      return {
        ...state,
        loading: false,
        error: (action as any).result,
      };
    }

    case UNFOLLOW_PUBLICATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case UNFOLLOW_PUBLICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        publication: { [(action as any).publication]: false },
      };
    }
    case UNFOLLOW_PUBLICATION_FAIL: {
      return {
        ...state,
        loading: false,
        error: (action as any).result,
      };
    }

    case FOLLOW_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case FOLLOW_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: { [(action as any).id]: true },
      };
    }
    case FOLLOW_USER_FAIL: {
      return {
        ...state,
        loading: false,
        error: (action as any).result,
      };
    }

    case UNFOLLOW_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case UNFOLLOW_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: { [(action as any).user]: false },
      };
    }
    case UNFOLLOW_USER_FAIL: {
      return {
        ...state,
        loading: false,
        error: (action as any).result,
      };
    }
    default:
      return state;
  }
}

export function followPublication(id: any) {
  return {
    types: [FOLLOW_PUBLICATION, FOLLOW_PUBLICATION_SUCCESS, FOLLOW_PUBLICATION_FAIL],
    id,
    promise: ({ app }: any) => app.service('publications/follow').create({ publication: id }),
  };
}

export function unFollowPublication(id: any) {
  return {
    types: [UNFOLLOW_PUBLICATION, UNFOLLOW_PUBLICATION_SUCCESS, UNFOLLOW_PUBLICATION_FAIL],
    id,
    promise: ({ app }: any) => app.service('publications/follow').remove(id),
  };
}

export function followUser(id: any) {
  return {
    types: [FOLLOW_USER, FOLLOW_USER_SUCCESS, FOLLOW_USER_FAIL],
    id,
    promise: ({ app }: any) => app.service('users/follow').create({ user: id }),
  };
}

export function unFollowUser(id: any) {
  return {
    types: [UNFOLLOW_USER, UNFOLLOW_USER_SUCCESS, UNFOLLOW_USER_FAIL],
    id,
    promise: ({ app }: any) => app.service('users/follow').remove(id),
  };
}
