import { forwardRef, useState } from 'react';
// import makeStyles from '@mui/styles/makeStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import MuiPopper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material';

// const useStyles = makeStyles(
//   {
//     popper: {
//       zIndex: 1200,
//       overflowY: 'visible',
//       overflowX: 'visible',
//       maxHeight: 'calc(100% - 82px)',
//       '&[x-placement*="bottom"] $arrow': {
//         top: -7,
//         left: 0,
//         marginTop: '-1em',
//         '&::before': {
//           '-webkit-transform-style': 'rotate(45deg) translate(6px,6px)',
//           transform: 'rotate(45deg) translate(6px,6px)',
//           '-webkit-box-shadow': '-1px -1px 1px -1px rgba(0,0,0,.54)',
//           boxShadow: '-1px -1px 1px -1px rgba(0,0,0,.54)',
//         },
//       },
//       '&[x-placement*="top"] $arrow': {
//         bottom: -7,
//         left: 0,
//         marginBottom: '-1em',
//         '&::before': {
//           '-webkit-transform-style': 'rotate(45deg) translate(-6px, -6px)',
//           transform: 'rotate(45deg) translate(-6px, -6px)',
//           '-webkit-box-shadow': 'rgba(0, 0, 0, 0.54) 1px 1px 1px -1px',
//           boxShadow: 'rgba(0, 0, 0, 0.54) 1px 1px 1px -1px',
//         },
//       },
//       '&[x-placement*="right"] $arrow': {
//         left: 0,
//         marginLeft: '-1em',
//         '&::before': {
//           borderWidth: '1em 1em 1em 0',
//           borderColor: 'transparent #fff transparent transparent',
//         },
//       },
//       '&[x-placement*="left"] $arrow': {
//         right: 0,
//         marginRight: '-1em',
//         '&::before': {
//           borderWidth: '1em 0 1em 1em',
//           borderColor: 'transparent transparent transparent #fff',
//         },
//       },
//     },
//     arrow: {
//       position: 'absolute',
//       fontSize: 7,
//       clip: 'rect(0 18px 14px -4px)',
//       '&::before': {
//         content: '""',
//         display: 'block',
//         width: 14,
//         height: 14,
//         background: '#fff',
//       },
//     },
//   },
//   { name: 'WMMenu' }
// );

const offsetTopModifier =
  (top = 5) =>
  ({ placement }: { placement: string }) => {
    if (placement === 'top') {
      return [0, top];
    }
    return [0, -top];
  };
const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'arrow',
})(({ theme, arrow }: { arrow: boolean; theme: Theme }) => ({
  zIndex: 1200,
  '& > div': {
    position: 'relative',
  },
  '& > div > div:nth-child(2)': {
    maxHeight: '70vh',
    overflow: 'auto',
  },
  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      marginTop: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-1.3em',
      width: '3em',
      height: '1em',
      '&::before': {
        boxShadow: '-1px -1px 1px -1px rgba(0,0,0,.54)',
        transform: 'rotate(45deg) translate(4px,1px)',
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.3em',
      width: '3em',
      height: '1em',
      '&::before': {
        boxShadow: '-1px -1px 1px -1px rgba(0,0,0,.54)',
        transform: 'rotate(-135deg) translate(2px,4px)',
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    '& > div': {
      marginLeft: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      left: 0,
      marginLeft: '-1.3em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    '& > div': {
      marginRight: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      right: 0,
      marginRight: '-1.3em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const Arrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  fontSize: 7,
  clip: 'rect(0 18px 14px -4px)',
  '&::before': {
    content: '""',
    display: 'block',
    width: 14,
    height: 14,
    background: theme.palette.background.paper,
  },
}));

interface MenuProps {
  anchorEl?: null;
  keepMounted?: boolean;
  margin?: number;
  disablePortal?: boolean;
  handleClosePopover: (event: MouseEvent | TouchEvent) => void;
  children: React.ReactElement;
}

const Menu = (
  {
    keepMounted = false,
    anchorEl = null,
    handleClosePopover,
    children,
    margin = 0,
    disablePortal = false,
  }: MenuProps,
  ref: any
): JSX.Element => {
  const [arrowRef, setArrowRef] = useState(null);
  const isOpen = Boolean(anchorEl);
  return (
    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: ({ TransitionProps, placement }:... Remove this comment to see the full error message
    <Popper
      keepMounted={keepMounted}
      // className={classes.popper}
      open={isOpen}
      anchorEl={anchorEl}
      transition
      disablePortal={disablePortal}
      ref={ref}
      modifiers={[
        {
          name: 'offset',
          enabled: true,
          // order: 900,
          options: {
            offset: offsetTopModifier(margin),
          },
        },
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'viewport',
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; id: string; style: { tr... Remove this comment to see the full error message
          id="menu-list-grow"
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <div>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message */}
            <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
            <Paper elevation={1}>
              {!!isOpen && (
                <ClickAwayListener onClickAway={handleClosePopover}>
                  <div>{children}</div>
                </ClickAwayListener>
              )}
            </Paper>
          </div>
        </Grow>
      )}
    </Popper>
  );
};

const MenuForwardRef = forwardRef(Menu);

MenuForwardRef.displayName = 'WMMenu';

export default MenuForwardRef;
