const CLEAR = 'redux-webmediums/tags/CLEAR';

const LOAD = 'redux-webmediums/tags/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/tags/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/tags/LOAD_FAIL';

const LOAD_RELATED = 'redux-webmediums/tags/LOAD_RELATED';
const LOAD_RELATED_SUCCESS = 'redux-webmediums/tags/LOAD_RELATED_SUCCESS';
const LOAD_RELATED_FAIL = 'redux-webmediums/tags/LOAD_RELATED_FAIL';

const initialState = {
  loaded: false,
  result: {},
  related: {},
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case CLEAR:
      return {
        ...state,
        loading: true,
        result: {},
      };
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        result: (action as any).result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };
    case LOAD_RELATED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_RELATED_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        related: { ...state.related, [(action as any).tag]: (action as any).result },
      };
    case LOAD_RELATED_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    default:
      return state;
  }
}

export function find(tag: any) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: any) =>
      app.service('tags').find({
        query: {
          tag: {
            $like: `%${tag}%`,
          },
          $limit: 10,
        },
      }),
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}

export function findRelated(tag: any) {
  return {
    tag,
    types: [LOAD_RELATED, LOAD_RELATED_SUCCESS, LOAD_RELATED_FAIL],
    promise: ({ app }: any) =>
      app.service('tags').find({
        query: {
          relatedTo: tag,
          $limit: 10,
          $paginate: false,
        },
      }),
  };
}
