import { memo } from 'react';
import classNames from 'clsx';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { green, amber } from '@mui/material/colors';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(
  (theme) => {
    return {
      close: {
        width: 35,
        height: 35,
        padding: 0,
      },
      notifs: {
        margin: 20,
      },
      notifText: {
        top: 0,
      },
      snackBarWrapper: {
        lineHeight: 1.4,
        fontSize: 16,
        flexWrap: 'nowrap',
      },
      success: {
        backgroundColor: green[600],
        '& $close': {
          color: '#fff',
        },
      },
      error: {
        backgroundColor: theme.palette.error.main,
        '& $close': {
          color: '#fff',
        },
      },
      info: {
        backgroundColor: '#fff',
        '& $close': {
          color: '#fff',
        },
      },
      warning: {
        backgroundColor: amber[700],
        '& $close': {
          color: '#fff',
        },
      },
      icon: {
        fontSize: 20,
      },
      iconVariant: {
        opacity: 0.9,
        marginRight: 20,
      },
      message: {
        display: 'flex',
        alignItems: 'center',
      },
      text: {
        border: 0,
        justifyContent: 'center',
        lineHeight: 1.4,
        fontSize: 16,
        letterSpacing: 0,
        color: 'rgba(0,0,0,.84)',
        backgroundColor: 'rgba(255,255,255,.97)',
        padding: '12px 25px',
        boxShadow: '0 1px 1px rgba(0,0,0,.25),0 0 1px rgba(0,0,0,.35)',
        '-webkit-box-shadow': '0 1px 1px rgba(0,0,0,.25),0 0 1px rgba(0,0,0,.35)',
        borderRadius: '0 0 4px 4px',
        '-webkit-border-radius': '0 0 4px 4px',
      },
      textSnackBarMessage: {
        padding: 0,
      },
      infoSnackBarMessage: {
        padding: 0,
        color: '#000',
      },
    };
  },
  { name: 'WMNotifComponent' }
);

const NotifComponent = (props: any) => {
  const classes = useStyles();
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const Icon = variantIcon[props.kind];
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const classesSnack = classes[`${props.kind}SnackBarMessage`]
    ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      { message: classes[`${props.kind}SnackBarMessage`] }
    : {};

  // const transform =
  //   props.kind === 'text'
  //     ? false
  //     : `translateY(${props.position.vertical === 'bottom' ? '-' : ''}${
  //         props.notifIndex * 20 + (props.calculateHeights || 0)
  //       }px)`;
  return (
    <Snackbar
      key={`${props.notifId}-snackbaritem`}
      // transform={transform}
      open
      anchorOrigin={props.position}
      className={classNames({
        [classes.notifs]: props.kind !== 'text',
        [classes.notifText]: props.kind === 'text',
      })}
    >
      <SnackbarContent
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        className={classNames(classes.snackBarWrapper, classes[props.kind])}
        classes={classesSnack}
        aria-describedby="client-snackbar"
        message={
          <div className={classes.message}>
            {!!Icon && <Icon className={classNames(classes.icon, classes.iconVariant)} />}
            {props.message}
          </div>
        }
        action={
          // eslint-disable-next-line
            props.actions
            ? props.actions
            : props.action
            ? [
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={() => props.handleCloseNotif(props.notifId)}
                  size="small"
                >
                  <CloseIcon fontSize="small" className={classes.icon} />
                </IconButton>,
              ]
            : null
        }
      />
    </Snackbar>
  );
};

export default memo(NotifComponent, (props, nextProps) => {
  return props.calculateHeights === nextProps.calculateHeights;
});
