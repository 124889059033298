/* eslint-disable no-underscore-dangle */
import type { Theme, Direction } from '@mui/material/styles';
import type { PaletteMode } from '@mui/material';
import { create } from 'jss';
import type { Jss } from 'jss';
// import rtl from 'jss-rtl';
import {
  // createGenerateClassName,
  createTheme as createMuiTheme,
} from '@mui/material/styles';

import { ServerStyleSheets } from '@mui/styles';
import { ServerStyleSheet } from 'styled-components';

import createGenerateClassName from 'utils/styles/createGenerateClassName';

import jssPluginRuleValueFunction from 'jss-plugin-rule-value-function';
import jssPluginGlobal from 'jss-plugin-global';
import jssPluginNested from 'jss-plugin-nested';
import jssPluginCamelCase from 'jss-plugin-camel-case';
import jssPluginDefaultUnit from 'jss-plugin-default-unit';
import jssPluginVendorPrefixer from 'utils/styles/vendor-prefixer';
import jssPluginPropsSort from 'jss-plugin-props-sort';

interface PageContext {
  theme: Theme;
  jss: Jss;
  sheetsCache: Map<any, any>;
  sheetsRegistry: ServerStyleSheets;
}

interface UiTheme {
  direction: Direction;
  paletteType: PaletteMode;
}

export type Shadows = [
  'none',
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

function jssPreset() {
  return {
    plugins: [
      jssPluginRuleValueFunction(),
      jssPluginGlobal(),
      jssPluginNested(),
      jssPluginCamelCase(),
      jssPluginDefaultUnit(),
      // Disable the vendor prefixer server-side, it does nothing.
      // This way, we can get a performance boost.
      // In the documentation, we are using `autoprefixer` to solve this problem.
      typeof window === 'undefined' ? null : jssPluginVendorPrefixer(),
      jssPluginPropsSort(),
    ],
  };
}

const shadowKeyUmbraOpacity = 0.2;
const shadowKeyPenumbraOpacity = 0.14;
const shadowAmbientShadowOpacity = 0.12;

function createShadow(...px: any[]) {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
  ].join(',');
}

// eslint-disable-next-line max-len
// Values from https://github.com/material-components/material-components-web/blob/be8747f94574669cb5e7add1a7c54fa41a89cec7/packages/mdc-elevation/_variables.scss
const shadowsDefault: Shadows = [
  'none',
  createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
  createShadow(0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0),
  createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
  createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
];

shadowsDefault[1] = 'rgba(0, 0, 0, 0.1) 0px 1px 4px';
shadowsDefault[2] = '0 1px 7px rgba(0,0,0,.05)';

const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};
const fontWeightMedium = 400;

const getTheme = (uiTheme: UiTheme): Theme => {
  const theme = createMuiTheme({
    props: {
      MuiWithWidth: {
        // Initial width property
        initialWidth: 'lg', // Breakpoint being globally set 🌎!
      },
    },
    breakpoints,
    direction: uiTheme.direction,
    nprogress: {
      color: uiTheme.paletteType === 'light' ? '#000' : '#fff',
    },
    palette: {
      error: {
        main: '#cc5454',
      },
      primary: {
        main: 'rgba(0,0,0,.84)',
      },
      secondary: {
        // Darken so we reach the AA contrast ratio level.
        main: '#03a87c',
      },
      mode: uiTheme.paletteType,
      action: {
        hover: 'rgba(0,0,0,.05)',
      },
      text: {
        secondary: 'rgba(0,0,0,.68)',
      },
    },
    typography: {
      // Use the system font.
      // eslint-disable-next-line
      fontFamily: [
        'wm-content-sans-serif-font',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        '"Open Sans"',
        '"Helvetica Neue"',
        'sans-serif',
      ]
        .join(',')
        .toString(),
      fontWeightMedium,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ fontFamily: string; fontWeightMedium: numb... Remove this comment to see the full error message
      letterSpacing: 0,
      fontWeight: 700,
      textRendering: 'optimizeLegibility',
      '-webkit-font-smoothing': 'antialiased',
      body1: {
        fontWeight: fontWeightMedium,
        fontSize: 20,
        lineHeight: 1.4,
        letterSpacing: 0,
        color: 'rgba(0,0,0,.84)',
      },
      body2: {
        fontWeight: fontWeightMedium,
        fontSize: 16,
        lineHeight: 1.4,
        letterSpacing: 0,
      },
      h1: {
        fontWeight: 700,
        fontSize: 42,
        fontFamily: [
          'wm-content-sans-serif-font',
          '"Lucida Grande"',
          '"Lucida Sans Unicode"',
          '"Lucida Sans"',
          'Geneva',
          'Arial',
          'sans-serif',
        ]
          .join(',')
          .toString(),
        '--x-height-multiplier': '0.342',
        '--baseline-multiplier': '0.22',
        letterSpacing: '-.015em',
        fontStyle: 'normal',
        fallbacks: [
          {
            letterSpacing: '0',
          },
          {
            letterSpacing: '-.02em',
          },
        ],
        marginLeft: -2.63,
        lineHeight: '1.04',
        paddingTop: '5px',
      },
      h2: {
        fontWeight: 700,
        fontSize: 26,
        fontFamily: [
          'wm-content-sans-serif-font',
          '"Lucida Grande"',
          '"Lucida Sans Unicode"',
          '"Lucida Sans"',
          'Geneva',
          'Arial',
          'sans-serif',
        ]
          .join(',')
          .toString(),
      },
      h3: {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: 1.4,
        fontFamily: [
          'wm-content-sans-serif-font',
          '"Lucida Grande"',
          '"Lucida Sans Unicode"',
          '"Lucida Sans"',
          'Geneva',
          'Arial',
          'sans-serif',
        ]
          .join(',')
          .toString(),
      },
      h4: {
        fontFamily: [
          'wm-content-sans-serif-font',
          '"Lucida Grande"',
          '"Lucida Sans Unicode"',
          '"Lucida Sans"',
          'Geneva',
          'Arial',
          'sans-serif',
        ]
          .join(',')
          .toString(),
      },
      h5: {
        fontFamily: [
          'wm-content-sans-serif-font',
          '"Lucida Grande"',
          '"Lucida Sans Unicode"',
          'Lucida Sans',
          'Geneva',
          'Arial',
          'sans-serif',
        ]
          .join(',')
          .toString(),
      },
      h6: {
        fontFamily: [
          'wm-content-sans-serif-font',
          '"Lucida Grande"',
          '"Lucida Sans Unicode"',
          '"Lucida Sans"',
          'Geneva',
          'Arial',
          'sans-serif',
        ]
          .join(',')
          .toString(),
      },
      caption: {
        fontFamily: [
          'wm-content-sans-serif-font',
          '"Lucida Grande"',
          '"Lucida Sans Unicode"',
          '"Lucida Sans"',
          'Geneva',
          'Arial',
          'sans-serif',
        ]
          .join(',')
          .toString(),
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '16px',
        lineHeight: '20px',
        '--x-height-multiplier': '0.342',
        '--baseline-multiplier': '0.22',
      },
      textSecondary: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    shadows: shadowsDefault,
    components: {
      MuiSnackbar: {
        styleOverrides: {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: string]: ((props: any) => string | fal... Remove this comment to see the full error message
          anchorOriginTopCenter: {
            transform: (props: any) => (props.transform ? `${props.transform}` : false),
            [`@media (min-width:${breakpoints.values.sm}px)`]: {
              transform: (props: any) =>
                props.transform ? `${props.transform} translateX(-50%)` : 'translateX(-50%)',
            },
          },
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: string]: ((props: any) => string | fal... Remove this comment to see the full error message
          anchorOriginBottomCenter: {
            transform: (props: any) => (props.transform ? `${props.transform}` : false),
            [`@media (min-width:${breakpoints.values.sm}px)`]: {
              transform: (props: any) =>
                props.transform ? `${props.transform} translateX(-50%)` : 'translateX(-50%)',
            },
          },
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ transform: (props: any) => string | false;... Remove this comment to see the full error message
          anchorOriginTopRight: {
            transform: (props: any) => (props.transform ? `${props.transform}` : false),
          },
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ transform: (props: any) => string | false;... Remove this comment to see the full error message
          anchorOriginBottomRight: {
            transform: (props: any) => (props.transform ? `${props.transform}` : false),
          },
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ transform: (props: any) => string | false;... Remove this comment to see the full error message
          anchorOriginTopLeft: {
            transform: (props: any) => (props.transform ? `${props.transform}` : false),
          },
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ transform: (props: any) => string | false;... Remove this comment to see the full error message
          anchorOriginBottomLeft: {
            transform: (props: any) => (props.transform ? `${props.transform}` : false),
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          regular: {
            minHeight: 65,
            height: 65,
            '@media screen and (max-width: 767px)': {
              minHeight: 56,
              height: 56,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.error': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.error': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.error': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundImage: 'linear-gradient(to bottom,rgba(49,49,47,.99),#262625)',
            color: '#fff',
            backgroundRepeat: 'repeat-x',
            fontSize: 15,
          },
          arrow: {
            color: 'rgba(49,49,47,.99)',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: 20,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            border: '1px solid rgba(0, 0, 0, 0.1)',
          },
          elevation1: {
            border: '1px solid rgba(0, 0, 0, 0.1)',
          },
          elevation2: {
            border: '1px solid rgba(0,0,0,.04)',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: 16,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: 'rgba(0,0,0,.54)',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // label: {
          //   paddingLeft: 0,
          //   paddingRight: 0,
          // },
          root: {
            padding: '4px 16px',
            minWidth: 0,
            minHeight: 0,
            fontSize: 16,
            // color: 'rgba(0,0,0,.54)',
            textTransform: 'none',
            // '&:hover': {
            //   backgroundColor: 'transparent',
            //   color: 'rgba(0, 0, 0, 0.9)',
            // },
            '@media screen and (max-width: 767px)': {
              padding: '1px 14px',
            },
          },
          // Name of the component ⚛️ / style sheet
          textPrimary: {
            color: 'rgba(0,0,0,.54)',
            '&:hover': {
              background: 'transparent',
              color: 'rgba(0, 0, 0, 0.9)',
              fill: 'rgba(0, 0, 0, 0.9)',
            },
          },
          outlinedPrimary: {
            border: `1px solid ${
              uiTheme.paletteType === 'light' ? 'rgba(0,0,0,.15)' : 'rgba(255, 255, 255, 0.23)'
            }`,
            '&:hover': {
              borderColor: 'rgba(0,0,0,.54)',
              color: 'rgba(0,0,0,.68)',
            },
            '@media screen and (max-width: 767px)': {
              padding: '1px 14px',
            },
          },
          containedPrimary: {
            '&:hover': {
              backgroundColor: '#000',
              borderColor: '#000',
              color: 'rgba(255,255,255,.97)',
            },
          },
        },
      },
    },
  });

  // Expose the theme as a global variable so people can play with it.
  if ((process as any).browser) {
    (window as any).theme = theme;
  }

  return theme;
};
const cache = new Map();
const theme = getTheme({
  direction: 'ltr',
  paletteType: 'light',
});
// Configure JSS
// @ts-expect-error ts-migrate(2322) FIXME: Type '(Plugin | { onProcessRule: (rule: any) => vo... Remove this comment to see the full error message
const jss = create({ plugins: [...jssPreset().plugins] });
(jss as any).options.insertionPoint =
  (process as any).browser && document.getElementById('insertion-point-jss');

function createPageContext() {
  // const theme = getTheme(uitheme);

  const generateClassName = createGenerateClassName({
    productionPrefix: 'j', // Reduce the bandwidth usage.
  });

  return {
    jss,
    theme,
    sheetsCache: cache,
    // This is needed in order to deduplicate the injection of CSS in the page.
    // sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new ServerStyleSheets({ serverGenerateClassName: generateClassName, jss }),
    styledSheetsRegistry: new ServerStyleSheet(),
    generateClassName,
  };
}

export const updatePageContext = (uiTheme: UiTheme): PageContext => {
  const pageContext = {
    // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
    ...global.__MUI_PAGE_CONTEXT__,
    theme: getTheme(uiTheme),
  };
  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  global.__MUI_PAGE_CONTEXT__ = pageContext;

  return pageContext;
};

export default function getPageContext(
  uiTheme = {
    direction: 'ltr',
    paletteType: 'light',
  }
): PageContext {
  uiTheme.paletteType = 'light';
  // Make sure to create a new store for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!(process as any).browser) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    return createPageContext(uiTheme);
  }

  // Reuse context on the client-side
  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  if (!global.__MUI_PAGE_CONTEXT__) {
    // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
    global.__MUI_PAGE_CONTEXT__ = createPageContext(uiTheme);
  }

  // @ts-expect-error ts-migrate(7017) FIXME: Element implicitly has an 'any' type because type ... Remove this comment to see the full error message
  return global.__MUI_PAGE_CONTEXT__;
}
