import { Component } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import loadable from '@loadable/component';
import meUsersReducer, { load as loadUsers } from 'redux/modules/me/users';
import mePaymentsReducer from 'redux/modules/me/payments';

const MeUsers = loadable(() => import(/* webpackChunkName: 'a-users' */ './Users'));

@provideHooks({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'inject' implicitly has an 'any' t... Remove this comment to see the full error message
  inject: ({ store: { inject } }) => {
    inject({ meUsers: meUsersReducer, mePayments: mePaymentsReducer });
  },
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
  fetch: ({ store: { dispatch } }) => {
    const promises = [];

    promises.push(dispatch(loadUsers(0)).catch(() => null));

    return Promise.all(promises);
  },
})
export default class MeUsersContainer extends Component {
  render() {
    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ children?: ReactNode; }' is missing the fo... Remove this comment to see the full error message
    return <MeUsers {...this.props} />;
  }
}
