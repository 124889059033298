import differenceInMinutes from 'date-fns/differenceInMinutes';

const MARK_SUCCESS_LOADING = 'redux-webmediums/stories/MARK_SUCCESS_LOADING';
const LOAD = 'redux-webmediums/stories/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/stories/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/stories/LOAD_FAIL';

const FIND = 'redux-webmediums/stories/FIND';
const FIND_SUCCESS = 'redux-webmediums/stories/FIND_SUCCESS';
const FIND_FAIL = 'redux-webmediums/stories/FIND_FAIL';
const CLEAR = 'redux-webmediums/stories/CLEAR';

const SAVE = 'redux-webmediums/stories/SAVE';
const SAVE_SUCCESS = 'redux-webmediums/stories/SAVE_SUCCESS';
const SAVE_FAIL = 'redux-webmediums/stories/SAVE_FAIL';

const INCREMENT = 'redux-webmediums/stories/INCREMENT';
const INCREMENT_SUCCESS = 'redux-webmediums/stories/INCREMENT_SUCCESS';
const INCREMENT_FAIL = 'redux-webmediums/stories/INCREMENT_FAIL';

const LOAD_POST = 'redux-webmediums/stories/LOAD_POST';
const LOAD_POST_SUCCESS = 'redux-webmediums/stories/LOAD_POST_SUCCESS';
const LOAD_POST_FAIL = 'redux-webmediums/stories/LOAD_POST_FAIL';
const LOAD_EXIST_POST = 'redux-webmediums/stories/LOAD_EXIST_POST';

const LOAD_POPULAR_NEWS = 'redux-webmediums/stories/LOAD_POPULAR_NEWS';
const LOAD_POPULAR_NEWS_SUCCESS = 'redux-webmediums/stories/LOAD_POPULAR_NEWS_SUCCESS';
const LOAD_POPULAR_NEWS_FAIL = 'redux-webmediums/stories/LOAD_POPULAR_NEWS_FAIL';

// @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__SERVER__'.
const storage = __SERVER__ ? null : require('localforage');

const initialState = {
  loaded: false,
  loadedPopularStories: false,
  timeFetch: new Date().getTime(),
  loadedPopularStoriesLastFetch: new Date().getTime(),
  loadedPost: {},
  stories: {},
  resultFind: {},
  errorLoadPost: {},
  post: {},
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case MARK_SUCCESS_LOADING:
      return {
        ...state,
        loading: false,
      };
    case LOAD:
      return {
        ...state,
        id: null,
        error: null,
        loading: true,
      };
    case LOAD_SUCCESS: {
      if ((action as any).skip === 0) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.stories[(action as any).key] = [];
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const stoiresLoaded = state.stories[(action as any).key] || [];

      return {
        ...state,
        key: (action as any).key,
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        timeFetch: { ...state.timeFetch, [(action as any).key]: new Date().getTime() },
        loading: false,
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        loaded: { ...state.loaded, [(action as any).key]: true },
        hasMore: Array.isArray((action as any).result) && (action as any).result.length !== 0,
        hasMoreByKey: {
          ...(state as any).hasMoreByKey,
          [(action as any).key]:
            Array.isArray((action as any).result) && (action as any).result.length !== 0,
        },
        stories: {
          ...state.stories,
          [(action as any).key]: stoiresLoaded.concat((action as any).result),
        },
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        loaded: { ...state.loaded, [(action as any).key]: false },
        key: (action as any).key,
        id: (action as any).id,
        error: (action as any).error,
      };

    case LOAD_POPULAR_NEWS:
      return {
        ...state,
        loadingPopularStories: true,
      };
    case LOAD_POPULAR_NEWS_SUCCESS:
      return {
        ...state,
        loadedPopularStoriesLastFetch: new Date().getTime(),
        loadingPopularStories: false,
        loadedPopularStories: true,
        popular: (action as any).result,
      };
    case LOAD_POPULAR_NEWS_FAIL:
      return {
        ...state,
        loadingPopularStories: false,
        loadedPopularStories: {},
        error: (action as any).error,
      };

    case LOAD_POST:
      return {
        ...state,
        id: (action as any).id,
        loading: true,
      };
    case LOAD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        id: (action as any).id,
        loadedPost: {
          ...state.loadedPost,
          [(action as any).id]: { timeFetch: new Date().getTime() },
        },
        post: { ...state.post, [(action as any).id]: (action as any).result },
      };
    case LOAD_POST_FAIL:
      return {
        ...state,
        loading: false,
        loadedPost: false,
        errorLoadPost: { [(action as any).id]: (action as any).error },
      };
    case LOAD_EXIST_POST:
      return {
        ...state,
        loading: false,
        id: (action as any).id,
      };

    case INCREMENT:
      return {
        ...state,
      };
    case INCREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        // post: { ...state.post, ...action.result }
      };
    case INCREMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: (action as any).error,
      };

    case CLEAR:
      return {
        ...state,
        loading: true,
        resultFind: {},
      };
    case FIND:
      return {
        ...state,
        loading: true,
      };
    case FIND_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        resultFind: (action as any).result,
      };
    case FIND_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    default:
      return state;
  }
}

export function markSuccessLoading() {
  return {
    type: MARK_SUCCESS_LOADING,
  };
}

export function isLoaded(globalState: any, key: any) {
  return (
    globalState.stories &&
    globalState.stories.loaded[key] &&
    differenceInMinutes(new Date().getTime(), globalState.stories.timeFetch[key]) < 3
  );
}

export function load(params: any, key: any) {
  return {
    batchCall: [
      'find',
      'stories/i18n',
      params,
      {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        key,
        skip: params.$skip,
      },
    ],
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    key,
    skip: params.$skip,
    // @ts-expect-error ts-migrate(6133) FIXME: 'dispatch' is declared but its value is never read... Remove this comment to see the full error message
    promise: async ({ app }: any, dispatch: any, getState: any) => {
      const store = getState();
      const fetch = () =>
        app
          .service('stories/i18n')
          .find({
            query: params,
          })
          .then((r: any) => {
            if (storage) {
              storage.setItem(`load-stories-${key}`, r);
            }
            return r;
          })
          .catch(async (r: any) => {
            if (storage && r.type === 'FeathersError' && r.name === 'Timeout' && r.code === 408) {
              const resultCached = await storage.getItem(`load-stories-${key}`);
              if (resultCached) {
                return resultCached;
              }
            }
            throw r;
          });

      if (store.online.isOnline) {
        return fetch();
      }
      if (storage) {
        const resultCached = await storage.getItem(`load-stories-${key}`);
        if (resultCached) {
          return resultCached;
        }
        return fetch();
      }
    },
  };
}

export function save(stories: any, index: any) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    id: stories.id,
    index,
    promise: ({ app }: any) => app.service('stories/i18n').patch(stories.id, stories),
  };
}

export function increment(stories: any) {
  return {
    types: [INCREMENT, INCREMENT_SUCCESS, INCREMENT_FAIL],
    id: stories.id,
    promise: ({ app }: any) => app.service('stories/i18n').patch(stories.id, stories),
  };
}

export function isLoadePost(globalState: any, postId: any, location: any) {
  const resetStory = location && location.resetStory && location.resetStory === postId;
  if (resetStory) {
    return false;
  }
  return (
    globalState.stories &&
    globalState.stories.loadedPost &&
    globalState.stories.loadedPost[postId] &&
    differenceInMinutes(new Date().getTime(), globalState.stories.loadedPost[postId].timeFetch) < 3
  );
}

export function loadPost(postId: any) {
  return {
    types: [LOAD_POST, LOAD_POST_SUCCESS, LOAD_POST_FAIL],
    id: postId,
    // @ts-expect-error ts-migrate(6133) FIXME: 'dispatch' is declared but its value is never read... Remove this comment to see the full error message
    promise: async ({ app }: any, dispatch: any, getState: any) => {
      const store = getState();
      const fetch = () =>
        app
          .service('stories/i18n')
          .get(postId)
          .then((r: any) => {
            if (storage) {
              storage.setItem(`story-${postId}`, r);
            }
            return r;
          })
          .catch(async (r: any) => {
            if (storage && r.type === 'FeathersError' && r.name === 'Timeout' && r.code === 408) {
              const resultCached = await storage.getItem(`story-${postId}`);
              if (resultCached) {
                return resultCached;
              }
            }
            throw r;
          });
      if (store.online.isOnline) {
        return fetch();
      }
      if (storage) {
        const resultCached = await storage.getItem(`story-${postId}`);
        if (resultCached) {
          return resultCached;
        }
        return fetch();
      }
    },
  };
}

export function isLoadePopularStories(globalState: any) {
  return (
    globalState.stories &&
    globalState.stories.loadedPopularStories &&
    globalState.stories.loadedPopularStories &&
    differenceInMinutes(new Date().getTime(), globalState.stories.loadedPopularStoriesLastFetch) <
      30
  );
}

export function loadPopularStories() {
  return {
    types: [LOAD_POPULAR_NEWS, LOAD_POPULAR_NEWS_SUCCESS, LOAD_POPULAR_NEWS_FAIL],
    promise: ({ app }: any) =>
      app.service('stories/i18n').find({
        query: {
          publishDate: {
            $gt: new Date(new Date().getTime() - 10 * 86400000),
          },
          status: 'public',
          $limit: 4,
          $paginate: 'false',
          $exclude: ['content'],
          $sort: { relevance: -1 },
        },
      }),
  };
}

export function loadExistPost(postId: any) {
  return {
    type: LOAD_EXIST_POST,
    id: postId,
  };
}

export function find(search: any, params = {}) {
  return {
    types: [FIND, FIND_SUCCESS, FIND_FAIL],
    promise: ({ app }: any) =>
      app.service('stories/i18n').find({
        query: {
          ...params,
          search,
          $limit: 10,
        },
      }),
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}
