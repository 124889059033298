/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@mat... Remove this comment to see the full error message
import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

export default (props: any) => {
  const Icon = createSvgIcon(
    <path d="M20.067 18.933l-4.157-4.157a6 6 0 1 0-.884.884l4.157 4.157a.624.624 0 1 0 .884-.884zM6.5 11c0-2.62 2.13-4.75 4.75-4.75S16 8.38 16 11s-2.13 4.75-4.75 4.75S6.5 13.62 6.5 11z" />,
    'Search'
  );
  return <Icon {...props} viewBox="0 0 25 25" />;
};
