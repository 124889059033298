// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import loadable from '@loadable/component';
import { getPublication } from 'redux/modules/publications';
import meStoriesReducer, { resetPostLoaded, loadPost } from 'redux/modules/me/stories';
import meStoriesRevisionsReducer, { load as loadRevision } from 'redux/modules/me/revisions';
import translate from 'redux/modules/me/translate';

const Edit = loadable(() => import(/* webpackChunkName: 'a-news-edit' */ './Edit'));
const Middleware = (props: any) => {
  return <Edit {...props} />;
};
export default provideHooks({
  fetch: async ({ params, store: { dispatch, inject, getState, asyncReducers } }: any) => {
    if (!asyncReducers.meStories && !asyncReducers.meStoriesRevisions) {
      inject({
        meStories: meStoriesReducer,
        meStoriesRevisions: meStoriesRevisionsReducer,
        meTranslate: translate,
      });
    } else {
      if (!asyncReducers.meStories) {
        inject({ meStories: meStoriesReducer, meTranslate: translate });
      }

      if (!asyncReducers.meStoriesRevisions) {
        inject({ meStoriesRevisions: meStoriesRevisionsReducer });
      }
    }

    const promises = [];

    if (params.publication) {
      promises.push(dispatch(getPublication(params.publication)).catch(() => null));
    }

    if (params.revision) {
      promises.push(dispatch(loadPost(params.uid)).catch(() => null));
      promises.push(
        dispatch(loadRevision(params.revisionuid, params.revision, params.uid)).catch(() => null)
      );
    } else if (params.uid) {
      promises.push(dispatch(loadPost(params.uid)).catch(() => null));
    } else {
      const state = getState();
      if (state.meStories && state.meStories.post && state.meStories.post.uid) {
        promises.push(dispatch(resetPostLoaded()));
      }
    }

    await Promise.all(promises);
  },
  defer: async ({ store: { inject, asyncReducers } }: any) => {
    if (!asyncReducers.meStories && !asyncReducers.meStoriesRevisions) {
      inject({
        meStories: meStoriesReducer,
        meStoriesRevisions: meStoriesRevisionsReducer,
        meTranslate: translate,
      });
    } else {
      if (!asyncReducers.meStories) {
        inject({ meStories: meStoriesReducer, meTranslate: translate });
      }

      if (!asyncReducers.meStoriesRevisions) {
        inject({ meStoriesRevisions: meStoriesRevisionsReducer });
      }
    }
  },
})(Middleware);
