const CLEAR = 'redux-webmediums/users/CLEAR';

const LOAD = 'redux-webmediums/users/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/users/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/users/LOAD_FAIL';

const FIND = 'redux-webmediums/users/FIND';
const FIND_SUCCESS = 'redux-webmediums/users/FIND_SUCCESS';
const FIND_FAIL = 'redux-webmediums/users/FIND_FAIL';

const GET_USER = 'redux-webmediums/users/GET_USER';
const GET_USER_SUCCESS = 'redux-webmediums/users/GET_USER_SUCCESS';
const GET_USER_FAIL = 'redux-webmediums/users/GET_USER_FAIL';

const SAVE = 'redux-webmediums/users/SAVE';
const SAVE_SUCCESS = 'redux-webmediums/users/SAVE_SUCCESS';
const SAVE_FAIL = 'redux-webmediums/users/SAVE_FAIL';

const initialState = {
  loaded: false,
  result: {},
  resultByKey: {},
  usersLoaded: {},
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case CLEAR: {
      const extra = (action as any).key ? { resultByKey: { [(action as any).key]: {} } } : {};
      return {
        ...state,
        loading: true,
        result: {},
        ...extra,
      };
    }
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS: {
      const extra = (action as any).key
        ? { resultByKey: { [(action as any).key]: (action as any).result } }
        : {};
      return {
        ...state,
        loading: false,
        loaded: true,
        result: (action as any).result,
        ...extra,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    case FIND:
      return {
        ...state,
        loading: true,
      };
    case FIND_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        resultFind: (action as any).result,
      };
    case FIND_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };
    case GET_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        usersLoaded: { ...state.usersLoaded, [(action as any).username]: true },
        users: { ...(state as any).users, [(action as any).username]: (action as any).result },
      };
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        usersLoaded: { ...state.usersLoaded, [(action as any).username]: false },
        error: (action as any).error,
      };

    case SAVE:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        resultSave: (action as any).result,
      };
    case SAVE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    default:
      return state;
  }
}

export function find(user: any, key: any) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    key,
    promise: ({ app }: any) =>
      app.service('users').find({
        query: {
          username: {
            $like: `%${user}%`,
          },
          $limit: 10,
        },
      }),
  };
}

export function isUserLoaded(globalState: any, username: any) {
  return globalState.users && globalState.users.usersLoaded[username];
}

export function getUser(username: any, params = {}) {
  return {
    username,
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAIL],
    promise: ({ app }: any) =>
      app
        .service('public/users')
        .find({
          query: {
            username,
            $paginate: false,
            $limit: 1,
            ...params,
          },
        })
        .then((r: any) => (r ? r[0] : false)),
  };
}

export function findUsers(params: any) {
  return {
    types: [FIND, FIND_SUCCESS, FIND_FAIL],
    promise: ({ app }: any) =>
      app.service('public/users').find({
        query: {
          $limit: 10,
          ...params,
        },
      }),
  };
}

export function save(data: any) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: ({ app }: any) => app.service('users').patch(data.uid, data),
  };
}

export function clear(key = undefined) {
  return {
    type: CLEAR,
    key,
  };
}
