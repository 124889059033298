interface Config {
  [x: string]: any;

  development?: {
    domain: string;
    domainIframe: string;
    domainImages: string;
    isProduction: boolean;
    assetsPath: string;
  };
  production?: {
    isProduction: true;
    assetsPath: string;
    domain: string;
    domainIframe: string;
    domainImages: string;
  };

  host: string;
  port: string | number | undefined;
  apiHost: string | number;
  apiPort: string | number | undefined;
  apiEditorPort: string | number | undefined;
  app: {
    title: string;
    description: string;
    head: {
      titleTemplate: string;
      defaultTitle: string;
      link: [{ type: string; rel: string; href: string }];
      meta: [
        { name: 'description'; content: 'WebMediums - Storeis And More.' },
        { charset: 'utf-8' },
        { property: 'fb:pages'; content: '2198307663748082' },
        { property: 'fb:app_id'; content: '296111991114313' },
        { property: 'og:locale'; content: 'en_US' },
        { property: 'og:type'; content: 'website' },
        { property: 'og:site_name'; content: 'WebMediums - Stories And More' },
        {
          property: 'og:image';
          content: 'https://webmediums.com/media/max_1200/1*NaXapbTluUAlP1Xmqib5mQ.png';
        },
        // eslint-disable-next-line max-len
        // { property: 'twitter:image:src', content: 'https://webmediums.com/media/max_1200/1*NaXapbTluUAlP1Xmqib5mQ.png' },
        { property: 'og:locale'; content: 'en_US' },
        { property: 'og:title'; content: 'WebMediums - Stories And More' },
        { property: 'og:description'; content: 'WebMediums - Stories And More.' },
        // { property: 'og:card', content: 'summary' }
        { property: 'twitter:site'; content: '@WebMediums' },
        { property: 'og:site'; content: '@WebMediums' },
        { property: 'og:creator'; content: '@WebMediums' }
        // { property: 'og:image:width', content: '200' },
        // { property: 'og:image:height', content: '200' }
      ];
    };
  };
}

const localhost = '127.0.0.1';
// const localhost = '192.168.1.85';

const environment = {
  development: {
    domain: `http://${localhost}:3000`,
    domainIframe: `http://${localhost}:3000`,
    domainImages: `http://${localhost}:3000`,
    isProduction: false,
    assetsPath: `http://${process.env.HOST || localhost}:${
      process.env.PORT ? +process.env.PORT + 1 : 3001
    }/dist/`,
    apiHostEditor: `ws://${process.env.APIHOST || localhost}:3333`,
    apiEditorPort: 3333,
  },
  production: {
    isProduction: true,
    assetsPath: '/dist/',
    domain: 'https://webmediums.com',
    domainIframe: 'https://iframe.webmediums.com',
    domainImages: 'https://webmediums.com',
    apiHostEditor: 'wss://webmediums.com/ws-editor',
    apiEditorPort: 3339,
  },
}[process.env.NODE_ENV || 'production'];

const config: Config = {
  host: process.env.HOST || localhost,
  port: process.env.PORT,
  apiHost: process.env.APIHOST || localhost,
  apiPort: process.env.APIPORT,
  apiEditorPort: 3333,
  app: {
    title: 'WebMediums',
    description: 'WebMediums - Stories And More',
    head: {
      titleTemplate: '%s',
      defaultTitle: 'WebMediums',
      link: [{ type: 'text/plain', rel: 'author', href: 'https://webmediums.com/humans.txt' }],
      meta: [
        { name: 'description', content: 'WebMediums - Storeis And More.' },
        { charset: 'utf-8' },
        { property: 'fb:pages', content: '2198307663748082' },
        { property: 'fb:app_id', content: '296111991114313' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'WebMediums - Stories And More' },
        {
          property: 'og:image',
          content: 'https://webmediums.com/media/max_1200/1*NaXapbTluUAlP1Xmqib5mQ.png',
        },
        // eslint-disable-next-line max-len
        // { property: 'twitter:image:src', content: 'https://webmediums.com/media/max_1200/1*NaXapbTluUAlP1Xmqib5mQ.png' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:title', content: 'WebMediums - Stories And More' },
        { property: 'og:description', content: 'WebMediums - Stories And More.' },
        // { property: 'og:card', content: 'summary' }
        { property: 'twitter:site', content: '@WebMediums' },
        { property: 'og:site', content: '@WebMediums' },
        { property: 'og:creator', content: '@WebMediums' },
        // { property: 'og:image:width', content: '200' },
        // { property: 'og:image:height', content: '200' }
      ],
    },
  },
};

Object.assign(config, environment);

export default config;
