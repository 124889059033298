import {
  createStore as _createStore,
  applyMiddleware,
  compose,
  combineReducers,
  Action,
} from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { EditorsState } from '../redux/modules/editor';
import clientMiddleware from './middleware/clientMiddleware';
import createReducers from './reducer';

function combine(reducers: any, persistConfig: any) {
  if (persistConfig) {
    return persistCombineReducers(persistConfig, reducers);
  }
  return combineReducers(reducers);
}

export function inject(store: any, reducers: any, persistConfig: any) {
  Object.keys(reducers).forEach((name) => {
    const reducer = reducers[name];
    if (!store.asyncReducers[name]) {
      store.asyncReducers[name] = reducer.__esModule ? reducer.default : reducer;
    }
  });

  store.replaceReducer(combine(createReducers(store.asyncReducers), persistConfig));
}

function getNoopReducers(reducers: any, data: any) {
  if (!data) {
    return {};
  }

  return Object.keys(data).reduce((accu, key) => {
    if (reducers[key]) {
      return accu;
    }

    return {
      ...accu,
      [key]: (state = data[key]) => state,
    };
  }, {});
}

function createStore({ data, helpers, persistConfig }: any) {
  const middleware = [clientMiddleware(helpers)];

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
  if (__CLIENT__ && __DEVELOPMENT__) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const logger = require('redux-logger').createLogger({
      collapsed: true,
    });
    middleware.push(logger.__esModule ? logger.default : logger);
  }

  const finalCreateStore = compose(
    applyMiddleware(...middleware),
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
    __CLIENT__ && __DEVTOOLS__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (v: any) => v
  )(
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    _createStore
  );
  const staticReducers = createReducers();
  const noopReducers = getNoopReducers(staticReducers, data);
  const store: any = finalCreateStore(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Reducer<PersistPartial, Action<a... Remove this comment to see the full error message
    combine({ ...noopReducers, ...staticReducers }, persistConfig),
    data
  );

  (store as any).asyncReducers = {};
  (store as any).inject = (_reducers: any) => inject(store, _reducers, persistConfig);
  if ((process as any).browser) {
    persistStore(store, {}, () => {
      (store as any).isReady = true;
    });
  }

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__DEVELOPMENT__'.
  if (__DEVELOPMENT__ && (module as any).hot) {
    (module as any).hot.accept('./reducer', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      let reducer = require('./reducer');
      reducer = combine(
        (reducer.__esModule ? reducer.default : reducer)(store.asyncReducers),
        persistConfig
      );
      store.replaceReducer(reducer);
    });
  }

  return store;
}

export default createStore;

interface AppAction extends Action {
  types?: string[];
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = {
  auth: any;
  online: any;
  settings: any;
  notifs: any;
  notifier: any;
  stories: any;
  tags: any;
  users: any;
  publications: any;
  follow: any;
  claps: any;
  tools: any;
  topics: any;
  meStories: any;
  meStoriesRevisions: any;
  editor: EditorsState;
};

type ActionFunction = () => any;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = (action: Partial<AppAction> | ActionFunction) => Promise<any>;
