import type { User } from '../database/Models';

const canCreatePublication = (auth: { loaded: boolean; user: User }): boolean => {
  const result =
    auth.loaded &&
    auth.user &&
    (auth.user.UserRole.role === 'admin' || auth.user.UserRole.role === 'editor');
  return result;
};

export default canCreatePublication;
