/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@mat... Remove this comment to see the full error message
import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

export default (props: any) => {
  const Icon = createSvgIcon(
    <path
      d="M365,526l-22.84-49.21a12.47,12.47,0,0,0-4.89-5.46,13.82,13.82,0,0,0-14,0,10.89,10.89,0,0,0-4.61,5.46l-12.62,29.92-13.47-29.92a11.78,11.78,0,0,0-4.68-5.46,13,13,0,0,0-6.95-1.92c-0.27,0-.51.09-0.78,0.1s-0.37-.1-0.57-0.1a10,10,0,0,0-5.74,1.77,9.75,9.75,0,0,0-1.65,1.57,11.73,11.73,0,0,0-3,4l-13.87,29.71-12.86-29.71a10.87,10.87,0,0,0-4.4-5.39,11.94,11.94,0,0,0-12.76,0,10.81,10.81,0,0,0-4.4,5.39l-13.76,32.62-13.47-32.76a10.83,10.83,0,0,0-4.18-5.46,11.18,11.18,0,0,0-6.17-1.77,13.41,13.41,0,0,0-8.72,3.19,9.79,9.79,0,0,0-3.9,7.87,10.56,10.56,0,0,0,1,4.54l22.84,49.21a12.47,12.47,0,0,0,4.89,5.46,13.82,13.82,0,0,0,14,0,10.89,10.89,0,0,0,4.61-5.46l12.62-29.92,13.47,29.92a11.78,11.78,0,0,0,4.68,5.46,13,13,0,0,0,6.95,1.92c0.27,0,.51-0.09.78-0.1s0.37,0.1.57,0.1a10,10,0,0,0,5.74-1.77,9.75,9.75,0,0,0,1.65-1.57,11.73,11.73,0,0,0,3-4l13.87-29.71,12.86,29.71a10.87,10.87,0,0,0,4.4,5.39,11.94,11.94,0,0,0,12.76,0,10.81,10.81,0,0,0,4.4-5.39l13.76-32.62,13.47,32.76a10.83,10.83,0,0,0,4.18,5.46,11.18,11.18,0,0,0,6.17,1.77,13.41,13.41,0,0,0,8.72-3.19,9.79,9.79,0,0,0,3.9-7.87A10.56,10.56,0,0,0,365,526Z"
      transform="translate(-170.69 -469.44)"
    />,
    'LogoWM'
  );
  return <Icon {...props} viewBox="0 0 195.35 72.19" />;
};
