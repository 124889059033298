// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import loadable from '@loadable/component';
import meStoriesReducer, {
  isLoaded as isStoriesLoaded,
  load as loadStories,
  isLoadedByPublication,
  loadByPublication,
} from 'redux/modules/me/stories';
import keywordsReducer from 'redux/modules/keywords';
import { getPublication } from 'redux/modules/publications';
import { batch } from 'react-redux';
import isAdmin from '../../../../api/utils/isAdmin';

const MeStories = loadable(() => import(/* webpackChunkName: 'a-news' */ './Stories'));
const Middleware = (props: any) => {
  return <MeStories {...props} />;
};
export default provideHooks({
  inject: ({ store: { inject } }: any) =>
    inject({ meStories: meStoriesReducer, keywords: keywordsReducer }),
  fetch: async ({ params, app, store: { dispatch, getState } }: any) => {
    const state = getState();

    const promises: any[] = [];

    if (params.publication) {
      promises.push(() => dispatch(getPublication(params.publication)).catch(() => null));

      if (!isLoadedByPublication(state, params.publication, 'task')) {
        promises.push(() =>
          dispatch(loadByPublication(0, params.publication, 'task', { $excludeHtml: true })).catch(
            () => null
          )
        );
      }

      if (!isLoadedByPublication(state, params.publication, 'rejected')) {
        promises.push(() =>
          dispatch(
            loadByPublication(0, params.publication, 'rejected', { $excludeHtml: true })
          ).catch(() => null)
        );
      }

      if (!isLoadedByPublication(state, params.publication, 'draft')) {
        promises.push(() =>
          dispatch(loadByPublication(0, params.publication, 'draft', { $excludeHtml: true })).catch(
            () => null
          )
        );
      }

      if (!isLoadedByPublication(state, params.publication, 'public')) {
        promises.push(() =>
          dispatch(
            loadByPublication(0, params.publication, 'public', { $excludeHtml: true })
          ).catch(() => null)
        );
      }

      if (!isLoadedByPublication(state, params.publication, 'scheduled')) {
        promises.push(() =>
          dispatch(
            loadByPublication(0, params.publication, 'scheduled', { $excludeHtml: true })
          ).catch(() => null)
        );
      }

      if (!isLoadedByPublication(state, params.publication, 'pre-public')) {
        promises.push(() =>
          dispatch(
            loadByPublication(0, params.publication, 'pre-public', { $excludeHtml: true })
          ).catch(() => null)
        );
      }

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      if (await isAdmin(state.auth)) {
        if (!isLoadedByPublication(state, params.publication, 'no-translated')) {
          promises.push(() =>
            dispatch(
              loadByPublication(0, params.publication, 'no-translated', { $excludeHtml: true })
            ).catch(() => null)
          );
        }
        if (!isLoadedByPublication(state, params.publication, 'deleted')) {
          promises.push(() =>
            dispatch(
              loadByPublication(0, params.publication, 'deleted', { $excludeHtml: true })
            ).catch(() => null)
          );
        }
      }
    } else {
      if (!isStoriesLoaded(state, 'task')) {
        promises.push(() => {
          return dispatch(loadStories(0, 'task', { $excludeHtml: true })).catch(() => null);
        });
      }
      if (!isStoriesLoaded(state, 'rejected')) {
        promises.push(() => {
          return dispatch(loadStories(0, 'rejected', { $excludeHtml: true })).catch(() => null);
        });
      }
      if (!isStoriesLoaded(state, 'draft')) {
        promises.push(() => {
          return dispatch(loadStories(0, 'draft', { $excludeHtml: true })).catch(() => null);
        });
      }

      if (!isStoriesLoaded(state, 'public')) {
        promises.push(() =>
          dispatch(loadStories(0, 'public', { $excludeHtml: true })).catch(() => null)
        );
      }

      if (!isStoriesLoaded(state, 'scheduled')) {
        promises.push(() =>
          dispatch(loadStories(0, 'scheduled', { $excludeHtml: true })).catch(() => null)
        );
      }

      if (!isStoriesLoaded(state, 'pre-public')) {
        promises.push(() =>
          dispatch(loadStories(0, 'pre-public', { $excludeHtml: true })).catch(() => null)
        );
      }

      if (await isAdmin(state.auth, app)) {
        if (!isStoriesLoaded(state, 'no-translated')) {
          promises.push(() =>
            dispatch(loadStories(0, 'no-translated', { $excludeHtml: true })).catch(() => null)
          );
        }
        if (!isStoriesLoaded(state, 'deleted')) {
          promises.push(() =>
            dispatch(loadStories(0, 'deleted', { $excludeHtml: true })).catch(() => null)
          );
        }
      }
    }

    await new Promise((resolve) => {
      batch(async () => {
        const finalPromises = promises.map((func) => func());
        await Promise.all(finalPromises);
        resolve(null);
      });
    });
  },
})(Middleware);
