/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import Slide from '@mui/material/Slide';
import { SnackbarClassKey } from '@mui/material/Snackbar';
import type {
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'CloseR... Remove this comment to see the full error message
  CloseReason,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Contai... Remove this comment to see the full error message
  ContainerClassKey,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Snackb... Remove this comment to see the full error message
  SnackbarProviderProps,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Varian... Remove this comment to see the full error message
  VariantType,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Snackb... Remove this comment to see the full error message
  SnackbarOrigin,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Varian... Remove this comment to see the full error message
  VariantClassKey,
} from '../index';
import { SnackbarItemProps } from '../SnackbarItem';
import { Snack } from '../SnackbarProvider';

export const allClasses: {
  mui: Record<SnackbarClassKey, any>;
  container: Record<ContainerClassKey, any>;
} = {
  mui: {
    root: {},
    anchorOriginTopCenter: {},
    anchorOriginBottomCenter: {},
    anchorOriginTopRight: {},
    anchorOriginBottomRight: {},
    anchorOriginTopLeft: {},
    anchorOriginBottomLeft: {},
  },
  container: {
    containerRoot: {},
    containerAnchorOriginTopCenter: {},
    containerAnchorOriginBottomCenter: {},
    containerAnchorOriginTopRight: {},
    containerAnchorOriginBottomRight: {},
    containerAnchorOriginTopLeft: {},
    containerAnchorOriginBottomLeft: {},
  },
};

export const MESSAGES = {
  NO_PERSIST_ALL:
    'WARNING - notistack: Reached maxSnack while all enqueued snackbars have \'persist\' flag. Notistack will dismiss the oldest snackbar anyway to allow other ones in the queue to be presented.',
};

export const SNACKBAR_INDENTS = {
  view: { default: 20, dense: 4 },
  snackbar: { default: 6, dense: 2 },
};

export const DEFAULTS = {
  maxSnack: 3,
  dense: false,
  hideIconVariant: false,
  variant: 'default' as VariantType,
  autoHideDuration: 5000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' } as SnackbarOrigin,
  TransitionComponent: Slide,
  transitionDuration: {
    enter: 225,
    exit: 195,
  },
};

export const capitalise = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

// @ts-expect-error ts-migrate(2339) FIXME: Property 'anchorOrigin' does not exist on type 'Sn... Remove this comment to see the full error message
export const originKeyExtractor = (anchor: Snack['anchorOrigin']): string =>
  `${capitalise(anchor.vertical)}${capitalise(anchor.horizontal)}`;

/**
 * Omit SnackbarContainer class keys that are not needed for SnackbarItem
 */
export const omitContainerKeys = (
  classes: SnackbarProviderProps['classes']
): SnackbarItemProps['classes'] =>
  Object.keys(classes)
    .filter((key: any) => !allClasses.container[key])
    .reduce((obj, key: any) => ({ ...obj, [key]: classes[key] }), {});

export const REASONS: { [key: string]: CloseReason } = {
  TIMEOUT: 'timeout',
  CLICKAWAY: 'clickaway',
  MAXSNACK: 'maxsnack',
  INSTRUCTED: 'instructed',
};

/** Tranforms classes name */
export const transformer = {
  toContainerAnchorOrigin: (origin: string) => `anchorOrigin${origin}` as ContainerClassKey,
  toAnchorOrigin: ({ vertical, horizontal }: SnackbarOrigin) =>
    `anchorOrigin${capitalise(vertical)}${capitalise(horizontal)}` as SnackbarClassKey,
  toVariant: (variant: VariantType) => `variant${capitalise(variant)}` as VariantClassKey,
};

export const isDefined = (value: string | null | undefined | number): boolean =>
  !!value || value === 0;

const numberOrNull = (numberish?: number | null) =>
  typeof numberish === 'number' || numberish === null;

export const merge =
  (options: any, props: any, defaults: any) =>
  (name: keyof Snack): any => {
    // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    if (name === 'autoHideDuration') {
      if (numberOrNull(options.autoHideDuration)) return options.autoHideDuration;
      if (numberOrNull(props.autoHideDuration)) return props.autoHideDuration;
      return DEFAULTS.autoHideDuration;
    }

    return options[name] || props[name] || defaults[name];
  };

export function objectMerge(options = {}, props = {}, defaults = {}) {
  return {
    ...defaults,
    ...props,
    ...options,
  };
}
