import type { Application } from '@feathersjs/feathers';
import type { User } from '../database/Models';

const isAdmin = async (
  auth: { loaded: boolean; user: User; loading: boolean },
  app: Application
): Promise<boolean> => {
  const { loading } = auth;
  let { user, loaded } = { ...auth };
  if (loading) {
    const authResult = await Promise.resolve(app.get('authentication')).catch(() => null);
    if (authResult) {
      ({ user } = authResult);
      loaded = true;
    }
  }
  return loaded && user !== null && user.UserRoleId === 1;
};

export default isAdmin;
