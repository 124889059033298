import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { isLoadedPublication, getPublication } from 'redux/modules/publications';
import { isLoaded as isStoriesLoaded, load as loadStories } from 'redux/modules/stories';

const PublicationLatest = loadable(
  () => import(/* webpackChunkName: 'publication-latest' */ './Latest')
);

function PublicationLatestContainer(props: any) {
  return <PublicationLatest {...props} />;
}

export default provideHooks({
  fetch: async ({ params, store: { dispatch, getState }, match }: any) => {
    const lastComponentMatch = match[match.length - 1];
    const { type } = lastComponentMatch.route.element.props;
    const promises = [];
    const state = getState();

    if (!isLoadedPublication(state, params.publication)) {
      promises.push(dispatch(getPublication(params.publication)).catch(() => null));
    }

    const paramsSearch = {
      status: 'public',
      $skip: 0,
      $limit: 20,
      $paginate: false,
      $exclude: ['content'],
      $sort:
        type === 'latest'
          ? {
              publishDate: -1,
            }
          : {
              claps: -1,
              views: -1,
            },
      publication: params.publication,
    };

    const key = JSON.stringify(paramsSearch);

    if (!isStoriesLoaded(state, key)) {
      paramsSearch.$skip = 0;
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
      if (!__CLIENT__) {
        promises.push(dispatch(loadStories(paramsSearch, key)).catch(() => null));
      } else {
        dispatch(loadStories(paramsSearch, key)).catch(() => null);
      }
    }

    await Promise.all(promises);
  },
})(PublicationLatestContainer);
