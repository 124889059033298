import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeSnackbar } from 'redux/modules/notifier';
import { useSnackbar } from 'components/notistack';

let displayed: any = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store) => (store as any).notifier.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }: any) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event: any, reason: any, myKey: any) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        // @ts-expect-error ts-migrate(6133) FIXME: 'event' is declared but its value is never read.
        onExited: (event: any, myKey: any) => {
          // removen this snackbar from redux store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
