const ENQUEUE_SNACKBAR = 'redux-webmediums/notifier/ENQUEUE_SNACKBAR';
const CLOSE_SNACKBAR = 'redux-webmediums/notifier/CLOSE_SNACKBAR';
const REMOVE_SNACKBAR = 'redux-webmediums/notifier/REMOVE_SNACKBAR';

export const enqueueSnackbar = (notification: any) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key: any) => ({
  type: CLOSE_SNACKBAR,

  // dismiss all if no key has been defined
  dismissAll: !key,

  key,
});

export const removeSnackbar = (key: any) => ({
  type: REMOVE_SNACKBAR,
  key,
});

const defaultState = {
  notifications: [],
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action: any) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || (notification as any).key === action.key
            ? // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              { ...notification, dismissed: true }
            : // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              { ...notification }
        ),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => (notification as any).key !== action.key
        ),
      };

    default:
      return state;
  }
};
