import type { User } from '../../api/database/Models';

export default function userCan(user: string | User, permission: string) {
  if (!user || user === null) {
    return false;
  }

  if (typeof user === 'string') {
    return false;
  }

  const { role } = user.UserRole || {};
  if (role === 'admin') {
    return true;
  }

  switch (permission) {
    case 'markFeaturedPost': {
      return false;
    }
    case 'createPublication': {
      return role === 'editor';
    }
    default:
      return false;
  }
}
