// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import loadable from '@loadable/component';
import meTopicsReducer, { load as loadTopics } from 'redux/modules/me/topics';

const MeTopics = loadable(() => import(/* webpackChunkName: 'a-topics' */ './Topics'));

const MeTopicsContainer = (props: any) => {
  return <MeTopics {...props} />;
};

export default provideHooks({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
  fetch: ({ store: { dispatch, inject, asyncReducers } }) => {
    if (!asyncReducers.meTopics) {
      inject({ meTopics: meTopicsReducer });
    }

    const promises = [];

    promises.push(dispatch(loadTopics(0)).catch(() => null));

    return Promise.all(promises);
  },
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'inject' implicitly has an 'any' t... Remove this comment to see the full error message
  defer: async ({ store: { inject, asyncReducers } }) => {
    if (!asyncReducers.meTopics) {
      inject({ meTopics: meTopicsReducer });
    }
  },
})(MeTopicsContainer);
