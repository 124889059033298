/* eslint-disable max-len */
/* eslint-disable react/no-multi-comp */
import { useEffect, useState } from 'react';
import { Outlet, Link, matchRoutes, useLocation, useParams } from 'react-router-dom';
import type { RouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonFollow from 'components/Button/Follow';
import { changeTheme } from 'redux/modules/settings';
import HeaderApp from 'components/HeaderApp/HeaderApp';
import Notifs from 'components/Notifs/Notifs';
import NotifComponent from 'components/NotifComponent/NotifComponent';
import config from 'config';
import ReactGA from 'react-ga';
// import { socket } from 'app';
import { load as loadAuth } from 'redux/modules/auth';
// import 'components/Fonts/index.css';
import getImageLink from 'utils/getImageLink';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-c... Remove this comment to see the full error message
import WmContentSerifFont400ItalicWoff from 'components/Fonts/files/latin-charter-400-italic.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-ch... Remove this comment to see the full error message
import WmContentSerifFont400ItalicRestWoff from 'components/Fonts/files/rest-charter-400-italic.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-ch... Remove this comment to see the full error message
import WmContentSerifFont400NormalRestWoff from 'components/Fonts/files/rest-charter-400-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-c... Remove this comment to see the full error message
import WmContentSerifFont400NormalWoff from 'components/Fonts/files/latin-charter-400-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-c... Remove this comment to see the full error message
import WmContentSerifFont700ItalicWoff from 'components/Fonts/files/latin-charter-700-italic.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-ch... Remove this comment to see the full error message
import WmContentSerifFont700ItalicRestWoff from 'components/Fonts/files/rest-charter-700-italic.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-c... Remove this comment to see the full error message
import WmContentSerifFont700NormalWoff from 'components/Fonts/files/latin-charter-700-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-ch... Remove this comment to see the full error message
import WmContentSerifFont700NormalRestWoff from 'components/Fonts/files/rest-charter-700-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-f... Remove this comment to see the full error message
import WmContentTitleFont400NormalWoff from 'components/Fonts/files/latin-fell-400-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-fe... Remove this comment to see the full error message
import WmContentTitleFont400NormalRestWoff from 'components/Fonts/files/rest-fell-400-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-m... Remove this comment to see the full error message
import WmContentSlabSerifFont300ItalicWoff from 'components/Fonts/files/latin-marat-sans-300-italic.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-ma... Remove this comment to see the full error message
import WmContentSlabSerifFont300ItalicRestWoff from 'components/Fonts/files/rest-marat-sans-300-italic.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-ma... Remove this comment to see the full error message
import WmContentSansSerifFont400NormalRestWoff from 'components/Fonts/files/rest-marat-sans-400-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-m... Remove this comment to see the full error message
import WmContentSansSerifFont400NormalWoff from 'components/Fonts/files/latin-marat-sans-400-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-ma... Remove this comment to see the full error message
import WmContentSansSerifFont600NormalRestWoff from 'components/Fonts/files/rest-marat-sans-600-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-m... Remove this comment to see the full error message
import WmContentSansSerifFont600NormalWoff from 'components/Fonts/files/latin-marat-sans-600-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/latin-n... Remove this comment to see the full error message
import WmMarketingDisplayFont500NormalWoff from 'components/Fonts/files/latin-noe-display-500-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-no... Remove this comment to see the full error message
import WmMarketingDisplayFont500NormalRestWoff from 'components/Fonts/files/rest-noe-display-500-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-no... Remove this comment to see the full error message
import sohneFont500NormalRestWoff from 'components/Fonts/files/rest-sohne-500-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-no... Remove this comment to see the full error message
import sohneFont500NormalLatinWoff from 'components/Fonts/files/latin-sohne-500-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-no... Remove this comment to see the full error message
import sohneFont700NormalRestWoff from 'components/Fonts/files/rest-sohne-700-normal.woff';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Fonts/files/rest-no... Remove this comment to see the full error message
import sohneFont700NormalLatinWoff from 'components/Fonts/files/latin-sohne-700-normal.woff';
// import { closeSnackbar, enqueueSnackbar } from 'redux/modules/notifier';
import routes from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider } from 'components/notistack';
import Notifier from 'components/Notifier/Notifier';
import { notifDismiss } from 'redux/modules/notifs';
import useApp from 'hooks/useApp';

// local('-apple-system'),local('Segoe UI'),local('Roboto'),local('Oxygen'),local('Ubuntu'),local('Cantarell'),local('Open Sans'),local('Helvetica Neue'),local('sans-serif'),
/* latin */
const wmContentSerifFont400Italic = {
  fontFamily: 'wm-content-serif-font',
  fontWeight: 400,
  fontStyle: 'italic',
  fontDisplay: 'swap',
  src: `url('${WmContentSerifFont400ItalicWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* rest */
const wmContentSerifFont400ItalicRest = {
  fontFamily: 'wm-content-serif-font',
  fontWeight: 400,
  fontStyle: 'italic',
  fontDisplay: 'swap',
  src: `url('${WmContentSerifFont400ItalicRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};

/* rest */
const wmContentSerifFont400NormalRest = {
  fontFamily: 'wm-content-serif-font',
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentSerifFont400NormalRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};

/* latin */
const wmContentSerifFont400Normal = {
  fontFamily: 'wm-content-serif-font',
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentSerifFont400NormalWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* latin */
const wmContentSerifFont700Italic = {
  fontFamily: 'wm-content-serif-font',
  fontWeight: 700,
  fontStyle: 'italic',
  fontDisplay: 'swap',
  src: `url('${WmContentSerifFont700ItalicWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* rest */
const wmContentSerifFont700ItalicRest = {
  fontFamily: 'wm-content-serif-font',
  fontWeight: 700,
  fontStyle: 'italic',
  fontDisplay: 'swap',
  src: `url('${WmContentSerifFont700ItalicRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};

/* latin */
const wmContentSerifFont700Normal = {
  fontFamily: 'wm-content-serif-font',
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentSerifFont700NormalWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* rest */
const wmContentSerifFont700NormalRest = {
  fontFamily: 'wm-content-serif-font',
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentSerifFont700NormalRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};

/* latin */
const wmContentTitleFont400Normal = {
  fontFamily: 'wm-content-title-font',
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentTitleFont400NormalWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* rest */
const wmContentTitleFont400NormalRest = {
  fontFamily: 'wm-content-title-font',
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentTitleFont400NormalRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};

/* latin */
const wmContentSlabSerifFont300Italic = {
  fontFamily: 'wm-content-slab-serif-font',
  fontWeight: 300,
  fontStyle: 'italic',
  fontDisplay: 'swap',
  src: `url('${WmContentSlabSerifFont300ItalicWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* rest */
const wmContentSlabSerifFont300ItalicRest = {
  fontFamily: 'wm-content-slab-serif-font',
  fontWeight: 300,
  fontStyle: 'italic',
  fontDisplay: 'swap',
  src: `url('${WmContentSlabSerifFont300ItalicRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};

/* rest */
const wmContentSansSerifFont400NormalRest = {
  fontFamily: 'wm-content-sans-serif-font',
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentSansSerifFont400NormalRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};

/* latin */
const wmContentSansSerifFont400Normal = {
  fontFamily: 'wm-content-sans-serif-font',
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentSansSerifFont400NormalWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* rest */
const wmContentSansSerifFont600NormalRest = {
  fontFamily: 'wm-content-sans-serif-font',
  fontWeight: 600,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentSansSerifFont600NormalRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};

/* latin */
const wmContentSansSerifFont600Normal = {
  fontFamily: 'wm-content-sans-serif-font',
  fontWeight: 600,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmContentSansSerifFont600NormalWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* latin */
const wmMarketingDisplayFont500Normal = {
  fontFamily: 'wm-marketing-display-font',
  fontWeight: 500,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmMarketingDisplayFont500NormalWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

/* rest */
const wmMarketingDisplayFont500NormalRest = {
  fontFamily: 'wm-marketing-display-font',
  fontWeight: 500,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${WmMarketingDisplayFont500NormalRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};
/* rest */
const sohneFont500NormalRest = {
  fontFamily: 'sohne',
  fontWeight: 500,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${sohneFont500NormalRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-B6,U+B8-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};
/* latin */
const sohneFont500NormalLatin = {
  fontFamily: 'sohne',
  fontWeight: 500,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${sohneFont500NormalLatinWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+B7,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};
/* rest */
const sohneFont700NormalRest = {
  fontFamily: 'sohne',
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${sohneFont700NormalRestWoff}') format('woff')`,
  unicodeRange:
    'U+80-9F,U+A1-B6,U+B8-2009,U+200B-2013,U+2015-2017,U+201A-201B,U+201E-2021,U+2023-2025,U+2027-10FFFF',
};
/* latin */
const sohneFont700NormalLatin = {
  fontFamily: 'sohne',
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url('${sohneFont700NormalLatinWoff}') format('woff')`,
  unicodeRange: 'U+0-7F,U+A0,U+B7,U+200A,U+2014,U+2018,U+2019,U+201C,U+201D,U+2022,U+2026',
};

const aspectRatio = (oldWidth: any, oldHeight: any, maxWidth: any, maxHeight: any) => {
  let ar = oldWidth / oldHeight;
  let newHeight = maxWidth / ar;
  let newWidth = newHeight * ar;

  if (newHeight > maxHeight) {
    ar = oldHeight / oldWidth;
    newWidth = maxHeight / ar;
    newHeight = newWidth * ar;
  }

  return {
    width: newWidth,
    height: newHeight,
  };
};

const styles = (theme: any) => ({
  '@keyframes nprogress-spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },

    '100%': {
      transform: 'rotate(360deg)',
    },
  },

  '@global': {
    'input[type="search"]::-webkit-search-decoration': {
      '-webkit-appearance': 'none',
    },
    'input[type="search"]::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
    'input[type="search"]::-webkit-search-results-button': {
      '-webkit-appearance': 'none',
    },
    'input[type="search"]::-webkit-search-results-decoration': {
      '-webkit-appearance': 'none',
    },
    ".tippy-box[data-animation='mention']": {
      transform: 'scale(0)',
      transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
    },
    ".tippy-box[data-animation='mention'][data-state='visible']": {
      transform: 'scale(1)',
    },
    '.react-hot-loader-error-overlay': {
      position: 'absolute',
      zIndex: 9999999,
    },
    '@font-face': [
      {
        fontFamily: 'Cambria',
        // eslint-disable-next-line quotes
        src: "local('Arial'), local('Helvetica')",
        unicodeRange: 'U + 2500-259F',
      },
      wmContentSerifFont400Italic,
      wmContentSerifFont400ItalicRest,
      wmContentSerifFont400NormalRest,
      wmContentSerifFont400Normal,
      wmContentSerifFont700Italic,
      wmContentSerifFont700ItalicRest,
      wmContentSerifFont700Normal,
      wmContentSerifFont700NormalRest,
      wmContentTitleFont400Normal,
      wmContentTitleFont400NormalRest,
      wmContentSlabSerifFont300Italic,
      wmContentSlabSerifFont300ItalicRest,
      wmContentSansSerifFont400NormalRest,
      wmContentSansSerifFont400Normal,
      wmContentSansSerifFont600NormalRest,
      wmContentSansSerifFont600Normal,
      wmMarketingDisplayFont500Normal,
      wmMarketingDisplayFont500NormalRest,
      sohneFont500NormalRest,
      sohneFont500NormalLatin,
      sohneFont700NormalRest,
      sohneFont700NormalLatin,
    ],
    html: {
      WebkitFontSmoothing: 'antialiased',
      // Antialiasing.
      MozOsxFontSmoothing: 'grayscale',
      // Antialiasing.
      // Change from `box-sizing: content-box` so that `width`
      // is not affected by `padding` or `border`.
      boxSizing: 'border-box',
      fontFamily: 'sans-serif',
      '-ms-text-size-adjust': '100%',
      '-webkit-text-size-adjust': '100%',
    },
    '@media screen and (max-device-width:1000px)': {
      html: {
        '-webkit-text-size-adjust': 'none',
      },
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    body: {
      background:
        theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
      margin: 0,
      paddingTop: 65,
      fontFamily: [
        'wm-content-sans-serif-font',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        '"Open Sans"',
        '"Helvetica Neue"',
        'sans-serif',
      ],
      letterSpacing: 0,
      fontWeight: 400,
      fontStyle: 'normal',
      textRendering: 'optimizeLegibility',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      '-moz-font-feature-settings': '"liga" on',
      color: 'rgba(0,0,0,.84)',
      fontSize: 20,
      lineHeight: 1.4,
      '@media print': {
        // Save printer ink.
        backgroundColor: theme.palette.common.white,
      },
    },
    pre: {
      margin: 0,
    },
    '#content': {
      overflow: 'hidden',
    },
    '.no-js': {
      '& .img-no-webp': {
        display: 'none',
        backgroundImage: 'none!important',
      },
      '& .img-webp': {
        display: 'none',
        backgroundImage: 'none!important',
      },
    },
    'html.js:not(.webp):not(.no-webp)': {
      '& .img-no-webp': {
        display: 'none',
        backgroundImage: 'none!important',
      },
      '& .img-webp': {
        display: 'none',
        backgroundImage: 'none!important',
      },
    },
    'html.js.webp': {
      '& .img-no-webp': {
        display: 'none',
        backgroundImage: 'none!important',
      },
      '& .img-webp': {
        display: 'inherit',
      },
    },
    'html.js.no-webp': {
      '& .img-no-webp': {
        display: 'inherit',
      },
      '& .img-webp': {
        display: 'none',
        backgroundImage: 'none!important',
      },
    },
    '.links': {
      color: theme.palette.mode === 'light' ? '#1070e0' : '#69c',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.mode === 'light' ? 'rgba(16,112,224,0.85)' : '#98caff',
      },
      '&:focus': {
        color: theme.palette.mode === 'light' ? 'rgba(16,112,224,0.85)' : '#98caff',
      },
    },
    'h1,h2,h3,h4,h5,h6': {
      fontFamily: [
        'wm-content-sans-serif-font',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        '"Open Sans"',
        '"Helvetica Neue"',
        'sans-serif',
      ].join(','),
      letterSpacing: 0,
      fontWeight: 700,
      fontStyle: 'normal',
    },
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
    '.headerWrapper': {
      position: 'relative',
      zIndex: 10,
    },
    '.headerBackground': {
      transition: 'height 300ms ease-in 2s',
      transitionProperty: 'height',
      transitionDuration: '300ms',
      transitionTimingFunction: 'ease-in',
      transitionDelay: '100ms',

      height: 300,
      background:
        theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
      backgroundImage:
        theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
      backgroundRepeat: 'repeat-x',
      filter:
        // eslint-disable-next-line
        "progid: DXImageTransform.Microsoft.gradient(startColorstr= '#FF2364A7', endColorstr= '#FF15497B', GradientType=1)",
    },
    '.appBarAnimation': {
      transition: 'background 300ms ease-in 2s',
      transitionProperty: 'background',
      transitionDuration: '300ms',
      transitionTimingFunction: 'ease-in',
      transitionDelay: '100ms',
    },
    '.is404Body': {
      background: '#2364A7',
      backgroundImage: 'linear-gradient(to right, #2364A7 0%, #15497B 100%)',
      backgroundRepeat: 'repeat-x',
      filter:
        // eslint-disable-next-line
        "progid: DXImageTransform.Microsoft.gradient(startColorstr= '#FF2364A7', endColorstr= '#FF15497B', GradientType=1)",
    },
    '.appBar': {
      background: 'transparent',
      color: '#fff',
    },
    '.appContent': {
      position: 'relative',
    },
    '.notifs': {
      // margin: '15px 0'
    },
    '.topBar': {
      position: 'relative',
      width: '100%',
      zIndex: 22,
      fontSize: 12,
      textAlign: 'right',
    },
    '.flagSelect': {
      marginRight: 10,
    },
    '.titleLive': {
      color: '#fff',
      fontSize: 26,

      '& strong': {
        color: '#000',
        background: '#fff',
      },
    },
    '.clearfix:before, .clearfix:after': {
      content: '"."',
      display: 'block',
      height: 0,
      overflow: 'hidden',
    },

    '.clearfix:after': {
      clear: 'both',
    },

    '.clearfix': {
      zoom: 1,
    },

    // '.error.error': {
    //   backgroundColor: 'transparent'
    // },

    '.fancy': {
      lineHeight: 0.1,
      textAlign: 'center',

      '& span': {
        display: 'inline-block',
        position: 'relative',
      },
      '& span:before, span:after': {
        content: '""',
        position: 'absolute',
        height: 5,
        borderBottom: '1px solid #eeeeee',
        top: 0,
        width: 600,
      },

      '& span:before': {
        right: '100%',
        marginRight: 15,
      },

      '& span:after': {
        left: '100%',
        marginLeft: 15,
      },
    },
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        background: '#34e79a',
        position: 'fixed',
        zIndex: 10317777,
        top: 0,
        left: 0,
        width: '100%',
        height: 2,
      },
      '& .peg': {
        display: 'block',
        position: 'absolute',
        right: 0,
        width: 100,
        height: '100%',
        boxShadow: '0 0 10px #34e79a, 0 0 5px #34e79a',
        opacity: 1.0,
        transform: 'rotate(3deg) translate(0px, -4px)',
      },
      '& .spinner': {
        display: 'block',
        position: 'fixed',
        zIndex: 1031,
        top: 15,
        right: 15,
      },
      '& .spinner-icon': {
        width: 18,
        height: 18,
        boxSizing: 'border-box',
        border: 'solid 2px transparent',
        borderTopColor: '#34e79a',
        borderLeftColor: '#34e79a',
        borderRadius: '50%',
        animation: 'nprogress-spinner 400ms linear infinite',
        animationName: '$nprogress-spinner',
      },
    },

    '.nprogress-custom-parent': {
      overflow: 'hidden',
      position: 'relative',
      '& #nprogress': {
        '& .spinner, .bar': {
          position: 'absolute',
        },
      },
    },
    '@media (max-width: 600px)': {
      '.textCenterXs': {
        textAlign: 'center',
      },
      '.titleLive': {
        fontSize: 19,
      },
    },
    '@global .animate-icon': {
      width: 38,
      height: 38,
    },
    '@media screen and (max-width: 767px)': {
      body: {
        paddingTop: 56,
      },
    },
  },

  headerLogo: {
    width: '100%',
    height: '100%',
  },

  titleHeaderApp: {
    fontWeight: 'bold',
  },

  containerPublicationLogo: {
    height: 65,
    marginRight: 18,
    display: 'flex',
    alignItems: 'center',
  },

  publicationLogoA: {
    lineHeight: 1,
  },

  publicationLogoIMG: {
    marginTop: 5,
  },

  avatarPublicPublciation: {
    width: 32,
    height: 32,
  },
});
if (typeof window !== 'undefined') {
  // @ts-expect-error hide this
  const userUid = window?.__data?.auth?.user?.uid;
  const userIdOption = userUid ? { userId: userUid } : {};
  ReactGA.initialize('UA-128011462-1', {
    gaOptions: {
      ...userIdOption,
    },
  });
}

const trackPage = (page: any) => {
  ReactGA.set({
    page,
  });
  ReactGA.pageview(page);
};

type AppWrapperProps = {
  user?: {
    email?: string;
  };
  changeTheme: (...args: any[]) => any;
  classes: {
    [key: string]: any;
  };
  publications?: {
    [key: string]: any;
  };
  post?: {
    [key: string]: any;
  };
  postPublic?: {
    [key: string]: any;
  };
  postPublicId?: string;
  loadingMeStories?: boolean;
  saved?: boolean;
};
const AppWrapper = ({
  publications = {},
  postPublic = {},
  post = {},
  postPublicId = '',
  loadingMeStories = false,
  classes,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'topics' does not exist on type 'AppWrapp... Remove this comment to see the full error message
  topics,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'localeContext' does not exist on type 'A... Remove this comment to see the full error message
  localeContext,
}: AppWrapperProps) => {
  const app: any = useApp();
  const location = useLocation();
  const params = useParams();
  const [prevLocation, setPrevLocation] = useState(location);
  const dispatch = useDispatch();
  const { user, notifs } = useSelector((s: any) => ({
    uiTheme: s.settings.uiTheme,
    lang: s.settings.lang,
    user: s.auth.user,
    notifs: s.notifs,
  }));

  useEffect(() => {
    trackPage(location.pathname + location.search);
    // Remove the server-side injected CSS.

    const jssStylesBlankHtml = document.querySelector('#pg-loading-screen');
    if (jssStylesBlankHtml && jssStylesBlankHtml.parentNode) {
      jssStylesBlankHtml.parentNode.removeChild(jssStylesBlankHtml);
    }

    const jssStylesBlank = document.querySelector('#server-side-styles-blank-page');
    if (jssStylesBlank && jssStylesBlank.parentNode) {
      jssStylesBlank.parentNode.removeChild(jssStylesBlank);
    }

    const jssStyles = document.querySelector('#jss-side-styles');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    const links = document.querySelectorAll('link[media="none"]');
    if (links) {
      for (let i = 0; i < links.length; i += 1) {
        const link = links[i];
        (link as any).media = 'screen, projection';
        (link as any).rel = 'stylesheet';
      }
    }

    const userListener = () => {
      dispatch(loadAuth(true));
    };
    const serviceMeUsers = app.service('me/users');
    serviceMeUsers.on('patched', userListener);

    return () => {
      serviceMeUsers.removeListener('patched', userListener);
    };
  }, []);

  // useEffect((): any => {
  //   const rolesToHearSocket: any = {
  //     admin: true,
  //     editor: true,
  //   };
  // if (rolesToHearSocket[user?.UserRole.role]) {
  //   const disconnectListener = (reason: any) => {
  //     console.log('Reason socket got disconnected:', reason);
  //     // setState({ wsStatus: false });
  //     dispatch(notifDismiss('reconnectedws'));
  //     dispatch(notifDismiss('reconnectingws'));
  //     dispatch(notifDismiss('disconectedws'));

  //     dispatch(
  //       notifSend({
  //         id: 'disconectedws',
  //         message: 'Connection with WebSocket lost',
  //         kind: 'error',
  //         position: {
  //           vertical: 'top',
  //           horizontal: 'center',
  //         },
  //       })
  //     );
  //   };
  //   socket.on('disconnect', disconnectListener);

  //   const reconnectAttempListener = (attempt: any) => {
  //     dispatch(notifDismiss('reconnectedws'));
  //     dispatch(notifDismiss('reconnectingws'));
  //     dispatch(notifDismiss('disconectedws'));
  //     dispatch(
  //       notifSend({
  //         id: 'reconnectingws',
  //         message: `Reconnecting... attempt ${attempt}`,
  //         kind: 'info',
  //         position: {
  //           vertical: 'top',
  //           horizontal: 'center',
  //         },
  //       })
  //     );
  //   };
  //   socket.io.on('reconnect_attempt', reconnectAttempListener);

  //   const reconnectListener = () => {
  //     // setState({ wsStatus: true });
  //     dispatch(notifDismiss('reconnectingws'));
  //     dispatch(notifDismiss('disconectedws'));

  //     dispatch(
  //       notifSend({
  //         id: 'reconnectedws',
  //         message: 'WebSocket Established...',
  //         kind: 'success',
  //         position: {
  //           vertical: 'top',
  //           horizontal: 'center',
  //         },
  //       })
  //     );
  //   };
  //   socket.io.on('reconnect', reconnectListener);
  //   return () => {
  //     socket.io.off('disconnect', disconnectListener);
  //     socket.io.off('reconnect_attempt', reconnectAttempListener);
  //     socket.io.off('reconnect', reconnectListener);
  //   };
  // }
  // }, [user?.uid]);

  // didComponentUpdate
  useEffect(() => {
    const nextPage = location.pathname + location.search;
    const currentPage = prevLocation.pathname + prevLocation.search;

    if (currentPage !== nextPage) {
      trackPage(nextPage);
      setPrevLocation(location);
    }

    if (location !== prevLocation) {
      // remove lang on the url
      const nextPath = location.pathname;
      const prevPath = prevLocation.pathname;
      if (nextPath !== prevPath) {
        if (
          location.pathname.indexOf('/page') === -1 &&
          location.pathname.indexOf('/latest') === -1 &&
          location.pathname.indexOf('/trending') === -1 &&
          !params.revisionuid
        ) {
          window.scrollTo(0, 0);
        }
      }
    }
  });

  const handleCloseNotif = (id: string) => {
    dispatch(notifDismiss(id));
  };

  // if (!user && prevProps.user) {
  //   // login
  //   const redirect = location.query && location.query.redirect;
  //   history.push(redirect || '/login-success');
  // } else if (user && !prevProps.user) {
  //   // logout
  //   history.push('/');
  // }

  const pathSplit = location.pathname.split('/').filter((v) => v);

  const pathsToLang: any = {
    '/english': 'en',
    '/espanol': 'es',
  };
  const lang = pathsToLang[location.pathname] || localeContext.lang;

  const urlsNotHeader: any = {
    '/:publication/stories': true,
    '/:publication/stories/:status': true,
    '/me/stories/edit': true,
    '/me/stories/create': true,
    '/me/stories/edit/:uid': true,
    '/me/stories/create/:uid': true,
    '/:publication/:slug': true,
    '/:publication/about': true,
    '/:publication/archive': true,
  };

  const urlsGetFromPostPub: any = {
    '/me/stories/create': true,
    '/me/stories/edit': true,
    '/me/stories/create/:uid': true,
    '/me/stories/edit/:uid': true,
  };

  const urlsWithTopicsHeaderActive: any = {
    '/english': true,
    '/espanol': true,
    '/': true,
  };

  const branch: RouteMatch[] = matchRoutes(routes, location.pathname) as RouteMatch[];
  const lastRouter = branch[branch.length - 1];
  const isPostPublicationPage = urlsNotHeader[lastRouter.route.path];
  const getPubFromPost = urlsGetFromPostPub[lastRouter.route.path];
  const isEditPage = getPubFromPost;
  const activeHeaderTopics = urlsWithTopicsHeaderActive[lastRouter.route.path];

  if (post && post.draftContent) {
    post.draftContent =
      typeof post.draftContent === 'object' ? post.draftContent : JSON.parse(post.draftContent);
    if (post.draftContent && !post.draftContent.Publication) {
      post.draftContent.Publication = post.Publication;
    }
  }

  const getPublication = (p: any) => {
    if (p[`${pathSplit[0]}-public`] && p[`${pathSplit[0]}-public`][0]) {
      return p[`${pathSplit[0]}-public`][0];
    }

    if (p[pathSplit[0]] && p[pathSplit[0]][0]) {
      return p[pathSplit[0]][0];
    }
  };

  // eslint-disable-next-line
    const publication =
    getPubFromPost && post && post.draftContent
      ? post.draftContent.Publication
      : publications && pathSplit && getPublication(publications);

  const notIn = ['settings'];
  const notRepresentHeaderPublication =
    publication && pathSplit[0] && pathSplit[1] && notIn.includes(pathSplit[1]);

  // const { lang } = branch[0].match.params;

  const setHeaderAttrr = !isPostPublicationPage && !notRepresentHeaderPublication && publication;
  let headerAttributes = {};

  let headerLogo = null;
  if (setHeaderAttrr) {
    headerAttributes = {
      layout: publication.settingsLayout,
      align: publication.settingsAlignment,
      title: publication.title,
      tagline: publication.tagline,
      size: publication.settingsSize,
      backgroundColor:
        publication.settingsColorAppliedMethod === 'bold' && publication.settingsColor,
      backgroundImage: {
        src:
          publication.backgroundImage &&
          publication.backgroundImage.md5 &&
          getImageLink(
            publication.backgroundImage.md5,
            'max_2000',
            publication.backgroundImage.format
          ),
        pos: publication.backgroundImagePosition,
      },
    };
    if (publication) {
      if (publication.headerLogo && publication.headerLogo.md5) {
        const dimensions =
          publication && publication.headerLogo && publication.headerLogo.md5
            ? aspectRatio(
                publication.headerLogo.width,
                publication.headerLogo.height,
                publication.settingsLayout === 'both' ? 660 : 1000,
                publication.settingsLayout === 'both' ? 200 : 350
              )
            : {};

        const imageSrc = getImageLink(
          publication.headerLogo.md5,
          publication.settingsLayout === 'logo' ? 'max_2000x750' : 'max_1320x400',
          publication.headerLogo.format
        );

        headerLogo = (
          <div
            style={{
              maxWidth: (dimensions as any).width,
              margin:
                publication.settingsLayout === 'logo' || publication.settingsAlignment === 'center'
                  ? '0 auto'
                  : '',
            }}
          >
            <img src={imageSrc} alt={publication.name} className={classes.headerLogo} />
          </div>
        );

        (headerAttributes as any).headerLogo = headerLogo;
      }
    }
  }

  if (!isEditPage && isPostPublicationPage && publication && publication.uid) {
    if (publication.logo && publication.logo.md5) {
      const imageSrc = getImageLink(publication.logo.md5, 'max_600x72', publication.logo.format);

      const dimensionHeaderLogo = aspectRatio(
        publication.logo.width,
        publication.logo.height,
        300,
        40
      );

      (headerAttributes as any).contentAfterSeparator = (
        <>
          <div className={classes.containerPublicationLogo}>
            <Link className={classes.publicationLogoA} to={`/${publication.slug}`}>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img
                  className={classes.avatarPublicPublciation}
                  alt={publication.name}
                  src={getImageLink(
                    publication.avatar.md5,
                    'fit_c_64x64',
                    publication.avatar.format
                  )}
                />
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <img
                  className={classes.publicationLogoIMG}
                  width={dimensionHeaderLogo.width}
                  height={dimensionHeaderLogo.height}
                  src={imageSrc}
                  alt={publication.name}
                />
              </Box>
            </Link>
          </div>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div className={classes.containerPublicationLogo}>
              {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ object: any; }' is missing the following p... Remove this comment to see the full error message */}
              <ButtonFollow object={publication} />
            </div>
          </Box>
        </>
      );
    } else if (!isEditPage && publication && publication.name && publication.avatar) {
      (headerAttributes as any).contentAfterSeparator = (
        <>
          <div className={classes.containerPublicationLogo}>
            <Link className={classes.publicationLogoA} to={`/${publication.slug}`}>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <img
                  className={classes.avatarPublicPublciation}
                  alt={publication.name}
                  src={getImageLink(
                    publication.avatar.md5,
                    'fit_c_64x64',
                    publication.avatar.format
                  )}
                />
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Typography variant="body1" className={classes.titleHeaderApp}>
                  {publication.name}
                </Typography>
              </Box>
            </Link>
          </div>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div className={classes.containerPublicationLogo}>
              {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ object: any; }' is missing the following p... Remove this comment to see the full error message */}
              <ButtonFollow object={publication} />
            </div>
          </Box>
        </>
      );
    }

    (headerAttributes as any).separatorMainLogo = true;
  }

  const content = <Outlet />;
  return (
    // <LangContext.Provider value={lang}>
    <>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Helmet {...config.app.head}>
        <link
          rel="preload"
          href={WmContentSerifFont400ItalicWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSerifFont400ItalicRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSerifFont400NormalRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSerifFont400NormalWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSerifFont700ItalicWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSerifFont700ItalicRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSerifFont700NormalWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSerifFont700NormalRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentTitleFont400NormalWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentTitleFont400NormalRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSlabSerifFont300ItalicWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSlabSerifFont300ItalicRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSansSerifFont400NormalRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSansSerifFont400NormalWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSansSerifFont600NormalRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmContentSansSerifFont600NormalWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmMarketingDisplayFont500NormalWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={WmMarketingDisplayFont500NormalRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={sohneFont500NormalRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={sohneFont500NormalLatinWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={sohneFont700NormalRestWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={sohneFont700NormalLatinWoff}
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Helmet>
      <SnackbarProvider maxSnack={3}>
        <Notifier />
      </SnackbarProvider>
      <HeaderApp
        {...headerAttributes}
        topics={activeHeaderTopics && topics && topics[`featuredHome_${lang}`]}
        position="fixed"
        location={location}
        user={user}
        publication={publication}
        // post={post}
        postPublic={postPublic && postPublic[postPublicId]}
        loading={loadingMeStories}
        changeTheme={changeTheme}
      />
      {notifs.global && (
        <Notifs
          key="notifs"
          className="notifs"
          namespace="global"
          handleCloseNotif={handleCloseNotif}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          NotifComponent={NotifComponent}
        />
      )}
      {/* <div className="appContent"> */}
      {content}
      {/* </div> */}
    </>
    // </LangContext.Provider>
  );
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(theme: any) => { '@keyframes np... Remove this comment to see the full error message
export default withStyles(styles, { name: 'AppWrapper' })(AppWrapper);
