import differenceInSeconds from 'date-fns/differenceInSeconds';
import { FORM_ERROR } from 'final-form';

const LOAD = 'redux-webmediums/me/payments/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/me/payments/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/me/payments/LOAD_FAIL';

const LOAD_PAYMENT = 'redux-webmediums/me/payments/LOAD_PAYMENTS';
const LOAD_PAYMENT_SUCCESS = 'redux-webmediums/me/payments/LOAD_PAYMENTS_SUCCESS';
const LOAD_PAYMENT_FAIL = 'redux-webmediums/me/payments/LOAD_PAYMENTS_FAIL';

const CREATE_PAYMENTS = 'redux-webmediums/payments/CREATE_PAYMENTS';
const CREATE_PAYMENTS_SUCCESS = 'redux-webmediums/payments/CREATE_PAYMENTS_SUCCESS';
const CREATE_PAYMENTS_FAIL = 'redux-webmediums/payments/CREATE_PAYMENTS_FAIL';

const UPDATE_PAYMENTS = 'redux-webmediums/payments/UPDATE_PAYMENTS';
const UPDATE_PAYMENTS_SUCCESS = 'redux-webmediums/payments/UPDATE_PAYMENTS_SUCCESS';
const UPDATE_PAYMENTS_FAIL = 'redux-webmediums/payments/UPDATE_PAYMENTS_FAIL';

const DELETE_INVOICE = 'redux-webmediums/payments/DELETE_INVOICE';
const DELETE_INVOICE_SUCCESS = 'redux-webmediums/payments/DELETE_INVOICE_SUCCESS';
const DELETE_INVOICE_FAIL = 'redux-webmediums/payments/DELETE_INVOICE_FAIL';

const catchValidation = (error: any) => {
  if (error.message) {
    if (error.message === 'Validation failed' && error.data) {
      return Promise.reject(error.data);
    }
    const err = {
      [FORM_ERROR]: error.message,
    };
    return Promise.reject(err);
  }
  return Promise.reject(error);
};

const initialState = {
  loaded: false,
  saved: true,
  payments: {
    loading: true,
    data: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case LOAD:
      (state as any).loading = true;
      return {
        ...state,
      };
    case LOAD_SUCCESS: {
      if ((action as any).result.skip === 0) {
        state.payments.data = [];
      }
      const copy = {
        loading: false,
        loaded: true,
        lastTimeUsersLoaded:
          (action as any).result.skip === 0 ? new Date() : (state as any).lastTimeUsersLoaded,
        ...(action as any).result,
      };
      copy.data = state.payments.data.concat(copy.data);

      return {
        ...state,
        payments: { ...copy },
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        payments: {
          loading: false,
          loaded: false,
          data: [],
        },
        error: (action as any).error,
      };
    case LOAD_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        topic: (action as any).result,
      };
    case LOAD_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        loadedTopic: false,
        error: (action as any).error,
      };

    case CREATE_PAYMENTS:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case CREATE_PAYMENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    case UPDATE_PAYMENTS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE_PAYMENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    case DELETE_INVOICE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case DELETE_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState: any) {
  return (
    globalState.meUsers &&
    globalState.meUsers.payments &&
    globalState.meUsers.payments.loaded &&
    globalState.meUsers.payments.lastTimeUsersLoaded &&
    differenceInSeconds(new Date(), globalState.meUsers.payments.lastTimeUsersLoaded) < 5
  );
}

export function load($skip = 0, extraParams = {}) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: any) =>
      app.service('me/payments').find({
        query: {
          $sort: { createdAt: -1 },
          $limit: 10,
          $skip,
          ...extraParams,
        },
      }),
  };
}

export function isLoadeUsers(globalState: any, topicId: any) {
  return (
    globalState.meUsers &&
    globalState.meUsers.loadedUsers &&
    globalState.meUsers.loadedUsers[topicId]
  );
}

export function create(data: any) {
  return {
    types: [CREATE_PAYMENTS, CREATE_PAYMENTS_SUCCESS, CREATE_PAYMENTS_FAIL],
    promise: ({ app }: any) => app.service('me/payments').create(data).catch(catchValidation),
  };
}

export function update(data: any) {
  return {
    types: [UPDATE_PAYMENTS, UPDATE_PAYMENTS_SUCCESS, UPDATE_PAYMENTS_FAIL],
    promise: ({ app }: any) =>
      app.service('me/payments').patch(data.uid, data).catch(catchValidation),
  };
}

export function deleteInvoice(data: any) {
  return {
    types: [DELETE_INVOICE, DELETE_INVOICE_SUCCESS, DELETE_INVOICE_FAIL],
    promise: ({ app }: any) => app.service('me/invoices').remove(data.uid).catch(catchValidation),
  };
}
