import { FORM_ERROR } from 'final-form';

const RESET_LOADED_PUBLICATIONS = 'redux-webmediums/publications/RESET_LOADED_PUBLICATIONS';
const LOAD = 'redux-webmediums/publications/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/publications/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/publications/LOAD_FAIL';

const SEARCHMY_PUBLICATIONS = 'redux-webmediums/publications/SEARCHMY_PUBLICATIONS';
const SEARCHMY_PUBLICATIONS_SUCCESS = 'redux-webmediums/publications/SEARCHMY_PUBLICATIONS_SUCCESS';
const SEARCHMY_PUBLICATIONS_FAIL = 'redux-webmediums/publications/SEARCHMY_PUBLICATIONS_FAIL';

const SEARCH_FOLLOWING_PUBLICATIONS = 'redux-webmediums/publications/SEARCH_FOLLOWING_PUBLICATIONS';
const SEARCH_FOLLOWING_PUBLICATIONS_SUCCESS =
  'redux-webmediums/publications/SEARCH_FOLLOWING_PUBLICATIONS_SUCCESS';
const SEARCH_FOLLOWING_PUBLICATIONS_FAIL =
  'redux-webmediums/publications/SEARCH_FOLLOWING_PUBLICATIONS_FAIL';

const LOAD_PUBLICATION = 'redux-webmediums/publications/LOAD_PUBLICATION';
const LOAD_PUBLICATION_SUCCESS = 'redux-webmediums/publications/LOAD_PUBLICATION_SUCCESS';
const LOAD_PUBLICATION_FAIL = 'redux-webmediums/publications/LOAD_PUBLICATION_FAIL';

const LOAD_PUBLICATION_SEARCH = 'redux-webmediums/publications/LOAD_PUBLICATION_SEARCH';
const LOAD_PUBLICATION_SEARCH_SUCCESS =
  'redux-webmediums/publications/LOAD_PUBLICATION_SEARCH_SUCCESS';
const LOAD_PUBLICATION_SEARCH_FAIL = 'redux-webmediums/publications/LOAD_PUBLICATION_SEARCH_FAIL';

const CREATE_PUBLICATION = 'redux-webmediums/publications/CREATE_PUBLICATION';
const CREATE_PUBLICATION_SUCCESS = 'redux-webmediums/publications/CREATE_PUBLICATION_SUCCESS';
const CREATE_PUBLICATION_FAIL = 'redux-webmediums/publications/CREATE_PUBLICATION_FAIL';

const UPDATE_PUBLICATION = 'redux-webmediums/publications/UPDATE_PUBLICATION';
const UPDATE_PUBLICATION_SUCCESS = 'redux-webmediums/publications/UPDATE_PUBLICATION_SUCCESS';
const UPDATE_PUBLICATION_FAIL = 'redux-webmediums/publications/UPDATE_PUBLICATION_FAIL';

const initialState: {
  loaded: boolean;
  publicationsByKey: any;
  publicationLoaded: any;
  publications: any[];
  publication: any;
  search: any;
  myPublications: any[];
  followingPublications: any[];
} = {
  loaded: false,
  publicationsByKey: {},
  publicationLoaded: {},
  publications: [],
  publication: {},
  search: {},
  myPublications: [],
  followingPublications: [],
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS: {
      const { key } = action as any;
      if (!state.publicationsByKey[key]) {
        state.publicationsByKey[key] = [];
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        hasMore: (action as any).result.length !== 0,
        publications: state.publications.concat((action as any).result),
        publicationsByKey: {
          ...state.publicationsByKey,
          [key]: state.publicationsByKey[key].concat((action as any).result),
        },
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        hasMore: false,
        error: (action as any).error,
      };

    case SEARCHMY_PUBLICATIONS:
      return {
        ...state,
        loading: true,
      };
    case SEARCHMY_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        myLoaded: true,
        hasMyMore: (action as any).result.length !== 0,
        myPublications: state.publications.concat((action as any).result),
      };
    case SEARCHMY_PUBLICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        myLoaded: false,
        hasMyMore: false,
        error: (action as any).error,
      };
    case SEARCH_FOLLOWING_PUBLICATIONS:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_FOLLOWING_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        followingLoaded: true,
        hasFollowingMore: (action as any).result.length !== 0,
        followingPublications: state.publications.concat((action as any).result),
      };
    case SEARCH_FOLLOWING_PUBLICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        followingLoaded: false,
        hasFollowingMore: false,
        error: (action as any).error,
      };
    case LOAD_PUBLICATION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_PUBLICATION_SUCCESS: {
      if (parseInt((action as any).to, 10) === 0) {
        state.publication[(action as any).key] = [];
      }
      let copy = { ...(action as any).result[0] };
      copy = state.publication[(action as any).key].concat(copy);

      return {
        ...state,
        loading: false,
        hasMore: (action as any).result.length !== 0,
        publicationLoaded: { ...state.publicationLoaded, [(action as any).key]: true },
        publication: { ...state.publication, [(action as any).key]: copy },
      };
    }
    case LOAD_PUBLICATION_FAIL:
      return {
        ...state,
        loading: false,
        publicationLoaded: { ...state.publicationLoaded, [(action as any).key]: false },
        error: (action as any).error,
        hasMore: false,
      };

    case LOAD_PUBLICATION_SEARCH:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_PUBLICATION_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        search: { ...state.search, [(action as any).key]: (action as any).result },
      };
    }
    case LOAD_PUBLICATION_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        search: { ...state.search, [(action as any).key]: false },
        error: (action as any).error,
      };

    case CREATE_PUBLICATION:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PUBLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        myLoaded: false,
        followingLoaded: false,
        publication: { ...state.publication, [(action as any).result.slug]: null },
        publicationLoaded: { ...state.publicationLoaded, [(action as any).result.slug]: false },
      };
    case CREATE_PUBLICATION_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    case UPDATE_PUBLICATION:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PUBLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        myLoaded: false,
        followingLoaded: false,
        publication: { ...state.publication, [(action as any).result.slug]: null },
        publicationLoaded: {
          ...state.publicationLoaded,
          [(action as any).result.slug]: false,
          [`${(action as any).result.slug}-public`]: false,
          [`${(action as any).result.slug}-settings`]: false,
          [`${(action as any).result.slug}-about`]: false,
        },
      };
    case UPDATE_PUBLICATION_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    default:
      return state;
  }
}

const catchValidation = (error: any) => {
  if (error.message) {
    if (error.message === 'Validation failed' && error.data) {
      return Promise.reject(error.data);
    }
    const err = {
      [FORM_ERROR]: error.message,
    };
    return Promise.reject(err);
  }
  return Promise.reject(error);
};

export function isLoaded(globalState: any) {
  return globalState.publications && globalState.myPublications.myLoaded;
}

export function load(uid: any, revisionId: any, I18nStoryId: any) {
  return {
    types: [LOAD_PUBLICATION, LOAD_PUBLICATION_SUCCESS, LOAD_PUBLICATION_FAIL],
    promise: ({ app }: any) =>
      app.service('publications').find({
        query: {
          uid,
          revisionId,
          I18nStoryId,
        },
      }),
  };
}

export function isLoadedSearch(globalState: any, key: any) {
  return globalState.publications && globalState.publications.search[key];
}

export function search(params: any, key: any) {
  return {
    key,
    types: [LOAD_PUBLICATION_SEARCH, LOAD_PUBLICATION_SEARCH_SUCCESS, LOAD_PUBLICATION_SEARCH_FAIL],
    promise: ({ app }: any) =>
      app.service('publications').find({
        query: {
          ...params,
        },
      }),
  };
}

export function isLoadedMy(globalState: any) {
  return globalState.publications && globalState.publications.myLoaded;
}

export function searchMy() {
  return {
    types: [SEARCHMY_PUBLICATIONS, SEARCHMY_PUBLICATIONS_SUCCESS, SEARCHMY_PUBLICATIONS_FAIL],
    promise: ({ app }: any) =>
      app.service('publications').find({
        query: {
          my: true,
          $limit: 100,
        },
      }),
  };
}

export function isLoadedFollowing(globalState: any) {
  return globalState.publications && globalState.publications.followingLoaded;
}

export function searchFollowing() {
  return {
    types: [
      SEARCH_FOLLOWING_PUBLICATIONS,
      SEARCH_FOLLOWING_PUBLICATIONS_SUCCESS,
      SEARCH_FOLLOWING_PUBLICATIONS_FAIL,
    ],
    promise: ({ app }: any) =>
      app.service('publications').find({
        query: {
          following: true,
          $limit: 100,
        },
      }),
  };
}

export function create(data: any) {
  return {
    types: [CREATE_PUBLICATION, CREATE_PUBLICATION_SUCCESS, CREATE_PUBLICATION_FAIL],
    promise: ({ app }: any) => app.service('publications').create(data).catch(catchValidation),
  };
}

export function update(data: any) {
  return {
    types: [UPDATE_PUBLICATION, UPDATE_PUBLICATION_SUCCESS, UPDATE_PUBLICATION_FAIL],
    promise: ({ app }: any) =>
      app.service('publications').patch(data.uid, data).catch(catchValidation),
  };
}

export function isLoadedPublication(globalState: any, key: any) {
  return (
    globalState.publications &&
    globalState.publications.publicationLoaded &&
    globalState.publications.publicationLoaded[key]
  );
}

export function getPublication(slug: any, page = 0, to = 0, key = null, params = {}) {
  key = key || slug;
  return {
    types: [LOAD_PUBLICATION, LOAD_PUBLICATION_SUCCESS, LOAD_PUBLICATION_FAIL],
    slug,
    key,
    to,
    promise: ({ app }: any) =>
      app.service('publications').find({
        query: {
          slug,
          page,
          to,
          $paginate: false,
          ...params,
        },
      }),
  };
}

export function loadPublications(params: any, lastPublicationId: any, key = 'no-key') {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    key,
    promise: ({ app }: any) => {
      const publicationQuery = lastPublicationId
        ? {
            id: {
              $lt: lastPublicationId,
            },
          }
        : {};

      return app.service('publications').find({
        query: {
          $sort: { id: -1 },
          $limit: 50,
          ...params,
          ...publicationQuery,
        },
      });
    },
  };
}

export function resetLoadedRevisions() {
  return {
    type: RESET_LOADED_PUBLICATIONS,
  };
}
