import { Component } from 'react';
import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { isLoadedPublication, getPublication } from 'redux/modules/publications';

// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
const Publication = loadable(() => import(/* webpackChunkName: 'publication' */ './Publication'));

@provideHooks({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'params' implicitly has an 'any' t... Remove this comment to see the full error message
  fetch: async ({ params, store: { dispatch, getState } }) => {
    const promises = [];

    if (!isLoadedPublication(getState(), `${params.publication}-public`)) {
      promises.push(
        dispatch(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          getPublication(params.publication, 0, 0, `${params.publication}-public`, {
            includeSections: true,
          })
        ).catch(() => null)
      );
    }

    // const paramsSearch = { status: 'public', publication: params.publication, $paginate: false };

    // const key = JSON.stringify(paramsSearch);

    // if (!isLoaded(getState(), key)) {
    //   promises.push(dispatch(load(paramsSearch, key)).catch(() => null));
    // }

    await Promise.all(promises);
  },
})
export default class PublicationContainer extends Component {
  render() {
    return <Publication {...this.props} />;
  }
}
