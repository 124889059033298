/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Theme, emphasize } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { withStyles, WithStyles } from '@mui/styles';
import Collapse from '@mui/material/Collapse';
import SnackbarContent from '../SnackbarContent';
import { getTransitionDirection, omitNonCollapseKeys } from './SnackbarItem.util';
import {
  allClasses,
  REASONS,
  SNACKBAR_INDENTS,
  objectMerge,
  DEFAULTS,
  transformer,
} from '../utils/constants';
import type {
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Shared... Remove this comment to see the full error message
  SharedProps,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Requir... Remove this comment to see the full error message
  RequiredBy,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Transi... Remove this comment to see the full error message
  TransitionHandlerProps,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Snackb... Remove this comment to see the full error message
  SnackbarProviderProps as ProviderProps,
} from '../index';
import defaultIconVariants from '../utils/defaultIconVariants';
import createChainedFunction from '../utils/createChainedFunction';
import { Snack } from '../SnackbarProvider';
import Snackbar from './Snackbar';

const styles = (theme: Theme) => {
  const mode = theme.palette.mode || theme.palette.mode;
  const backgroundColor = emphasize(
    theme.palette.background.default,
    mode === 'light' ? 0.8 : 0.98
  );
  return createStyles({
    ...allClasses.mui,
    lessPadding: {
      paddingLeft: 8 * 2.5,
    },
    variantSuccess: {
      backgroundColor: '#43a047 !important', // green
      color: '#fff !important',
    },
    variantError: {
      backgroundColor: '#d32f2f !important', // dark red
      color: '#fff !important',
    },
    variantInfo: {
      backgroundColor: '#2196f3 !important', // nice blue
      color: '#fff !important',
    },
    variantWarning: {
      backgroundColor: '#ff9800 !important', // amber
      color: '#fff !important',
    },
    contentRoot: {
      ...theme.typography.body2,
      backgroundColor,
      color: theme.palette.getContrastText(backgroundColor),
      alignItems: 'center',
      padding: '6px 16px',
      borderRadius: '4px',
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 0',
    },
    action: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      paddingLeft: 16,
      marginRight: -8,
    },
    wrappedRoot: {
      position: 'relative',
      transform: 'translateX(0)',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    collapseContainer: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    collapseWrapper: {
      transition: theme.transitions.create(['margin-bottom'], { easing: 'ease' }),
      marginTop: SNACKBAR_INDENTS.snackbar.default,
      marginBottom: SNACKBAR_INDENTS.snackbar.default,
    },
    collapseWrapperDense: {
      marginTop: SNACKBAR_INDENTS.snackbar.dense,
      marginBottom: SNACKBAR_INDENTS.snackbar.dense,
    },
    collapseWrapperInner: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });
};

type RemovedProps =
  | 'variant' // the one received from Provider is processed and passed to snack prop
  | 'anchorOrigin' // same as above
  | 'autoHideDuration' // same as above
  | 'preventDuplicate'; // the one recevied from enqueueSnackbar is processed in provider, therefore shouldn't be passed to SnackbarItem */

export interface SnackbarItemProps
  extends WithStyles<typeof styles>,
    RequiredBy<Omit<SharedProps, RemovedProps>, 'onEntered' | 'onExited' | 'onClose'> {
  snack: Snack;
  dense: ProviderProps['dense'];
  iconVariant: ProviderProps['iconVariant'];
  hideIconVariant: ProviderProps['hideIconVariant'];
}

const SnackbarItem: React.FC<SnackbarItemProps> = ({ classes, ...props }) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const [collapsed, setCollapsed] = useState(true);

  useEffect(
    () => (): void => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    []
  );

  const handleClose = createChainedFunction(
    [(props.snack as any).onClose, (props as any).onClose],
    (props.snack as any).key
  );

  const handleEntered: TransitionHandlerProps['onEntered'] = () => {
    if (props.snack.requestClose) {
      handleClose(null, REASONS.INSTRCUTED);
    }
  };

  const handleExitedScreen = (): void => {
    timeout.current = setTimeout(() => {
      setCollapsed(!collapsed);
    }, 125);
  };

  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type '{ snack: ... Remove this comment to see the full error message
    style,
    dense,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ariaAttributes' does not exist on type '... Remove this comment to see the full error message
    ariaAttributes: otherAriaAttributes,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type '{ sna... Remove this comment to see the full error message
    className: otherClassName,
    hideIconVariant,
    iconVariant,
    snack,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'action' does not exist on type '{ snack:... Remove this comment to see the full error message
    action: otherAction,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'content' does not exist on type '{ snack... Remove this comment to see the full error message
    content: otherContent,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'TransitionComponent' does not exist on t... Remove this comment to see the full error message
    TransitionComponent: otherTranComponent,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'TransitionProps' does not exist on type ... Remove this comment to see the full error message
    TransitionProps: otherTranProps,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionDuration' does not exist on ty... Remove this comment to see the full error message
    transitionDuration: otherTranDuration,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onEnter' does not exist on type '{ snack... Remove this comment to see the full error message
    onEnter: ignoredOnEnter,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onEntered' does not exist on type '{ sna... Remove this comment to see the full error message
    onEntered: ignoredOnEntered,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onEntering' does not exist on type '{ sn... Remove this comment to see the full error message
    onEntering: ignoredOnEntering,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExit' does not exist on type '{ snack:... Remove this comment to see the full error message
    onExit: ignoredOnExit,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExited' does not exist on type '{ snac... Remove this comment to see the full error message
    onExited: ignoredOnExited,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExiting' does not exist on type '{ sna... Remove this comment to see the full error message
    onExiting: ignoredOnExiting,
    ...other
  } = props;

  const {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'persist' does not exist on type 'Snack'.
    persist,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'Snack'.
    key,
    open,
    entered,
    requestClose,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Snack... Remove this comment to see the full error message
    className: singleClassName,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'variant' does not exist on type 'Snack'.
    variant,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'content' does not exist on type 'Snack'.
    content: singleContent,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'action' does not exist on type 'Snack'.
    action: singleAction,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ariaAttributes' does not exist on type '... Remove this comment to see the full error message
    ariaAttributes: singleAriaAttributes,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'anchorOrigin' does not exist on type 'Sn... Remove this comment to see the full error message
    anchorOrigin,
    message: snackMessage,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'TransitionComponent' does not exist on t... Remove this comment to see the full error message
    TransitionComponent: singleTranComponent,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'TransitionProps' does not exist on type ... Remove this comment to see the full error message
    TransitionProps: singleTranProps,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitionDuration' does not exist on ty... Remove this comment to see the full error message
    transitionDuration: singleTranDuration,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onEnter' does not exist on type 'Snack'.
    onEnter,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onEntered' does not exist on type 'Snack... Remove this comment to see the full error message
    onEntered,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onEntering' does not exist on type 'Snac... Remove this comment to see the full error message
    onEntering,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExit' does not exist on type 'Snack'.
    onExit,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExited' does not exist on type 'Snack'... Remove this comment to see the full error message
    onExited,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExiting' does not exist on type 'Snack... Remove this comment to see the full error message
    onExiting,
    ...singleSnackProps
  } = snack;

  const icon = {
    ...defaultIconVariants,
    ...iconVariant,
  }[variant];

  const ariaAttributes = {
    'aria-describedby': 'notistack-snackbar',
    ...objectMerge(singleAriaAttributes, otherAriaAttributes),
  };

  const TransitionComponent =
    singleTranComponent || otherTranComponent || DEFAULTS.TransitionComponent;
  const transitionDuration = objectMerge(
    singleTranDuration,
    otherTranDuration,
    DEFAULTS.transitionDuration
  );
  const transitionProps = {
    direction: getTransitionDirection(anchorOrigin),
    ...objectMerge(singleTranProps, otherTranProps),
  };

  let action = singleAction || otherAction;
  if (typeof action === 'function') {
    action = action(key);
  }

  let content = singleContent || otherContent;
  if (typeof content === 'function') {
    content = content(key, snack.message);
  }

  const callbacks: {
    [_key in keyof TransitionHandlerProps]?: any;
  } = ['onEnter', 'onEntering', 'onEntered', 'onExit', 'onExiting', 'onExited'].reduce(
    (acc, cbName) => ({
      ...acc,
      [cbName]: createChainedFunction(
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [props.snack[cbName], props[cbName]],
        (props.snack as any).key
      ),
    }),
    {}
  );

  return (
    <Collapse
      unmountOnExit
      timeout={175}
      in={collapsed}
      classes={omitNonCollapseKeys(classes, dense)}
      onExited={callbacks.onExited}
    >
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; open: boolean; classNam... Remove this comment to see the full error message */}
      <Snackbar
        {...other}
        {...singleSnackProps}
        open={open}
        className={clsx(
          classes.root,
          classes.wrappedRoot,
          classes[transformer.toAnchorOrigin(anchorOrigin)]
        )}
        onClose={handleClose}
      >
        <TransitionComponent
          appear
          in={open}
          timeout={transitionDuration}
          {...transitionProps}
          onExit={callbacks.onExit}
          onExiting={callbacks.onExiting}
          onExited={handleExitedScreen}
          onEnter={callbacks.onEnter}
          onEntering={callbacks.onEntering}
          // order matters. first callbacks.onEntered to set entered: true,
          // then handleEntered to check if there's a request for closing
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
          onEntered={createChainedFunction([callbacks.onEntered, handleEntered])}
        >
          {content || (
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any[]; role: string; style: any;... Remove this comment to see the full error message
            <SnackbarContent
              {...ariaAttributes}
              role="alert"
              style={style}
              className={clsx(
                classes.contentRoot,
                { [classes.lessPadding]: !hideIconVariant && icon },
                classes[transformer.toVariant(variant)],
                otherClassName,
                singleClassName
              )}
            >
              <div id={ariaAttributes['aria-describedby']} className={classes.message}>
                {!hideIconVariant ? icon : null}
                {snackMessage}
              </div>
              {action && <div className={classes.action}>{action}</div>}
            </SnackbarContent>
          )}
        </TransitionComponent>
      </Snackbar>
    </Collapse>
  );
};

export default withStyles(styles)(SnackbarItem);
