/* eslint-disable react/no-multi-comp */
// eslint-disable-next-line max-classes-per-file
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';

const defaults = {
  AuthenticatingComponent: () => null, // dont render anything while authenticating
  FailureComponent: () => null, // dont render anything on failure of the predicate
  wrapperDisplayName: 'AuthWrapper',
};

declare class UserAuthWrapperType extends PureComponent {}

export default (args: any) => {
  const { AuthenticatingComponent, FailureComponent, wrapperDisplayName }: any = {
    ...defaults,
    ...args,
  };

  // Wraps the component that needs the auth enforcement
  function wrapComponent(DecoratedComponent: any) {
    const displayName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component';

    class UserAuthWrapper extends PureComponent {
      render() {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'isAuthenticated' does not exist on type ... Remove this comment to see the full error message
        const { isAuthenticated, isAuthenticating, isTermsAccepted } = this.props;
        if (isAuthenticated) {
          return <DecoratedComponent {...this.props} />;
        }
        if (isAuthenticating) {
          return <AuthenticatingComponent {...this.props} />;
        }
        if (isTermsAccepted) {
          return <DecoratedComponent {...this.props} />;
        }
        return <FailureComponent {...this.props} />;
      }
    }

    (UserAuthWrapper as any).defaultProps = {
      isAuthenticating: false,
    };
    (UserAuthWrapper as any).displayName = `${wrapperDisplayName}(${displayName})`;
    (UserAuthWrapper as any).propTypes = {
      isAuthenticated: PropTypes.bool,
      isAuthenticating: PropTypes.bool,
      isTermsAccepted: PropTypes.bool,
    };

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'UserAuthWrapperType' is not assi... Remove this comment to see the full error message
    return hoistStatics(UserAuthWrapper as unknown as UserAuthWrapperType, DecoratedComponent);
  }

  return wrapComponent;
};
