import config from '../config';
import type { Images } from '../../api/database/Models';

const { domainImages } = config;

const getImageLink = (md5: string, version: string, format: string) => {
  if (!format) {
    return false;
  }
  const formatSplit = format.split('/');
  const ext = formatSplit[formatSplit.length - 1];
  if (version.indexOf('webp') !== -1) {
    return `${domainImages}/media/${version}/${md5}*${ext}.webp`;
  }
  return `${domainImages}/media/${version}/${md5}.${ext}`;
  // return `https://webmediums.com/media/${version}/${md5}.${ext}`;
};

export default getImageLink;
const getImage = (image: Images, version: any): string | boolean => {
  if (image && image.md5) return getImageLink(image.md5, version, image.format);
  return false;
};

export { getImage };
