import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { isLoadedPublication, getPublication } from 'redux/modules/publications';

const PublicationAbout = loadable(
  () => import(/* webpackChunkName: 'publication-about' */ './About')
);

function PublicationAboutContainer(props: any) {
  return <PublicationAbout {...props} />;
}

export default provideHooks({
  fetch: async ({ params, store: { dispatch, getState } }: any) => {
    const promises = [];

    if (!isLoadedPublication(getState(), `${params.publication}-about`)) {
      promises.push(
        dispatch(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          getPublication(params.publication, 0, 0, `${params.publication}-about`, {
            includeWriters: true,
            includeEditors: true,
          })
        ).catch(() => null)
      );
    }

    await Promise.all(promises);
  },
})(PublicationAboutContainer);
