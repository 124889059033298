import type * as Y from 'yjs';
import type { Editor } from '@tiptap/react';

export interface EditorBody {
  editor: Editor | null;
  ydoc: Y.Doc | null;
  key: string;
}
export interface EditorAction extends EditorBody {
  type: string;
}

const SET_EDITOR = 'redux-webmediums/online/SET_EDITOR';

export interface EditorsType {
  [x: string]: {
    editor?: Editor;
    ydoc?: Y.Doc;
  };
}

export interface EditorsState {
  editors?: EditorsType;
}

const initialState: EditorsState = {
  editors: {},
};

// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action: EditorAction) {
  switch (action.type) {
    case SET_EDITOR: {
      const { key } = action;
      return {
        editors: {
          ...state.editors,
          [key]: {
            editor: action.editor,
            ydoc: action.ydoc,
          },
        },
      };
    }
    default:
      return state;
  }
}

export const setEditor = ({ editor, ydoc, key }: EditorBody) => {
  return {
    type: SET_EDITOR,
    editor,
    ydoc,
    key,
  };
};
