import { FORM_ERROR } from 'final-form';

const CHANGE_PASSWORD_START = 'redux-webmediums/account/settings/CHANGE_PASSWORD_START';
const CHANGE_PASSWORD_SUCCESS = 'redux-webmediums/account/settings/CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'redux-webmediums/account/settings/CHANGE_PASSWORD_FAIL';

const CHANGE_IDENTITY_START = 'redux-webmediums/account/settings/CHANGE_IDENTITY_START';
const CHANGE_IDENTITY_SUCCESS = 'redux-webmediums/account/settings/CHANGE_IDENTITY_SUCCESS';
const CHANGE_IDENTITY_FAIL = 'redux-webmediums/account/settings/CHANGE_IDENTITY_FAIL';

const VERIFY_SIGN_UP_START = 'redux-webmediums/account/settings/VERIFY_SIGN_UP_START';
const VERIFY_SIGN_UP_SUCCESS = 'redux-webmediums/account/settings/VERIFY_SIGN_UP_SUCCESS';
const VERIFY_SIGN_UP_FAIL = 'redux-webmediums/account/settings/VERIFY_SIGN_UP_FAIL';

const VERIFY_CHANGES_START = 'redux-webmediums/account/settings/VERIFY_CHANGES_START';
const VERIFY_CHANGES_SUCCESS = 'redux-webmediums/account/settings/VERIFY_CHANGES_SUCCESS';
const VERIFY_CHANGES_FAIL = 'redux-webmediums/account/settings/VERIFY_CHANGES_FAIL';

const RESEND_VERIFY_SIGNUP_START = 'redux-webmediums/account/settings/RESEND_VERIFY_SIGNUP_START';
const RESEND_VERIFY_SIGNUP_SUCCESS =
  'redux-webmediums/account/settings/RESEND_VERIFY_SIGNUP_SUCCESS';
const RESEND_VERIFY_SIGNUP_FAIL = 'redux-webmediums/account/settings/RESEND_VERIFY_SIGNUP_FAIL';

const SEND_RESET_PWD_START = 'redux-webmediums/account/settings/SEND_RESET_PWD_START';
const SEND_RESET_PWD_SUCCESS = 'redux-webmediums/account/settings/SEND_RESET_PWD_SUCCESS';
const SEND_RESET_PWD_FAIL = 'redux-webmediums/account/settings/SEND_RESET_PWD_FAIL';

const RESET_PWD_LONG_START = 'redux-webmediums/account/settings/RESET_PWD_LONG_START';
const RESET_PWD_LONG_SUCCESS = 'redux-webmediums/account/settings/RESET_PWD_LONG_SUCCESS';
const RESET_PWD_LONG_FAIL = 'redux-webmediums/account/settings/RESET_PWD_LONG_FAIL';

const CLEAR_STATE = 'redux-webmediums/account/settings/CLEAR_STATE';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordResult: (action as any).result,
        loading: false,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changePasswordResultError: (action as any).error,
        loading: false,
      };

    case CHANGE_IDENTITY_START:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_IDENTITY_SUCCESS:
      return {
        ...state,
        changeIdentityResult: (action as any).result,
        loading: false,
      };
    case CHANGE_IDENTITY_FAIL:
      return {
        ...state,
        changeIdentityResultError: (action as any).error,
        loading: false,
      };

    case VERIFY_SIGN_UP_START:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_SIGN_UP_SUCCESS:
      return {
        ...state,
        verifySignUpResult: (action as any).result,
        verifySignUpResultError: null,
        loading: false,
      };
    case VERIFY_SIGN_UP_FAIL:
      return {
        ...state,
        verifySignUpResultError: (action as any).error,
        loading: false,
      };

    case VERIFY_CHANGES_START:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_CHANGES_SUCCESS:
      return {
        ...state,
        verifyChangesResult: (action as any).result,
        verifyChangesResultError: null,
        loading: false,
      };
    case VERIFY_CHANGES_FAIL:
      return {
        ...state,
        verifyChangesResultError: (action as any).error,
        loading: false,
      };

    case RESEND_VERIFY_SIGNUP_START:
      return {
        ...state,
        loading: true,
      };
    case RESEND_VERIFY_SIGNUP_SUCCESS:
      return {
        ...state,
        resendVerifySignupResult: (action as any).result,
        resendVerifySignupResultError: null,
        loading: false,
      };
    case RESEND_VERIFY_SIGNUP_FAIL:
      return {
        ...state,
        resendVerifySignupResultError: (action as any).error,
        loading: false,
      };
    case SEND_RESET_PWD_START:
      return {
        ...state,
        loading: true,
      };
    case SEND_RESET_PWD_SUCCESS:
      return {
        ...state,
        sendResetPwdResult: (action as any).result,
        sendResetPwdResultError: null,
        loading: false,
      };
    case SEND_RESET_PWD_FAIL:
      return {
        ...state,
        sendResetPwdResultError: (action as any).error,
        loading: false,
      };

    case RESET_PWD_LONG_START:
      return {
        ...state,
        loading: true,
      };
    case RESET_PWD_LONG_SUCCESS:
      return {
        ...state,
        resetPwdLongResult: (action as any).result,
        resetPwdLongResultError: null,
        loading: false,
      };
    case RESET_PWD_LONG_FAIL:
      return {
        ...state,
        resetPwdLongResultError: (action as any).error,
        loading: false,
      };
    case CLEAR_STATE:
      return {
        ...state,
        sendResetPwdResultError: null,
        sendResetPwdResult: null,
        loading: false,
      };

    default:
      return state;
  }
}

const catchValidation = (error: any) => {
  if (error.message) {
    if (error.message === 'Validation failed' && error.data) {
      return Promise.reject(error.data);
    }
    const err = {
      [FORM_ERROR]: error.message,
    };
    return Promise.reject(err);
  }
  return Promise.reject(error);
};

export function verifySignUp(slug: any) {
  return {
    types: [VERIFY_SIGN_UP_START, VERIFY_SIGN_UP_SUCCESS, VERIFY_SIGN_UP_FAIL],
    promise: async ({ app }: any) =>
      app.service('authManagement').create({
        action: 'verifySignupLong',
        value: slug,
      }),
  };
}

export function verifyChanges(slug: any) {
  return {
    types: [VERIFY_CHANGES_START, VERIFY_CHANGES_SUCCESS, VERIFY_CHANGES_FAIL],
    promise: async ({ app }: any) =>
      app.service('authManagement').create({
        action: 'verifySignupLong',
        value: slug,
      }),
  };
}

export function passwordChange(email: any, oldPassword: any, password: any) {
  return {
    types: [CHANGE_PASSWORD_START, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    promise: async ({ app }: any) =>
      app.service('authManagement').create({
        action: 'passwordChange',
        value: {
          user: {
            email,
          },
          oldPassword,
          password,
        },
      }),
  };
}

export function identityChange(email: any, password: any, changes: any) {
  return {
    types: [CHANGE_IDENTITY_START, CHANGE_IDENTITY_SUCCESS, CHANGE_IDENTITY_FAIL],
    promise: async ({ app }: any) =>
      app
        .service('authManagement')
        .create({
          action: 'identityChange',
          value: {
            user: {
              email,
            },
            password,
            changes,
          },
        })
        .catch(catchValidation),
  };
}

export function resendVerifySignup(email: any) {
  return {
    types: [CHANGE_IDENTITY_START, CHANGE_IDENTITY_SUCCESS, CHANGE_IDENTITY_FAIL],
    promise: async ({ app }: any) =>
      app
        .service('authManagement')
        .create({
          action: 'resendVerifySignup',
          value: {
            email,
          },
        })
        .catch(catchValidation),
  };
}

export function sendResetPwd(email: any) {
  return {
    types: [SEND_RESET_PWD_START, SEND_RESET_PWD_SUCCESS, SEND_RESET_PWD_FAIL],
    promise: async ({ app }: any) =>
      app
        .service('authManagement')
        .create({
          action: 'sendResetPwd',
          value: {
            email,
          },
        })
        .catch(catchValidation),
  };
}

export function resetPwdLong(token: any, password: any) {
  return {
    types: [RESET_PWD_LONG_START, RESET_PWD_LONG_SUCCESS, RESET_PWD_LONG_FAIL],
    promise: async ({ app }: any) =>
      app
        .service('authManagement')
        .create({
          action: 'resetPwdLong',
          value: { token, password },
        })
        .catch(catchValidation),
  };
}

export function clearState() {
  return {
    type: CLEAR_STATE,
  };
}
