import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { isLoadedPublication, getPublication } from 'redux/modules/publications';

import { isLoaded as isStoriesLoaded, load as loadStories } from 'redux/modules/stories';

const PublicationArchive = loadable(
  () => import(/* webpackChunkName: 'publication-archive' */ './Archive')
);

function PublicationArchiveContainer(props: any) {
  return <PublicationArchive {...props} />;
}

export default provideHooks({
  fetch: async ({ params, store: { dispatch, getState } }: any) => {
    const promises = [];

    const state = getState();

    if (
      !isLoadedPublication(state, `${params.publication}-archive-${params.year}-${params.month}`)
    ) {
      promises.push(
        dispatch(
          getPublication(
            params.publication,
            0,
            0,
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            `${params.publication}-archive-${params.year}-${params.month}`,
            {
              includeActiveIndex: true,
              includeBucketMonths: params.year,
              includeBucketDays: params.month,
            }
          )
        ).catch(() => null)
      );
    }

    const paramsSearch = {
      status: 'public',
      $skip: 0,
      $limit: 20,
      $paginate: false,
      $exclude: ['content'],
      $sort: {
        views: -1,
      },
      publication: params.publication,
    };

    if (params.year) {
      (paramsSearch as any).publishDate = {
        $gte: new Date(Date.UTC(params.year, 1, 1, 0, 0, 0)).toISOString(),
        $lte: new Date(Date.UTC(params.year, 12, 31, 23, 59, 59)).toISOString(),
      };
    }

    const monthJS = parseInt(params.month, 10) - 1;

    if (params.month) {
      const days = new Date(params.year, monthJS, 0).getDate();
      (paramsSearch as any).publishDate = {
        $gte: new Date(Date.UTC(params.year, monthJS, 1, 0, 0, 0)).toISOString(),
        $lte: new Date(Date.UTC(params.year, monthJS, days, 23, 59, 59)).toISOString(),
      };
    }

    if (params.day) {
      (paramsSearch as any).publishDate = {
        $gte: new Date(Date.UTC(params.year, monthJS, params.day, 0, 0, 0)).toISOString(),
        $lte: new Date(Date.UTC(params.year, monthJS, params.day, 23, 59, 59)).toISOString(),
      };
    }

    const key = JSON.stringify(paramsSearch);

    if (!isStoriesLoaded(state, key)) {
      paramsSearch.$skip = 0;
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
      if (!__CLIENT__) {
        promises.push(dispatch(loadStories(paramsSearch, key)).catch(() => null));
      } else {
        dispatch(loadStories(paramsSearch, key)).catch(() => null);
      }
    }

    await Promise.all(promises);
  },
})(PublicationArchiveContainer);
