import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import classNames from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  followPublication,
  unFollowPublication,
  followUser,
  unFollowUser,
} from 'redux/modules/follow';
import { useLocation, useNavigate } from 'react-router';
import type { User } from '../../../api/database/Models';

const useStyles = makeStyles(
  {
    buttonFollow: {
      minWidth: 0,
      minHeight: 0,
      fontSize: 15,
      marginLeft: 10,
    },
    sizeDefault: {
      height: 19,
      padding: '0 10px',
      lineHeight: '19px',
    },
    sizeMedium: {
      height: 32,
      lineHeight: '32px',
      padding: '0 14px',
    },
    active: {
      backgroundColor: '#02B875',
      color: 'rgba(255, 255, 255, 1)',
      '&:hover': {
        backgroundColor: '#02B875',
        color: 'rgba(255, 255, 255, 1)',
      },
    },
  },
  { name: 'WMFollow' }
);

const ButtonFollow = ({
  type = 'publication',
  size = 'default',
  object = {},
}: {
  object: any;
  type: 'publication' | 'user';
  size: 'default' | 'medium';
}): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userLogged }: { userLogged: User } = useSelector((state: any) => {
    return {
      // publication: state.follow && state.follow.publication,
      // user: state.follow && state.follow.user,
      userLogged: state.auth.user,
    };
  });
  const follow = () => {
    if (!userLogged) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
      return;
    }
    if (object.userFollow) {
      object.userFollow = false;
      if (type === 'publication') {
        dispatch(unFollowPublication(object.uid));
      } else {
        dispatch(unFollowUser(object.uid));
      }
    } else {
      object.userFollow = true;
      if (type === 'publication') {
        dispatch(followPublication(object.uid));
      } else {
        dispatch(followUser(object.uid));
      }
    }
  };

  const classesSizes = {
    default: classes.sizeDefault,
    medium: classes.sizeMedium,
  };

  return (
    <Button
      disableFocusRipple
      disableRipple
      disableTouchRipple
      onClick={follow}
      className={classNames(classes.buttonFollow, classesSizes[size], {
        [classes.active]: object.userFollow,
      })}
      variant="outlined"
      color="secondary"
    >
      {object.userFollow ? 'Following' : 'Follow'}
    </Button>
  );
};
ButtonFollow.propTypes = {
  object: PropTypes.objectOf(PropTypes.any).isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
};

export default ButtonFollow;
