import differenceInMinutes from 'date-fns/differenceInMinutes';

const LOAD = 'redux-webmediums/topics/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/topics/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/topics/LOAD_FAIL';

const initialState = {
  loaded: false,
  result: {},
  related: {},
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        timeFetch: { ...(state as any).timeFetch, [(action as any).key]: new Date().getTime() },
        result: { ...(action as any).result, [(action as any).key]: (action as any).result },
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState: any, key: any) {
  return (
    globalState.topics &&
    globalState.topics.loaded[key] &&
    differenceInMinutes(new Date().getTime(), globalState.topics.timeFetch[key]) < 3
  );
}

export function load(params = {}, key = 'key') {
  const query = {
    $limit: 10,
    ...params,
  };
  return {
    batchCall: [
      'find',
      'topics',
      query,
      {
        key,
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      },
    ],
    key,
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: any) =>
      app.service('topics').find({
        query,
      }),
  };
}
