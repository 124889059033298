import { StrictMode, useMemo } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as AppProvider } from 'contexts/App';
import { Provider as RestAppProvider } from 'contexts/RestApp';
import ThemeContext from 'components/Context/ThemeContext';
import LocaleContext from 'components/Context/LocaleContext';
import { HelmetProvider } from 'react-helmet-async';
import RouterContext from 'components/Context/RouterContext';
import localeEnDateFns from 'date-fns/locale/en-US';
import localeEsDateFns from 'date-fns/locale/es';

const localesDateFns: any = {
  en: localeEnDateFns,
  es: localeEsDateFns,
};

// /dateFns
type OwnProps = {
  pageContext: {
    [key: string]: any;
  };
  app: {
    [key: string]: any;
  };
  restApp: {
    [key: string]: any;
  };
  store: {
    [key: string]: any;
  };
  intl?: {
    [key: string]: any;
  };
  children: React.ReactNode;
  helmetContext?: {
    [key: string]: any;
  };
  routerContext?: {
    [key: string]: any;
  };
};
const defaultProps = {
  helmetContext: {},
  intl: {
    lang: 'en',
    messages: {},
  },
};
type Props = OwnProps & typeof defaultProps;

const Provider = ({
  app,
  restApp,
  intl,
  store,
  pageContext,
  children,
  helmetContext,
  routerContext,
}: Props) => {
  const memoizedLocaleContextValue = useMemo(() => {
    return { localeContext: intl, dateFns: localesDateFns[intl.lang] };
  }, [intl, localesDateFns[intl.lang]]);

  return (
    <StrictMode>
      <RouterContext.Provider value={routerContext}>
        <HelmetProvider context={helmetContext}>
          <LocaleContext.Provider value={memoizedLocaleContextValue}>
            <ThemeContext.Provider value={pageContext}>
              <AppProvider value={app}>
                <RestAppProvider value={restApp}>
                  <ReduxProvider store={store}>{children}</ReduxProvider>
                </RestAppProvider>
              </AppProvider>
            </ThemeContext.Provider>
          </LocaleContext.Provider>
        </HelmetProvider>
      </RouterContext.Provider>
    </StrictMode>
  );
};

Provider.defaultProps = defaultProps;

export default Provider;
