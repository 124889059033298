import { forwardRef } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { withStyles, WithStyles } from '@mui/styles';
// @ts-expect-error ts-migrate(2305) FIXME: Module '"../index"' has no exported member 'Snackb... Remove this comment to see the full error message
import type { SnackbarContentProps } from '../index';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        flexGrow: 'initial',
        minWidth: 288,
      },
    },
  });

interface Props extends WithStyles<typeof styles>, SnackbarContentProps {}

const SnackbarContent = forwardRef<HTMLDivElement, Props>(
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
  ({ classes, className, ...props }, ref) => (
    <div ref={ref} className={clsx(classes.root, className)} {...props} />
  )
);

export default withStyles(styles)(SnackbarContent);
