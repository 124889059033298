import { StyleSheetManager } from 'styled-components';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { useTheme } from '@mui/material/styles';

// Cache for the ltr version of the styles
export const cacheLtr = createCache({ key: 'css', prepend: true });
cacheLtr.compat = true;

// Cache for the rtl version of the styles
const cacheRtl = createCache({
  key: 'rtl',
  prepend: true,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(element: unknown, index: unknown, children:... Remove this comment to see the full error message
  stylisPlugins: [rtlPlugin],
});
cacheRtl.compat = true;

type Props = {
  children?: React.ReactNode;
};

const StyledEngineProvider = (props: Props) => {
  const theme = useTheme();

  const rtl = theme.direction === 'rtl';

  const { children } = props;

  return (
    <StyleSheetManager stylisPlugins={rtl ? [rtlPlugin] : []}>
      <CacheProvider value={rtl ? cacheRtl : cacheLtr}>{children}</CacheProvider>
    </StyleSheetManager>
  );
};

export default StyledEngineProvider;
