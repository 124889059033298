const SHOW_HINTS = 'redux-webmediums/tools/SHOW_HINTS';
const HIDE_HINTS = 'redux-webmediums/tools/HIDE_HINTS';

const initialState = {
  showHints: false,
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case SHOW_HINTS:
      return {
        ...state,
        showHints: true,
      };

    case HIDE_HINTS:
      return {
        ...state,
        showHints: false,
      };

    default:
      return state;
  }
}

export function showHints() {
  return {
    type: SHOW_HINTS,
  };
}

export function hideHints() {
  return {
    type: HIDE_HINTS,
  };
}
