// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import loadable from '@loadable/component';
import { find as findTags } from 'redux/modules/tags';
import { search as searchPub, isLoadedSearch } from 'redux/modules/publications';
import { isLoaded as isStoriesLoaded, load as loadStories } from 'redux/modules/stories';

// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
const Search = loadable(() => import(/* webpackChunkName: 'search' */ './Search'));
const Middleware = (props: any) => {
  return <Search {...props} />;
};
export default provideHooks({
  fetch: async ({ store: { dispatch, getState }, location }: any) => {
    const keyword = location.search.replace('?q=', '').replace(/%20/g, ' ');
    const state = getState();
    const promises = [];

    const paramsSearch = {
      status: 'public',
      $skip: 0,
      $limit: 10,
      $paginate: false,
      search: keyword,
    };

    const paramsSearchPub = {
      $skip: 0,
      $limit: 10,
      $paginate: false,
      name: {
        $like: `%${keyword}%`,
      },
    };
    const key = JSON.stringify(paramsSearch);
    if (!isStoriesLoaded(state, key)) {
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
      if (!__CLIENT__) {
        promises.push(dispatch(loadStories(paramsSearch, key)).catch(() => null));
      } else {
        dispatch(loadStories(paramsSearch, key)).catch(() => null);
      }
    }

    if (!isLoadedSearch(state, keyword)) {
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
      if (!__CLIENT__) {
        promises.push(dispatch(searchPub(paramsSearchPub, keyword)).catch(() => null));
      } else {
        dispatch(searchPub(paramsSearchPub, keyword)).catch(() => null);
      }
    }

    // if (!isStoriesLoaded(state, key)) {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
    if (!__CLIENT__) {
      promises.push(dispatch(findTags(keyword)).catch(() => null));
    } else {
      dispatch(findTags(keyword)).catch(() => null);
    }
    // }

    await Promise.all(promises);
  },
})(Middleware);
