const INCREMENT = 'redux-webmediums/claps/INCREMENT';

const REMOVE = 'redux-webmediums/claps/REMOVE';

const SET = 'redux-webmediums/claps/SET';
const SAVE = 'redux-webmediums/claps/SAVE';
const SAVE_SUCCESS = 'redux-webmediums/claps/SAVE_SUCCESS';
const SAVE_FAIL = 'redux-webmediums/claps/SAVE_FAIL';

const initialState = {
  count: {},
  totalClaps: {},
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case INCREMENT: {
      const { count, totalClaps } = state;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const keyCount = count[(action as any).key] || 0;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const oTotalClaps = totalClaps[(action as any).key] || 0;
      return {
        ...state,
        count: { ...state.count, [(action as any).key]: keyCount + 1 },
        totalClaps: { ...state.totalClaps, [(action as any).key]: oTotalClaps + 1 },
      };
    }
    case REMOVE: {
      const { count, totalClaps } = state;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const keyCount = count[(action as any).key] || 0;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const oTotalClaps = totalClaps[(action as any).key] || 0;
      return {
        ...state,
        count: { ...state.count, [(action as any).key]: 0 },
        totalClaps: { ...state.totalClaps, [(action as any).key]: oTotalClaps - keyCount },
      };
    }
    case SET: {
      return {
        ...state,
        count: { ...state.count, [(action as any).key]: (action as any).count },
        totalClaps: { ...state.totalClaps, [(action as any).key]: (action as any).totalClaps },
      };
    }
    case SAVE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SAVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalClaps: { ...state.totalClaps, [(action as any).key]: (action as any).result.claps },
      };
    }
    case SAVE_FAIL: {
      return {
        ...state,
        loading: false,
        error: (action as any).result,
      };
    }
    default:
      return state;
  }
}

export function increment(key: any) {
  return {
    type: INCREMENT,
    key,
  };
}

export function remove(key: any) {
  return {
    type: REMOVE,
    key,
  };
}

export function set(key: any, totalClaps: any, count: any) {
  return {
    type: SET,
    key,
    count,
    totalClaps,
  };
}

export function save(key: any, uid: any, claps: any) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    key,
    promise: ({ app }: any) => app.service('claps').patch(uid, { claps }),
  };
}
