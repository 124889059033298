import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { getUser, isUserLoaded } from 'redux/modules/users';

const Profile = loadable(() => import(/* webpackChunkName: 'profile' */ './Profile'));
const Middflewware = (props: any) => {
  return <Profile {...props} />;
};
export default provideHooks({
  fetch: async ({ params, store: { dispatch, getState } }: any) => {
    const promises = [];
    if (!isUserLoaded(getState(), params.username)) {
      promises.push(dispatch(getUser(params.username, { includePosts: true })).catch(() => null));
    }

    await Promise.all(promises);
  },
})(Middflewware);
