import differenceInSeconds from 'date-fns/differenceInSeconds';
import { FORM_ERROR } from 'final-form';

const LOAD = 'redux-webmediums/me/users/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/me/users/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/me/users/LOAD_FAIL';

const LOAD_USER = 'redux-webmediums/me/users/LOAD_USERS';
const LOAD_USER_SUCCESS = 'redux-webmediums/me/users/LOAD_USERS_SUCCESS';
const LOAD_USER_FAIL = 'redux-webmediums/me/users/LOAD_USERS_FAIL';

const CREATE_USERS = 'redux-webmediums/users/CREATE_USERS';
const CREATE_USERS_SUCCESS = 'redux-webmediums/users/CREATE_USERS_SUCCESS';
const CREATE_USERS_FAIL = 'redux-webmediums/users/CREATE_USERS_FAIL';

const UPDATE_USERS = 'redux-webmediums/users/UPDATE_USERS';
const UPDATE_USERS_SUCCESS = 'redux-webmediums/users/UPDATE_USERS_SUCCESS';
const UPDATE_USERS_FAIL = 'redux-webmediums/users/UPDATE_USERS_FAIL';

const catchValidation = (error: any) => {
  if (error.message) {
    if (error.message === 'Validation failed' && error.data) {
      return Promise.reject(error.data);
    }
    const err = {
      [FORM_ERROR]: error.message,
    };
    return Promise.reject(err);
  }
  return Promise.reject(error);
};

const initialState = {
  loaded: false,
  saved: true,
  loadedUsers: false,
  users: {
    loading: true,
    data: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case LOAD:
      (state as any).loading = true;
      return {
        ...state,
      };
    case LOAD_SUCCESS: {
      if ((action as any).result.skip === 0) {
        state.users.data = [];
      }
      const copy = {
        loading: false,
        loaded: true,
        lastTimeUsersLoaded:
          (action as any).result.skip === 0 ? new Date() : (state as any).lastTimeUsersLoaded,
        ...(action as any).result,
      };
      copy.data = state.users.data.concat(copy.data);

      return {
        ...state,
        users: { ...copy },
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        users: {
          loading: false,
          loaded: false,
          data: [],
        },
        error: (action as any).error,
      };
    case LOAD_USER:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: (action as any).result,
      };
    case LOAD_USER_FAIL:
      return {
        ...state,
        loading: false,
        loadedTopic: false,
        error: (action as any).error,
      };

    case CREATE_USERS:
      return {
        ...state,
        loading: true,
      };
    case CREATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        myLoaded: false,
        followingLoaded: false,
        publication: { ...(state as any).publication, [(action as any).result.slug]: null },
        publicationLoaded: {
          ...(state as any).publicationLoaded,
          [(action as any).result.slug]: false,
        },
      };
    case CREATE_USERS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    case UPDATE_USERS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        myLoaded: false,
        followingLoaded: false,
        publication: { ...(state as any).publication, [(action as any).result.slug]: null },
        publicationLoaded: {
          ...(state as any).publicationLoaded,
          [(action as any).result.slug]: false,
          [`${(action as any).result.slug}-public`]: false,
          [`${(action as any).result.slug}-settings`]: false,
          [`${(action as any).result.slug}-about`]: false,
        },
      };
    case UPDATE_USERS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState: any) {
  return (
    globalState.meUsers &&
    globalState.meUsers.users &&
    globalState.meUsers.users.loaded &&
    globalState.meUsers.users.lastTimeUsersLoaded &&
    differenceInSeconds(new Date(), globalState.meUsers.users.lastTimeUsersLoaded) < 5
  );
}

export function load($skip = 0, extraParams = {}) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: any) =>
      app.service('me/users').find({
        query: {
          $sort: { createdAt: -1 },
          $limit: 10,
          $skip,
          ...extraParams,
        },
      }),
  };
}

export function isLoadeUsers(globalState: any, topicId: any) {
  return (
    globalState.meUsers &&
    globalState.meUsers.loadedUsers &&
    globalState.meUsers.loadedUsers[topicId]
  );
}

export function loadUser(userId: any, extreaParams = {}) {
  return {
    types: [LOAD_USER, LOAD_USER_SUCCESS, LOAD_USER_FAIL],
    promise: ({ app }: any) =>
      app
        .service('me/users')
        .find({
          query: {
            uid: userId,
            $sort: { createdAt: -1 },
            $limit: 1,
            ...extreaParams,
          },
        })
        .then((data: any) => data.data[0]),
  };
}

export function create(data: any) {
  return {
    types: [CREATE_USERS, CREATE_USERS_SUCCESS, CREATE_USERS_FAIL],
    promise: ({ app }: any) => app.service('me/users').create(data).catch(catchValidation),
  };
}

export function update(data: any) {
  return {
    types: [UPDATE_USERS, UPDATE_USERS_SUCCESS, UPDATE_USERS_FAIL],
    promise: ({ app }: any) => app.service('me/users').patch(data.uid, data).catch(catchValidation),
  };
}
