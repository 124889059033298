/* eslint-disable react-hooks/rules-of-hooks */
import { useRef, useReducer, useState, useEffect, useLayoutEffect } from 'react';
// import { hot } from 'react-hot-loader/root';
import routes from 'routes';
import { useRoutes, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const RenderRoutes = () => {
  const element = useRoutes(routes);
  return element;
};

type OwnRouterTriggerProps = {
  trigger?: (...args: any[]) => any;
};
const defaultProps = {
  trigger: () => {
    return null;
  },
};
type RouterTriggerProps = OwnRouterTriggerProps & typeof defaultProps;

const RouterTrigger = (props: RouterTriggerProps) => {
  const children = <RenderRoutes />;
  if ((process as any).browser) {
    const historyRef = useRef();
    if (historyRef.current == null) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'BrowserHistory<State>' is not assignable to ... Remove this comment to see the full error message
      historyRef.current = createBrowserHistory({ window });
    }
    const history = historyRef.current;
    const [state, dispatch] = useReducer((_: any, action: any) => action, {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      action: history.action,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      location: history.location,
    });
    const [_state, _setState] = useState({
      location: state.location,
    });
    useEffect(() => {
      const { trigger } = props;
      const navigated =
        (window as any).__PRELOADED__ ||
        `${state.location.pathname}${state.location.search}` !==
          `${_state.location.pathname}${_state.location.search}`;
      if (navigated) {
        trigger(state.location.pathname, history, state.location)
          .catch((err: any) => console.log('Failure in RouterTrigger:', err))
          .then(() => {
            // clear previousLocation so the next screen renders
            _setState({ location: state.location });
          });
      }
    });
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    useLayoutEffect(() => history.listen(dispatch), [history]);

    const navigated =
      (window as any).__PRELOADED__ ||
      `${state.location.pathname}${state.location.search}` !==
        `${_state.location.pathname}${_state.location.search}`;

    const location =
      _state.location !== state.location && !navigated ? state.location : _state.location;

    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Naviga... Remove this comment to see the full error message
      <Router action={state.action} location={location} navigator={history}>
        {children}
      </Router>
    );
  }

  return children;
};

RouterTrigger.defaultProps = defaultProps;

export default RouterTrigger;
