import auth from './modules/auth';
import settings from './modules/settings';
import notifs from './modules/notifs';
import notifier from './modules/notifier';
import stories from './modules/stories';
import tags from './modules/tags';
import users from './modules/users';
import claps from './modules/claps';
import follow from './modules/follow';
import tools from './modules/tools';
import publications from './modules/publications';
import topics from './modules/topics';
import online from './modules/online';
import editor from './modules/editor';

const reducers = {
  online,
  auth,
  settings,
  notifs,
  notifier,
  stories,
  tags,
  users,
  publications,
  follow,
  claps,
  tools,
  topics,
  editor,
};

export default function createReducers(asyncReducers?: any): typeof reducers {
  return {
    ...reducers,
    ...asyncReducers,
  };
}
