const LOAD = 'redux-webmediums/me/revisions/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/me/revisions/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/me/revisions/LOAD_FAIL';

const LOAD_REVISION = 'redux-webmediums/me/revisions/LOAD_REVISION';
const LOAD_REVISION_SUCCESS = 'redux-webmediums/me/revisions/LOAD_REVISION_SUCCESS';
const LOAD_REVISION_FAIL = 'redux-webmediums/me/revisions/LOAD_REVISION_FAIL';

const RESTORE = 'redux-webmediums/me/revisions/RESTORE';
const RESTORE_SUCCESS = 'redux-webmediums/me/revisions/RESTORE_SUCCESS';
const RESTORE_FAIL = 'redux-webmediums/me/revisions/RESTORE_FAIL';

const RESET_LOADED_REVISIONS = 'redux-webmediums/me/revisions/RESET_LOADED_REVISIONS';

const initialState = {
  loaded: false,
  revisions: [],
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        hasMore: (action as any).result.length !== 0,
        revisions: state.revisions.concat((action as any).result),
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        hasMore: false,
        error: (action as any).error,
      };
    case LOAD_REVISION:
      return {
        ...state,
        loading: true,
      };
    case LOAD_REVISION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        revision: (action as any).result,
      };
    case LOAD_REVISION_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };
    case RESET_LOADED_REVISIONS:
      return {
        ...state,
        loading: false,
        loaded: true,
        hasMore: true,
        revisions: [],
      };

    default:
      return state;
  }
}

export function isLoaded(globalState: any) {
  return globalState.meRevisions && globalState.meRevisions.loaded;
}

export function load(uid: any, revisionId: any, I18nStoryId: any) {
  return {
    types: [LOAD_REVISION, LOAD_REVISION_SUCCESS, LOAD_REVISION_FAIL],
    promise: ({ app }: any) =>
      app.service('me/stories/revisions').find({
        query: {
          uid,
          revisionId,
          I18nStoryId,
        },
      }),
  };
}

export function restoreTo(uid: any, revisionId: any, I18nStoryId: any) {
  return {
    types: [RESTORE, RESTORE_SUCCESS, RESTORE_FAIL],
    promise: ({ app }: any) =>
      app.service('me/stories/revisions').create({
        uid,
        restoreTo: revisionId,
        I18nStoryId,
      }),
  };
}

export function loadRevisions(I18nStoryId: any, lastRevisionId: any) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: any) => {
      const revisionIdQuery = lastRevisionId
        ? {
            revisionId: {
              $lt: lastRevisionId,
            },
          }
        : {};

      return app.service('me/stories/revisions').find({
        query: {
          I18nStoryId,
          ...revisionIdQuery,
          $sort: { revisionId: -1 },
          $limit: 50,
        },
      });
    },
  };
}

export function resetLoadedRevisions() {
  return {
    type: RESET_LOADED_REVISIONS,
  };
}
