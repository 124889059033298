import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import provideHooks from 'redial/lib/provideHooks';
import { changeTheme } from 'redux/modules/settings';
import getPageContext, { updatePageContext } from 'components/Context/createThemeContext';
import { isLoaded as isAuthLoaded, load as loadAuth } from 'redux/modules/auth';
// import { restoreToUnsaveState } from 'redux/modules/me/stories';
import qs from 'qs';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
// import { load as isOnline } from 'redux/modules/online';
import DocsStyledEngineProvider from 'utils/StyledEngineProvider';
import { useEffect, useState } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import useTheme from 'hooks/useTheme';
import useLocale from 'hooks/useLocale';
import { useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import AppWrapper from './AppWrapper';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// @connect(
//   (state) => ({
//     isOnline: state.online.isOnline,
//     isOnlineLoading: state.online.loading,
//     notifs: state.notifs,
//     uiTheme: state.settings.uiTheme,
//     lang: state.settings.lang,
//     user: state.auth.user,
//     topics: state.topics.result,
//     postPublic: state.stories && state.stories.post,
//     postPublicId: state.stories && state.stories.id,
//     post: state.meStories && state.meStories.post,
//     publications: state.publications && state.publications.publication,
//     loadingMeStories: state.meStories && state.meStories.loading,
//     saved: state.meStories && state.meStories.saved,
//   }),
//   {
//     logout,
//     notifDismiss: notifActions.notifDismiss,
//     changeTheme,
//     restoreToUnsaveState,
//     isOnlineAction: isOnline,
//   }
// )

const importSafe = (locale: string) => {
  switch (locale) {
    case 'es':
      return import('../../locales/es/common');
    // case 'pt':
    // return import('../../locales/pt/common');
    default:
      return import('../../locales/en/common');
  }

  // this does not work anymore becouse dynamic vars or somewhere exist an bug
  // const file = `./../../locales/${locale}/common`;
  // return import(file);
};

const cache = createIntlCache();

const createDefaultIntl = () => createIntl({ locale: 'en' }, createIntlCache());

const _createIntl = async (locale: any) =>
  locale === 'en'
    ? createDefaultIntl()
    : createIntl({
        locale,
        messages: await importSafe(locale).then((r) => ((r as any).__esModule ? r.default : r)),
      });

const App = (_props: any) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'localeContext' does not exist on type 'u... Remove this comment to see the full error message
  const { localeContext } = useLocale();
  const pageContext = useTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = createIntl(
    {
      locale: localeContext.lang,
      messages: localeContext.messages,
    },
    cache
  );

  const reduxProps = useSelector(
    (reduxState: any) => ({
      uiTheme: reduxState.settings.uiTheme,
      lang: reduxState.settings.lang,
      user: reduxState.auth.user,
      topics: reduxState.topics.result,
      postPublic: reduxState.stories && reduxState.stories.post,
      postPublicId: reduxState.stories && reduxState.stories.id,
      post: reduxState.meStories && reduxState.meStories.post,
      publications: reduxState.publications && reduxState.publications.publication,
      loadingMeStories: reduxState.meStories && reduxState.meStories.loading,
    }),
    shallowEqual
  );

  const { user, uiTheme } = reduxProps;

  const props = { ...reduxProps, ..._props };

  const paletteType =
    // eslint-disable-next-line no-nested-ternary
    uiTheme.paletteType === 'system' ? (prefersDarkMode ? 'dark' : 'light') : uiTheme.paletteType;

  const [state, setState] = useState<{ prevProps: any; user: any; pageContext: any; intl: any }>({
    prevProps: props,
    user,
    pageContext: pageContext || getPageContext({ ...uiTheme, paletteType }),
    intl,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ prevProps: any; user: any; pag... Remove this comment to see the full error message
    location,
  });

  const nextPage = location.pathname + location.search;
  const currentPage = (state as any).location.pathname + (state as any).location.search;

  useEffect(() => {
    if (!state.user && user) {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (query.redirect === '/') {
        delete query.redirect;
      }
      if (query.redirect) {
        const redirect = String(query.redirect)
          .replace('https://webmediums.com/', '/')
          .replace('https:/webmediums.com/amp/', '/');
        navigate(redirect);
      } else {
        navigate('/login-success');
      }

      setState((prev) => ({ ...prev, user }));
    } else if (state.user && !user) {
      // logout
      setState((prev) => ({ ...prev, user: null }));
    } else if (user) {
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (query.redirect && query.redirect !== '/' && nextPage !== currentPage) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        navigate(query.redirect);
      }
    }
  }, [user]);

  const _changeTheme = (theme = false) => {
    const _paletteType =
      // eslint-disable-next-line no-nested-ternary
      (theme || uiTheme.paletteType) === 'system'
        ? prefersDarkMode
          ? 'dark'
          : 'light'
        : theme || (uiTheme.paletteType === 'dark' ? 'light' : 'dark');

    const data = {
      ...uiTheme,
      // eslint-disable-next-line no-nested-ternary
    };
    if (theme) {
      data.paletteType = theme;
    }
    dispatch(changeTheme(data));
    setState((prev) => ({
      ...prev,
      pageContext: updatePageContext({ ...data, paletteType: _paletteType }),
    }));
  };

  useEffect(() => {
    if (uiTheme.paletteType === 'system') {
      _changeTheme();
    }
  }, [uiTheme.paletteType, paletteType]);

  const handleChangeLocale = async (locale: any) => {
    const _intl = await _createIntl(locale);
    setState((prev) => ({ ...prev, intl: _intl }));
  };

  const { pageContext: pageContextState } = state;
  const paramsToTheme =
    (process as any).browser || process.env.NODE_ENV !== 'production'
      ? {}
      : { sheetsCache: (pageContext as any).sheetsCache };
  return (
    <RawIntlProvider value={state.intl}>
      <StyledEngineProvider injectFirst>
        <StylesProvider
          generateClassName={(pageContext as any).generateClassName}
          jss={(pageContext as any).jss}
          // injectFirst
          {...paramsToTheme}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={pageContextState.theme}>
              <DocsStyledEngineProvider>
                <ErrorBoundary location={location} navigate={navigate}>
                  <AppWrapper
                    {...props}
                    pageContext={pageContext}
                    localeContext={localeContext}
                    location={location}
                    handleChangeLocale={handleChangeLocale}
                    changeTheme={_changeTheme}
                    lang={intl.locale}
                  />
                </ErrorBoundary>
              </DocsStyledEngineProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      </StyledEngineProvider>
    </RawIntlProvider>
  );
};

export default provideHooks({
  inject: async ({ app, client, restApp, store: { dispatch, getState } }: any) => {
    const promises = [];

    const state = getState();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    if (!isAuthLoaded(state, { app, client, restApp })) {
      promises.push(dispatch(loadAuth()).catch(() => null));
    }

    await Promise.all(promises);
  },
})(App);
