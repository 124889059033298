// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import loadable from '@loadable/component';
import meUsersReducer, { loadUser } from 'redux/modules/me/users';

const Edit = loadable(() => import(/* webpackChunkName: 'a-users-edit' */ './Edit'));

const EditContainer = (props: any) => {
  return <Edit {...props} />;
};

export default provideHooks({
  fetch: async ({ params, store: { dispatch, inject, asyncReducers } }: any) => {
    if (!asyncReducers.meUsers) {
      inject({ meUsers: meUsersReducer });
    }

    const promises = [];

    if (params.uid) {
      promises.push(dispatch(loadUser(params.uid)).catch(() => null));
    }

    await Promise.all(promises);
  },
  defer: async ({ store: { inject, asyncReducers } }: any) => {
    if (!asyncReducers.meTopics) {
      inject({ meUsers: meUsersReducer });
    }
  },
})(EditContainer);
