import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { isLoaded as isStoriesLoaded, load as loadStories } from 'redux/modules/stories';
import { findRelated } from 'redux/modules/tags';

const Tag = loadable(() => import(/* webpackChunkName: 'tag' */ './Tag'));

const TagComponent = (props: any) => {
  return <Tag {...props} />;
};

export default provideHooks({
  fetch: async ({ params, store: { dispatch, getState } }: any) => {
    const state = getState();
    const promises = [];

    const { tag } = params;
    const paramsSearch = {
      status: 'public',
      $skip: 0,
      $limit: 10,
      $paginate: false,
      tag,
      findPostRelated: true,
    };
    const key = JSON.stringify(paramsSearch);
    if (!isStoriesLoaded(state, key)) {
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
      if (!__CLIENT__) {
        promises.push(dispatch(loadStories(paramsSearch, key)).catch(() => null));
      } else {
        dispatch(loadStories(paramsSearch, key)).catch(() => null);
      }
    }

    // if (!isStoriesLoaded(state, key)) {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
    if (!__CLIENT__) {
      promises.push(dispatch(findRelated(tag)).catch(() => null));
    } else {
      dispatch(findRelated(tag)).catch(() => null);
    }
    // }

    await Promise.all(promises);
  },
})(TagComponent);
