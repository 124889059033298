import isOnline from '../../utils/isOnline';

const LOAD = 'redux-webmediums/online/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/online/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/online/LOAD_FAIL';
const SET_SOCKET_STATUS = 'redux-webmediums/online/SET_SOCKET_STATUS';

const initialState = {
  isOnline: true,
  loading: false,
  error: false,
  socketStatus: 'open',
};

export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        isOnline: action.result,
      };
    }
    case LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case SET_SOCKET_STATUS: {
      const extraParams = action.status === 'open' ? { isOnline: true } : {};
      return {
        ...state,
        ...extraParams,
        socketStatus: action.status,
      };
    }
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: () => {
      const promise = new Promise((resolve) => {
        setTimeout(async () => {
          resolve(await isOnline());
        }, 500);
      });
      return promise;
    },
  };
}

export function setSocketStatus(status: 'open' | 'close') {
  return {
    type: SET_SOCKET_STATUS,
    status,
  };
}
