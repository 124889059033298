import React from 'react';
import AppContext from 'contexts/App';
import type { Application } from '@feathersjs/feathers';

const { useContext } = React;

interface ApplicationExtended extends Application {
  deviceInformation: {
    isMobile: boolean | any;
    amazon: boolean | any;
    android: boolean | any;
    apple: boolean | any;
    other: boolean | any;
    phone: boolean | any;
    sevenInch: boolean | any;
    tablet: boolean | any;
    windows: boolean | any;
  };
}

const useApp = () => useContext(AppContext) as ApplicationExtended;

export default useApp;
