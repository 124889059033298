import { Component } from 'react';
import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import {
  isLoaded as isStoriesLoaded,
  load as loadStories,
  loadPopularStories,
  isLoadePopularStories,
} from 'redux/modules/stories';

const Topic = loadable(() => import(/* webpackChunkName: 'topic' */ './Topic'));

const paramsSearchFeatured: any = {
  status: 'public',
  isFeatured: 1,
  $skip: 0,
  $limit: 10,
  $paginate: false,
  $exclude: ['content'],
  $sort: {
    publishDate: -1,
  },
};

const paramsSearchTopic: any = {
  status: 'public',
  $skip: 0,
  $limit: 10,
  $paginate: false,
  $exclude: ['content'],
  $sort: {
    publishDate: -1,
  },
};

@provideHooks({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'params' implicitly has an 'any' t... Remove this comment to see the full error message
  fetch: async ({ params, store: { dispatch, getState }, location }) => {
    const state = getState();
    const promises = [];
    const { topic } = params;
    const languages = {
      tema: 'es',
      topic: 'en',
    };

    const topicPath = location.pathname.split('/')[1];
    const editorPicksPathsLang: any = {
      'editors-picks': 'en',
      'seleccion-editores': 'es',
    };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const locale = languages[topicPath];
    if (editorPicksPathsLang[topic]) {
      paramsSearchFeatured.locale = locale;
      if (!isStoriesLoaded(state, 'featuredTopic')) {
        paramsSearchFeatured.$skip = 0;
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
        if (!__CLIENT__) {
          promises.push(
            dispatch(loadStories(paramsSearchFeatured, 'featuredTopic')).catch(() => null)
          );
        } else {
          dispatch(loadStories(paramsSearchFeatured, 'featuredTopic')).catch(() => null);
        }
      }
    } else if (!isStoriesLoaded(state, topic)) {
      (paramsSearchTopic as any).topic = topic;
      (paramsSearchTopic as any).topicLocale = locale;
      paramsSearchTopic.$skip = 0;
      // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__CLIENT__'.
      if (!__CLIENT__) {
        promises.push(dispatch(loadStories(paramsSearchTopic, topic)).catch(() => null));
      } else {
        dispatch(loadStories(paramsSearchTopic, topic)).catch(() => null);
      }
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }
  },
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
  defer: async ({ store: { dispatch, getState } }) => {
    const state = getState();
    if (!isLoadePopularStories(state)) {
      dispatch(loadPopularStories()).catch(() => null);
    }
  },
})
export default class TopicContainer extends Component {
  render() {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'topicPath' is missing in type '{ childre... Remove this comment to see the full error message
    return <Topic {...this.props} />;
  }
}
