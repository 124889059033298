/**
 * @link https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Snackbar/Snackbar.js
 */
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { REASONS } from '../utils/constants';
import useEventCallback from '../utils/useEventCallback';

const Snackbar = forwardRef((props, ref) => {
  const {
    children,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'autoHideDuration' does not exist on type... Remove this comment to see the full error message
    autoHideDuration,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ClickAwayListenerProps' does not exist o... Remove this comment to see the full error message
    ClickAwayListenerProps,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableWindowBlurListener' does not exis... Remove this comment to see the full error message
    disableWindowBlurListener = false,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type '{ child... Remove this comment to see the full error message
    onClose,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onMouseEnter' does not exist on type '{ ... Remove this comment to see the full error message
    onMouseEnter,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onMouseLeave' does not exist on type '{ ... Remove this comment to see the full error message
    onMouseLeave,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'open' does not exist on type '{ children... Remove this comment to see the full error message
    open,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'resumeHideDuration' does not exist on ty... Remove this comment to see the full error message
    resumeHideDuration,
    ...other
  } = props;

  const timerAutoHide = useRef();

  const handleClose = useEventCallback((...args: any[]) => {
    if (onClose) {
      onClose(...args);
    }
  });

  const setAutoHideTimer = useEventCallback((autoHideDurationParam: any) => {
    if (!onClose || autoHideDurationParam == null) {
      return;
    }

    clearTimeout(timerAutoHide.current);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
    timerAutoHide.current = setTimeout(() => {
      handleClose(null, REASONS.TIMEOUT);
    }, autoHideDurationParam);
  });

  useEffect(() => {
    if (open) {
      setAutoHideTimer(autoHideDuration);
    }

    return () => {
      clearTimeout(timerAutoHide.current);
    };
  }, [open, autoHideDuration, setAutoHideTimer]);

  /**
   * Pause the timer when the user is interacting with the Snackbar
   * or when the user hide the window.
   */
  const handlePause = () => {
    clearTimeout(timerAutoHide.current);
  };

  /**
   * Restart the timer when the user is no longer interacting with the Snackbar
   * or when the window is shown back.
   */
  const handleResume = useCallback(() => {
    if (autoHideDuration != null) {
      setAutoHideTimer(resumeHideDuration != null ? resumeHideDuration : autoHideDuration * 0.5);
    }
  }, [autoHideDuration, resumeHideDuration, setAutoHideTimer]);

  const handleMouseEnter = (event: any) => {
    if (onMouseEnter) {
      onMouseEnter(event);
    }
    handlePause();
  };

  const handleMouseLeave = (event: any) => {
    if (onMouseLeave) {
      onMouseLeave(event);
    }
    handleResume();
  };

  const handleClickAway = (event: any) => {
    if (onClose) {
      onClose(event, REASONS.CLICKAWAY);
    }
  };

  useEffect(() => {
    if (!disableWindowBlurListener && open) {
      window.addEventListener('focus', handleResume);
      window.addEventListener('blur', handlePause);

      return () => {
        window.removeEventListener('focus', handleResume);
        window.removeEventListener('blur', handlePause);
      };
    }

    return undefined;
  }, [disableWindowBlurListener, handleResume, open]);

  return (
    <ClickAwayListener onClickAway={handleClickAway} {...ClickAwayListenerProps}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message */}
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={ref} {...other}>
        {children}
      </div>
    </ClickAwayListener>
  );
});

export default Snackbar;
