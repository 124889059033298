import differenceInSeconds from 'date-fns/differenceInSeconds';
import { FORM_ERROR } from 'final-form';

const LOAD = 'redux-webmediums/me/topics/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/me/topics/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/me/topics/LOAD_FAIL';

const LOAD_TOPIC = 'redux-webmediums/me/topics/LOAD_TOPIC';
const LOAD_TOPIC_SUCCESS = 'redux-webmediums/me/topics/LOAD_TOPIC_SUCCESS';
const LOAD_TOPIC_FAIL = 'redux-webmediums/me/topics/LOAD_TOPIC_FAIL';

const CREATE_TOPIC = 'redux-webmediums/topics/CREATE_TOPIC';
const CREATE_TOPIC_SUCCESS = 'redux-webmediums/topics/CREATE_TOPIC_SUCCESS';
const CREATE_TOPIC_FAIL = 'redux-webmediums/topics/CREATE_TOPIC_FAIL';

const UPDATE_TOPIC = 'redux-webmediums/topics/UPDATE_TOPIC';
const UPDATE_TOPIC_SUCCESS = 'redux-webmediums/topics/UPDATE_TOPIC_SUCCESS';
const UPDATE_TOPIC_FAIL = 'redux-webmediums/topics/UPDATE_TOPIC_FAIL';

const catchValidation = (error: any) => {
  if (error.message) {
    if (error.message === 'Validation failed' && error.data) {
      return Promise.reject(error.data);
    }
    const err = {
      [FORM_ERROR]: error.message,
    };
    return Promise.reject(err);
  }
  return Promise.reject(error);
};

const initialState = {
  loaded: false,
  saved: true,
  loadedTopic: false,
  topics: {
    loading: true,
    data: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  // let topicsById;
  switch ((action as any).type) {
    case LOAD:
      (state as any).loading = true;
      return {
        ...state,
      };
    case LOAD_SUCCESS: {
      if ((action as any).result.skip === 0) {
        state.topics.data = [];
      }
      const copy = {
        loading: false,
        loaded: true,
        lastTimeTopicsLoaded:
          (action as any).result.skip === 0 ? new Date() : (state as any).lastTimeTopicsLoaded,
        ...(action as any).result,
      };
      copy.data = state.topics.data.concat(copy.data);

      return {
        ...state,
        topics: { ...copy },
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        topics: {
          loading: false,
          loaded: false,
          data: [],
        },
        error: (action as any).error,
      };

    case LOAD_TOPIC:
      return {
        ...state,
        loading: true,
      };
    case LOAD_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        topic: (action as any).result,
      };
    case LOAD_TOPIC_FAIL:
      return {
        ...state,
        loading: false,
        loadedTopic: false,
        error: (action as any).error,
      };

    case CREATE_TOPIC:
      return {
        ...state,
        loading: true,
      };
    case CREATE_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        myLoaded: false,
        followingLoaded: false,
        publication: { ...(state as any).publication, [(action as any).result.slug]: null },
        publicationLoaded: {
          ...(state as any).publicationLoaded,
          [(action as any).result.slug]: false,
        },
      };
    case CREATE_TOPIC_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    case UPDATE_TOPIC:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        myLoaded: false,
        followingLoaded: false,
        publication: { ...(state as any).publication, [(action as any).result.slug]: null },
        publicationLoaded: {
          ...(state as any).publicationLoaded,
          [(action as any).result.slug]: false,
          [`${(action as any).result.slug}-public`]: false,
          [`${(action as any).result.slug}-settings`]: false,
          [`${(action as any).result.slug}-about`]: false,
        },
      };
    case UPDATE_TOPIC_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (action as any).error,
      };

    default:
      return state;
  }
}

export function isLoaded(globalState: any) {
  return (
    globalState.meTopics &&
    globalState.meTopics.topics &&
    globalState.meTopics.topics.loaded &&
    globalState.meTopics.topics.lastTimeTopicsLoaded &&
    differenceInSeconds(new Date(), globalState.meTopics.topics.lastTimeTopicsLoaded) < 5
  );
}

export function load($skip = 0, extraParams = {}) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: any) =>
      app.service('me/topics/i18n').find({
        query: {
          $sort: { createdAt: -1 },
          $limit: 10,
          $skip,
          ...extraParams,
        },
      }),
  };
}

export function isLoadeTopic(globalState: any, topicId: any) {
  return (
    globalState.meTopics &&
    globalState.meTopics.loadedTopic &&
    globalState.meTopics.loadedTopic[topicId]
  );
}

export function loadTopic(topicId: any, extreaParams = {}) {
  return {
    types: [LOAD_TOPIC, LOAD_TOPIC_SUCCESS, LOAD_TOPIC_FAIL],
    promise: ({ app }: any) =>
      app
        .service('me/topics/i18n')
        .find({
          query: {
            uid: topicId,
            $sort: { createdAt: -1 },
            $limit: 1,
            ...extreaParams,
          },
        })
        .then((data: any) => data.data[0]),
  };
}

export function create(data: any) {
  return {
    types: [CREATE_TOPIC, CREATE_TOPIC_SUCCESS, CREATE_TOPIC_FAIL],
    promise: ({ app }: any) => app.service('me/topics/i18n').create(data).catch(catchValidation),
  };
}

export function update(data: any) {
  return {
    types: [UPDATE_TOPIC, UPDATE_TOPIC_SUCCESS, UPDATE_TOPIC_FAIL],
    promise: ({ app }: any) =>
      app.service('me/topics/i18n').patch(data.uid, data).catch(catchValidation),
  };
}
