const NOTIF_SEND = 'redux-mytradewallet/notifs/NOTIF_SEND';
const NOTIF_DISMISS = 'redux-mytradewallet/notifs/NOTIF_DISMISS';
const NOTIF_CLEAR = 'redux-mytradewallet/notifs/NOTIF_CLEAR';
const NOTIF_CLEAR_ALL = 'redux-mytradewallet/notifs/NOTIF_CLEAR_ALL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case NOTIF_SEND:
      return {
        ...state,
        [(action as any).namespace]: [
          (action as any).payload,
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...(state[(action as any).namespace] || []),
        ],
      };
    case NOTIF_DISMISS:
      return {
        ...state,
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [(action as any).namespace]: (state[(action as any).namespace] || []).filter(
          (notif: any) => notif.id !== (action as any).payload
        ),
      };
    case NOTIF_CLEAR:
      return { ...state, [(action as any).namespace]: [] };
    case NOTIF_CLEAR_ALL:
      return {};
    default:
      return state;
  }
}

export function notifSend(notif: any, namespace = 'global'): any {
  if (!notif.id) {
    notif.id = new Date().getTime() * Math.random();
  }
  return (dispatch: any) => {
    dispatch({ type: NOTIF_SEND, namespace, payload: notif });

    if (notif.dismissAfter) {
      setTimeout(
        () => dispatch({ type: NOTIF_DISMISS, namespace, payload: notif.id }),
        notif.dismissAfter
      );
    }
  };
}

export function notifDismiss(id: any, namespace = 'global') {
  return { type: NOTIF_DISMISS, namespace, payload: id };
}

export function notifClear(namespace = 'global') {
  return { type: NOTIF_CLEAR, namespace };
}

export function notifClearAll() {
  return { type: NOTIF_CLEAR_ALL };
}
