/* eslint-disable @typescript-eslint/ban-ts-comment */
import { memo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { SNACKBAR_INDENTS } from './utils/constants';
// @ts-expect-error ts-migrate(2305) FIXME: Module '"."' has no exported member 'SnackbarProvi... Remove this comment to see the full error message
import type { SnackbarProviderProps } from '.';

const useStyle = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'fixed',
    flexDirection: 'column',
    zIndex: theme.zIndex.snackbar,
    height: 'auto',
    width: 'auto',
    minWidth: 288,
    transition: theme.transitions.create(['top', 'right', 'bottom', 'left'], { easing: 'ease' }),
    [theme.breakpoints.down('md')]: {
      left: '0 !important',
      right: '0 !important',
      width: '100%',
    },
  },
  reverseColumns: { flexDirection: 'column-reverse' },

  top: { top: SNACKBAR_INDENTS.view.default - SNACKBAR_INDENTS.snackbar.default },
  topDense: { top: SNACKBAR_INDENTS.view.dense - SNACKBAR_INDENTS.snackbar.dense },

  bottom: { bottom: SNACKBAR_INDENTS.view.default - SNACKBAR_INDENTS.snackbar.default },
  bottomDense: { bottom: SNACKBAR_INDENTS.view.dense - SNACKBAR_INDENTS.snackbar.dense },

  left: { left: SNACKBAR_INDENTS.view.default },
  leftDense: { left: SNACKBAR_INDENTS.view.dense },

  right: { right: SNACKBAR_INDENTS.view.default },
  rightDense: { right: SNACKBAR_INDENTS.view.dense },

  center: {
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(0)',
    },
  },
}));

interface SnackbarContainerProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  dense: SnackbarProviderProps['dense'];
  anchorOrigin: NonNullable<SnackbarProviderProps['anchorOrigin']>;
}

const SnackbarContainer: React.FC<SnackbarContainerProps> = (props) => {
  const classes = useStyle();
  const { className, anchorOrigin, dense, ...other } = props;

  const combinedClassname = clsx(
    classes.root,
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    classes[anchorOrigin.vertical],
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    classes[anchorOrigin.horizontal],
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    classes[`${anchorOrigin.vertical}${dense ? 'Dense' : ''}`],
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    classes[`${anchorOrigin.horizontal}${dense ? 'Dense' : ''}`],
    { [classes.reverseColumns]: anchorOrigin.vertical === 'bottom' },
    className
  );

  return <div className={combinedClassname} {...other} />;
};

export default memo(SnackbarContainer);
