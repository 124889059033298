import loadable from '@loadable/component';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import { getPublication, isLoadedPublication } from 'redux/modules/publications';

const PublicationSettings = loadable(
  () => import(/* webpackChunkName: 'publication-settings' */ './Settings')
);

const PublicationSettingsContainer = (props: any) => {
  return <PublicationSettings {...props} />;
};

export default provideHooks({
  fetch: async ({ params, store: { dispatch, getState } }: any) => {
    const promises = [];

    if (params.publication && !isLoadedPublication(getState(), `${params.publication}-settings`)) {
      promises.push(
        dispatch(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          getPublication(params.publication, 0, 0, `${params.publication}-settings`, {
            includeWriters: true,
            includeEditors: true,
            includeSections: true,
          })
        ).catch(() => null)
      );
    }

    await Promise.all(promises);
  },
})(PublicationSettingsContainer);
