import { connect } from 'react-redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'inva... Remove this comment to see the full error message
import invariant from 'invariant';

import authWrapper from '../authWrapper';
import Redirect from '../redirect';

const connectedDefaults = {
  authenticatingSelector: () => false,
  allowRedirectBack: true,
  FailureComponent: Redirect,
  redirectQueryParamName: 'redirect',
};

export default ({ locationHelperBuilder }: any) => {
  const connectedRouterRedirect = (args: any) => {
    const allArgs = { ...connectedDefaults, ...args };
    const {
      FailureComponent,
      redirectPath,
      authenticatedSelector,
      authenticatingSelector,
      allowRedirectBack,
      redirectQueryParamName,
    } = allArgs;

    const { createRedirectLoc } = locationHelperBuilder({
      redirectQueryParamName,
    });

    let redirectPathSelector: any;
    if (typeof redirectPath === 'string') {
      redirectPathSelector = () => redirectPath;
    } else if (typeof redirectPath === 'function') {
      redirectPathSelector = redirectPath;
    } else {
      invariant(false, 'redirectPath must be either a string or a function');
    }

    let allowRedirectBackFn: any;
    if (typeof allowRedirectBack === 'boolean') {
      allowRedirectBackFn = () => allowRedirectBack;
    } else if (typeof allowRedirectBack === 'function') {
      allowRedirectBackFn = allowRedirectBack;
    } else {
      invariant(false, 'allowRedirectBack must be either a boolean or a function');
    }

    const redirect =
      () =>
      ({ navigate, ...props }: any, path: any) =>
        navigate(createRedirectLoc(allowRedirectBackFn(props, path))(props, path));

    const getRedirectLocation =
      () =>
      ({ ...props }, path: any) =>
        createRedirectLoc(allowRedirectBackFn(props, path))(props, path);

    const ConnectedFailureComponent = connect(() => ({
      redirect: redirect(),
      getRedirectLocation: getRedirectLocation(),
    }))(FailureComponent);

    return (DecoratedComponent: any) =>
      connect((state, ownProps) => ({
        redirectPath: redirectPathSelector(state, ownProps),
        isAuthenticated: authenticatedSelector(state, ownProps),
        isAuthenticating: authenticatingSelector(state, ownProps),
      }))(
        authWrapper({
          ...allArgs,
          FailureComponent: ConnectedFailureComponent,
        })(DecoratedComponent)
      );
  };

  const connectedReduxRedirect = (args: any) => {
    const allArgs = { ...connectedDefaults, ...args };
    const {
      FailureComponent,
      redirectPath,
      authenticatedSelector,
      authenticatingSelector,
      allowRedirectBack,
      redirectAction,
      redirectQueryParamName,
    } = allArgs;

    const { createRedirectLoc } = locationHelperBuilder({
      redirectQueryParamName,
    });

    let redirectPathSelector: any;
    if (typeof redirectPath === 'string') {
      redirectPathSelector = () => redirectPath;
    } else if (typeof redirectPath === 'function') {
      redirectPathSelector = redirectPath;
    } else {
      invariant(false, 'redirectPath must be either a string or a function');
    }

    let allowRedirectBackFn: any;
    if (typeof allowRedirectBack === 'boolean') {
      allowRedirectBackFn = () => allowRedirectBack;
    } else if (typeof allowRedirectBack === 'function') {
      allowRedirectBackFn = allowRedirectBack;
    } else {
      invariant(false, 'allowRedirectBack must be either a boolean or a function');
    }

    const createRedirect = (dispatch: any) => ({
      redirect: (props: any, path: any) =>
        dispatch(redirectAction(createRedirectLoc(allowRedirectBackFn(props, path))(props, path))),
    });

    const ConnectedFailureComponent = connect(null, createRedirect)(FailureComponent);

    return (DecoratedComponent: any) =>
      connect((state, ownProps) => ({
        redirectPath: redirectPathSelector(state, ownProps),
        isAuthenticated: authenticatedSelector(state, ownProps),
        isAuthenticating: authenticatingSelector(state, ownProps),
      }))(
        authWrapper({ ...allArgs, FailureComponent: ConnectedFailureComponent })(DecoratedComponent)
      );
  };

  return {
    connectedRouterRedirect,
    connectedReduxRedirect,
  };
};
