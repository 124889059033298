import clsx from 'clsx';
import { SnackbarItemProps } from './SnackbarItem';
import { Snack } from '../SnackbarProvider';
// @ts-expect-error ts-migrate(2305) FIXME: Module '".."' has no exported member 'SnackbarProv... Remove this comment to see the full error message
import type { SnackbarProviderProps } from '..';

const DIRECTION = {
  right: 'left',
  left: 'right',
  bottom: 'up',
  top: 'down',
} as const;
export type DirectionType = typeof DIRECTION[keyof typeof DIRECTION];

// @ts-expect-error ts-migrate(2339) FIXME: Property 'anchorOrigin' does not exist on type 'Sn... Remove this comment to see the full error message
export const getTransitionDirection = (anchorOrigin: Snack['anchorOrigin']): DirectionType => {
  if (anchorOrigin.horizontal !== 'center') {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return DIRECTION[anchorOrigin.horizontal];
  }
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return DIRECTION[anchorOrigin.vertical];
};

/**
 * Omit all class keys except what we need for collapse component
 */
export const omitNonCollapseKeys = (
  classes: SnackbarItemProps['classes'],
  dense: SnackbarProviderProps['dense']
): { root: string; wrapper: string; wrapperInner: string } => ({
  root: classes.collapseContainer,
  wrapper: clsx(classes.collapseWrapper, { [classes.collapseWrapperDense]: dense }),
  wrapperInner: classes.collapseWrapperInner,
});
