import type { Application } from '@feathersjs/feathers';

export interface Params {
  query: {
    [x: string]: {
      $in?: string[] | number[] | null;
      $nin?: string[] | number[] | null;
      $lt?: any;
      $lte?: any;
      $gt?: any;
      $gte?: any;
      $ne?: any;
      $or?: any[];
      $like?: string;
    } & any;
    $limit?: number;
    $skip?: number;
    $sort?: any;
    $paginate?: boolean | number;
    $select?: string[];
  };
}

const defaultReduxActions = ({ namespace }: { namespace: string }) => {
  const LOAD = `redux-webmediums/${namespace}/LOAD`;
  const LOAD_SUCCESS = `redux-webmediums/${namespace}/LOAD_SUCCESS`;
  const LOAD_FAIL = `redux-webmediums/${namespace}/LOAD_FAIL`;

  const LOAD_GET = `redux-webmediums/${namespace}/LOAD_GET`;
  const LOAD_GET_SUCCESS = `redux-webmediums/${namespace}/LOAD_GET_SUCCESS`;
  const LOAD_GET_FAIL = `redux-webmediums/${namespace}/LOAD_GET_FAIL`;

  const CREATE = `redux-webmediums/${namespace}/CREATE`;
  const CREATE_SUCCESS = `redux-webmediums/${namespace}/CREATE_SUCCESS`;
  const CREATE_FAIL = `redux-webmediums/${namespace}/CREATE_FAIL`;

  const UPDATE = `redux-webmediums/${namespace}/UPDATE`;
  const UPDATE_SUCCESS = `redux-webmediums/${namespace}/UPDATE_SUCCESS`;
  const UPDATE_FAIL = `redux-webmediums/${namespace}/UPDATE_FAIL`;

  const PATCH = `redux-webmediums/${namespace}/PATCH`;
  const PATCH_SUCCESS = `redux-webmediums/${namespace}/PATCH_SUCCESS`;
  const PATCH_FAIL = `redux-webmediums/${namespace}/PATCH_FAIL`;

  const REMOVE = `redux-webmediums/${namespace}/REMOVE`;
  const REMOVE_SUCCESS = `redux-webmediums/${namespace}/REMOVE_SUCCESS`;
  const REMOVE_FAIL = `redux-webmediums/${namespace}/REMOVE_FAIL`;

  const initialState = {
    loaded: false,
    loaders: {},
    loadings: {},
    loadeds: {},

    result: {},
    createResult: {},
    patchResult: {},
    getResult: {},
    removeResult: {},
  };

  const reducer = (state = initialState, action: any = {}) => {
    switch (action.type) {
      case LOAD:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case LOAD_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          result: { ...state.result, [action.key]: action.result },
        };
      case LOAD_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };
      case LOAD_GET:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case LOAD_GET_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          getResult: { ...state.result, [action.key]: action.result },
        };
      case LOAD_GET_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };

      case CREATE:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case CREATE_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          createResult: { ...state.result, [action.key]: action.result },
        };
      case CREATE_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };
      case PATCH:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case PATCH_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          patchResult: { ...state.result, [action.key]: action.result },
        };
      case PATCH_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };

      case REMOVE:
        return {
          ...state,
          loading: true,
          loadings: {
            ...state.loadings,
            [action.key]: true,
          },
        };
      case REMOVE_SUCCESS:
        return {
          ...state,
          loading: false,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loaded: true,
          loadeds: {
            ...state.loadeds,
            [action.key]: true,
          },
          removeResult: { ...state.result, [action.key]: action.result },
        };
      case REMOVE_FAIL:
        return {
          ...state,
          loadings: {
            ...state.loadings,
            [action.key]: false,
          },
          loading: false,
          loaded: false,
          loadeds: {
            ...state.loadeds,
            [action.key]: false,
          },
          error: action.error,
        };
      default:
        return state;
    }
  };

  const find = (key: string, params: Params, { timeout }: { timeout?: number } = {}) => ({
    key,
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ app }: { app: Application }) => {
      const service = app.service(`${namespace}`);
      if (timeout) {
        // @ts-expect-error hide this
        const { timeout: oldTimeout } = service;
        // @ts-expect-error hide this
        service.timeout = timeout;
        return service.find(params).then((r) => {
          // @ts-expect-error hide this
          service.timeout = oldTimeout;
          return r;
        });
      }
      return service.find(params);
    },
  });

  const get = (key: string, id: number) => ({
    key,
    types: [LOAD_GET, LOAD_GET_SUCCESS, LOAD_GET_FAIL],
    promise: ({ app }: { app: Application }) => app.service(`${namespace}`).get(id),
  });

  const create = (key: string, data: any, { timeout }: { timeout?: number } = {}) => ({
    key,
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    promise: ({ app }: { app: Application }) => {
      const service = app.service(`${namespace}`);
      if (timeout) {
        // @ts-expect-error hide this
        const { timeout: oldTimeout } = service;
        // @ts-expect-error hide this
        service.timeout = timeout;
        return service.create(data).then((r) => {
          // @ts-expect-error hide this
          service.timeout = oldTimeout;
          return r;
        });
      }
      return service.create(data);
    },
  });

  const update = (key: string, id: number, data: any, { timeout }: { timeout?: number } = {}) => ({
    key,
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: ({ app }: { app: Application }) => {
      const service = app.service(`${namespace}`);
      if (timeout) {
        // @ts-expect-error hide this
        const { timeout: oldTimeout } = service;
        // @ts-expect-error hide this
        service.timeout = timeout;
        return service.update(id, data).then((r) => {
          // @ts-expect-error hide this
          service.timeout = oldTimeout;
          return r;
        });
      }
      return service.update(id, data);
    },
  });
  const patch = (key: string, id: number, data: any, { timeout }: { timeout?: number } = {}) => ({
    key,
    types: [PATCH, PATCH_SUCCESS, PATCH_FAIL],
    promise: ({ app }: { app: Application }) => {
      const service = app.service(`${namespace}`);
      if (timeout) {
        // @ts-expect-error hide this
        const { timeout: oldTimeout } = service;
        // @ts-expect-error hide this
        service.timeout = timeout;
        return service.patch(id, data).then((r) => {
          // @ts-expect-error hide this
          service.timeout = oldTimeout;
          return r;
        });
      }
      return service.patch(id, data);
    },
  });

  const remove = (key: string, id: number, params: Params) => ({
    key,
    types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
    promise: ({ app }: { app: Application }) => app.service(`${namespace}`).remove(id, params),
  });

  return {
    reducer,
    find,
    get,
    create,
    update,
    patch,
    remove,
  };
};

export default defaultReduxActions;
