import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AvatarIcon from '@mui/icons-material/AccountCircleOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { getContrastRatio } from '@mui/system/colorManipulator';
import classNames from 'clsx';
import Container from 'components/Container/Container';
import LogoSymbol from 'components/Icons/LogoWM';
import Menu from 'components/Menu/Menu';
import AppSearch from 'components/Search/Search';
import capitalize from 'lodash.capitalize';
import { pathToRegexp } from 'path-to-regexp';
import { cloneElement, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import getImageLink from 'utils/getImageLink';
import userCan from 'utils/userCan';
import { useIntl } from 'react-intl';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/modules/auth';
import { load as isOnlineAction, setSocketStatus } from 'redux/modules/online';
import { socket } from 'app';
import readingTime from 'utils/reading-time';
import HeaderUser from './HeaderUserLoadable';

let intervalIsOnline: any = null;

interface Props {
  children: React.ReactElement;
  disableHidden: boolean;
  demoMode: boolean;
  classes: { [x: string]: string };
  className: string;
}

const scrollTrigger = (store: any, options: any) => {
  const { disableHysteresis = false, threshold = 100, target } = options;
  const previous = store.current;

  if (target) {
    // Get vertical scroll
    store.current = target.pageYOffset !== undefined ? target.pageYOffset : target.scrollTop;
  }

  if (!disableHysteresis && previous !== undefined) {
    if (store.current < previous) {
      return false;
    }
  }

  return store.current > threshold;
};
const defaultTarget = typeof window !== 'undefined' ? window : null;
const HideOnScroll = (props: Props) => {
  const { children, disableHidden, demoMode, classes, className } = props;
  const store = useRef();
  const isMounted = useRef<boolean>();
  const [triggerUpDown, setTriggerUpDown] = useState(() =>
    scrollTrigger(store, {
      target: defaultTarget,
    })
  );
  const [triggerScroll, setTriggerScroll] = useState(() =>
    scrollTrigger(store, {
      disableHysteresis: true,
      threshold: 0,
      target: defaultTarget,
    })
  );

  useEffect(() => {
    isMounted.current = true;
    const handleScroll = async () => {
      setTriggerUpDown(
        scrollTrigger(store, {
          target: defaultTarget,
        })
      );
      setTriggerScroll(
        scrollTrigger(store, {
          disableHysteresis: true,
          threshold: 0,
          target: defaultTarget,
        })
      );
    };

    handleScroll(); // Re-evaluate trigger when dependencies change

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    defaultTarget.addEventListener('scroll', handleScroll);
    return () => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      defaultTarget.removeEventListener('scroll', handleScroll);
    }; // See Option 3. https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isIn = !isMounted.current || disableHidden || demoMode || !triggerUpDown;

  const childrenCloned = cloneElement(children, {
    className:
      isMounted.current && triggerScroll ? classNames(className, classes.headerBorder) : className,
  });

  return (
    <Slide appear={false} direction="down" in={Boolean(isIn)}>
      {childrenCloned}
    </Slide>
  );
};

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
    },
    grow: {
      flex: '1 1 auto',
    },
    logoHeader: {
      width: 45,
      marginTop: 5,
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 5,
      fill: '#ffffff',
      backgroundColor: '#000000',
    },
    headerRoot: {
      // background: '#fff',
      background: 'rgba(255,255,255,.97)',
      border: 0,
      boxShadow: 'none',
    },
    headerWrap: {
      color: 'rgba(0,0,0,.54)',
      fontSize: 16,
      fontFamily: [
        'wm-content-sans-serif-font',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        '"Open Sans"',
        '"Helvetica Neue"',
        'sans-serif',
      ].join(','),
      '-webkit-transition': '-webkit-transform .3s',
      transition: 'transform .3s',
      fallbacks: [
        { transition: '-webkit-transform .3s' },
        { transition: 'transform .3s, -webkit-transform .3s' },
      ],
      '-webkit-transform': 'translateY(0%)',
      transform: 'translateY(0%)',
    },
    headerBorder: {
      '-webkit-box-shadow': '0 2px 2px -2px rgba(0,0,0,.15)',
      boxShadow: '0 2px 2px -2px rgba(0,0,0,.15)',
    },
    toolbar: {
      padding: 0,
      width: '100%',
    },
    toolbarMobile: {
      height: 30,
      minHeight: 30,
    },
    navUp: {
      '-webkit-transform': 'translateY(-100%)',
      transform: 'translateY(-100%)',
    },
    navDown: {},
    colorAvatar: {
      width: 32,
      height: 32,
      backgroundColor: '#000000',
    },
    imageAvatarPublication: {
      width: 32,
      height: 32,
    },
    popover: {
      width: 220,
      marginTop: 10,
      overflowY: 'visible',
      overflowX: 'visible',
    },
    popoverArrow: {
      position: 'absolute',
      left: '50%',
      marginLeft: -7,
      top: -14,
      clip: 'rect(0 18px 14px -4px)',

      '&:after': {
        content: '""',
        display: 'block',
        width: 14,
        height: 14,
        background: '#fff',

        '-webkit-transform-style': 'rotate(45deg) translate(6px,6px)',
        transform: 'rotate(45deg) translate(6px,6px)',
        '-webkit-box-shadow': '-1px -1px 1px -1px rgba(0,0,0,.54)',
        boxShadow: '-1px -1px 1px -1px rgba(0,0,0,.54)',
      },
    },
    // blur: {
    //   '& .appContent, $headerWrap, footer': {
    //     pointerEvents: 'none',
    //     userSelect: 'none',
    //     overflow: 'hidden',
    //     opacity: 0.7,
    //     '-webkit-filter': 'blur(0.5px)',
    //     filter: 'blur(0.5px)',
    //     fallbacks: [
    //       {
    //         filter: 'progid:DXImageTransform.Microsoft.Blur(PixelRadius=1, MakeShadow=false)'
    //       }
    //     ]
    //   }
    // },
    statusPost: {
      marginLeft: 20,
    },
    statusPostWordSeparator: {
      marginLeft: 4,
    },
    separationButtons: {
      marginRight: 8,
    },
    headerContainerBackground: {},
    headerContainerBackgroundBg: {},
    headerBackgroundAspectRatioFullWith: {},
    headerBackgroundAspectRatioCell: {},
    headerHero: {},
    headerSmall: {
      '& $headerHero': {
        '-webkit-box-flex': 1,
        '-webkit-flex': '1 0 auto',
        '-ms-flex': '1 0 auto',
        flex: '1 0 auto',
      },
      '& $overlayBackground': {
        display: 'none',
      },
    },
    headerMedium: {
      position: 'relative',
      display: 'table',
      width: '200%',
      tableLayout: 'fixed',

      '& $headerHero': {
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 20,
        width: '100%',
        height: '100%',
        display: 'table',
      },
      '& $headerContainerBackgroundBg': {
        position: 'relative',
        display: 'table-cell',
        width: '50%',
        verticalAlign: 'middle',
        overflow: 'hidden',
        backgroundSize: 'cover',
      },
      '& $headerBackgroundAspectRatioCell': {
        display: 'table-cell',
        width: '50%',
        verticalAlign: 'top',
      },
      '& $headerBackgroundAspectRatioFullWith': {
        display: 'block',
        maxHeight: 300,
        '&:after': {
          // eslint-disable-next-line quotes
          content: "''",
          display: 'block',
          width: '100%',
          height: 0,
          paddingBottom: '12%',
        },
      },
    },
    headerLarge: {
      position: 'relative',
      display: 'table',
      width: '200%',
      tableLayout: 'fixed',
      '& $headerHero': {
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 20,
        width: '100%',
        height: '100%',
        display: 'table',
      },
      '& $headerBackgroundAspectRatioCell': {
        display: 'table-cell',
        width: '50%',
        varticalAlign: 'top',
      },
      '& $headerContainerBackgroundBg': {
        position: 'relative',
        display: 'table-cell',
        width: '50%',
        verticalAlign: 'middle',
        overflow: 'hidden',
        backgroundSize: 'cover',
      },
      '& $headerBackgroundAspectRatioFullWith': {
        display: 'block',
        maxHeight: 700,
        '&:after': {
          // eslint-disable-next-line quotes
          content: "''",
          display: 'block',
          width: '100%',
          height: 0,
          paddingBottom: '36%',
        },
      },
    },
    overlayBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      background: 'rgba(0,0,0,.175)',
      height: '100vh',
    },
    tagline: {
      fontWeight: 400,
    },
    textWhite: {
      color: 'rgba(255, 255, 255, 1)',
    },
    avatarCenter: {
      margin: '0 auto',
    },
    iconButton: {
      padding: 5,
    },
    containerAfterOverlay: {
      position: 'relative',
      zIndex: 100,
    },
    logoContainerlogo: {},
    logoContainerboth: {},
    aligncenter: {
      '& $headerHero': {
        textAlign: 'center',
      },
    },
    alignleft: {},
    separatorMainLogoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    separatorMainLogo: {
      borderRight: '1px solid rgba(0,0,0,.15)',
      marginLeft: 15,
      marginRight: 20,
      height: 28,
      display: 'inline-block',
    },
    metaBar: {
      height: 50,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      marginBottom: 16,
    },
    // overFlowTrickNav: {
    //   bottom: -18,
    //   height: 65,
    //   display: 'flex',
    //   position: 'relative',
    //   overflowY: 'hidden',
    // },
    navTopics: {
      overflow: 'hidden',
    },
    link: {
      color: '#03a87c',
    },
    overflowMobileHeaderInfo: {},
    topicNavLink: {
      color: 'rgba(0,0,0,.54)',
      marginLeft: 24,
      fontFamily:
        // eslint-disable-next-line max-len
        'wm-content-sans-serif-font,"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif!important',
      fontWweight: 400,
      fontStyle: 'normal',
      fontSize: 15,
      lineHeight: '20px',
      transform: 'translateY(1.8px)',
      letterSpacing: '.05em',
      textTransform: 'uppercase',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:hover': {
        color: 'rgba(0,0,0,.84)',
      },
    },
    tabsFlexContainer: {
      height: 48,
    },
    tabsRoot: {
      position: 'relative',
      borderBottom: '0',
      // '& > $tabsFlexContainer': {
      // borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
      // }
    },
    scrollButtons: {
      position: 'absolute',
      left: 0,
      top: -1,
      bottom: 1,
      zIndex: 99,
      background: '#fff',
      width: 'auto',
      marginTop: 6,
    },
    tabsScroller: {
      position: 'relative',
      bottom: -1,
      display: 'flex',
      height: 60,
      overflowY: 'hidden',
      // '& $tabsFlexContainer': {
      //   borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
      // }
      '&+$scrollButtons': {
        left: 'auto',
        right: 0,
      },
    },
    tabsIndicator: {
      height: 1,
      top: 46,
      backgroundColor: 'rgba(0, 0, 0, 0.54)',
    },
    tabRoot: {
      opacity: 1,
      padding: 0,
      minWidth: 0,
      color: 'rgba(0,0,0,.54)',
      marginLeft: 24,
      fontFamily:
        // eslint-disable-next-line max-len
        'wm-content-sans-serif-font,"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif!important',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 15,
      lineHeight: '20px',
      transform: 'translateY(1.8px)',
      letterSpacing: '.05em',
      textTransform: 'uppercase',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:hover': {
        color: 'rgba(0,0,0,.84)',
      },
      '&$tabSelected': {
        color: 'rgba(0, 0, 0, 0.84)',
      },
      '&:focus': {
        color: 'rgba(0, 0, 0, 0.84)',
      },
    },
    tabSelected: {},
    '@media screen and (max-width: 767px)': {
      overflowMobileHeaderInfo: {
        width: 130,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      metaBar: {
        height: 39,
      },
      tabsRoot: {
        height: 39,
        minHeight: 39,
        transform: 'translateY(-6.8px)',
      },
      tabsFlexContainer: {
        height: 39,
      },
      tabsScroller: {
        height: 51,
      },
      tabsIndicator: {
        top: 37,
      },
      scrollButtons: {
        marginTop: 14,
      },
    },
    '@global': {
      '.medium-zoom--opened ._headerWrapGlobal': {
        transform: 'translateY(-100%)',
        '-webkit-transform': 'translateY(-100%)',
      },
      '.no-hiddenscroll': {
        '& $tabsScroller': {
          bottom: -4,
          height: 61,
        },
        '& $tabsIndicator': {
          top: 43,
        },
      },
    },
    wrapperIsOnline: {
      position: 'fixed',
      overflow: 'visible',
      zIndex: 9999,
      top: 0,
      left: 0,
      right: 0,
      height: 50,
      lineHeight: '50px',
      '& ~ *': {
        transform: 'translateY(+50px)',
      },
      '& .notif-container': {
        transform: 'none',
      },
    },
    containerIsOnline: {
      position: 'fixed',
      backgroundColor: '#ffa000',
      borderBottom: '1px solid red',
      color: '#fff',
      top: 0,
      left: 0,
      right: 0,
      height: 50,
      lineHeight: '50px',
      textAlign: 'center',
    },
    buttonDownConn: {
      position: 'relative',
      top: 50,
    },
    wrapperConn: {
      textAlign: 'center',
    },
    buttonProgress: {
      position: 'absolute',
      color: green[500],
      top: '50%',
      left: '50%',
      marginTop: 38,
      marginLeft: -12,
    },
    coronavirus: {
      marginLeft: 10,
      borderColor: 'red',
    },
  },
  { name: 'WMHeaderApp' }
);

// need to add restoreToUnsaveStateReducer

const HeaderApp = (props: any): React.ReactElement => {
  const {
    post,
    postPublic,
    loading,
    user,
    position,
    backgroundImage,
    size,
    title,
    tagline,
    backgroundColor,
    publication,
    contentAfterSeparator,
    headerLogo,
    separatorMainLogo,
    align,
    layout,
    topics,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isOnlineActionDispatch = () => {
    dispatch(isOnlineAction());
  };
  const {
    // saved,
    post: postSaved,
    socketStatus,
    isOnline,
    isOnlineLoading,
  } = useSelector((state: any) => {
    return {
      isOnline: state.online.isOnline,
      socketStatus: state.online.socketStatus,
      isOnlineLoading: state.online.loading,
      saved: state?.meStories?.saved,
      post: state?.meStories?.post,
    };
  });
  const postSavedOrPost = postSaved || post;
  const classes = useStyles();
  const intl = useIntl();
  const { current: componentRef } = useRef({
    didScroll: false,

    interval: false,

    lastScrollTop: 0,

    navbarHeight: 0,

    timeout: false,

    timeoutCountWords: false,

    saved: true,
  });

  const navbar = useRef(null);

  const [state, setState] = useState({
    drawer: {
      top: false,
      left: false,
      bottom: false,
      right: false,
    },
    wordsSelected: 0,
    anchorEl: null,
    anchorElPublication: null,
    navbarBorder: false,
    position: 'fixed',
    disableHidden: false,
    demoMode: false,
    location,
    forceUpdate: 0,
  });

  // const toggleDrawer = (side, open) => () => {
  //   setState((prev) => ({
  //     ...prev,
  //     drawer: {
  //       [side]: open,
  //     },
  //   }));
  // };

  const handleClickAvatar = (event: any) => {
    // const { classes } = this.props;
    // document.getElementById('content').classList.add(classes.blur);
    setState((prev) => ({
      ...prev,
      anchorEl: event.currentTarget,
    }));
  };

  const handleCloseAvatar = () => {
    // const { classes } = this.props;
    // document.getElementById('content').classList.remove(classes.blur);
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        anchorEl: null,
      }));
    }, 50);
  };

  const handleClickPublicationAvatar = (event: any) => {
    // const { classes } = this.props;
    // document.getElementById('content').classList.add(classes.blur);
    setState((prev) => ({
      ...prev,
      anchorElPublication: event.currentTarget,
    }));
  };

  const handleClosePublicationAvatar = () => {
    // const { classes } = this.props;
    // document.getElementById('content').classList.remove(classes.blur);
    setState((prev) => ({
      ...prev,
      anchorElPublication: null,
    }));
  };

  const handleLogout = (event: any) => {
    handleCloseAvatar();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch(logout(event));
  };

  useEffect(() => {
    const disconnectListener = () => {
      dispatch(setSocketStatus('close'));
    };

    socket.on('disconnect', disconnectListener);

    const reconnectListener = () => {
      dispatch(setSocketStatus('open'));
    };
    socket.io.on('reconnect', reconnectListener);

    componentRef.didScroll = true;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    componentRef.navbarHeight = navbar.current.clientHeight;

    return () => {
      if (componentRef.timeout) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        clearTimeout(componentRef.timeout);
      }
      if (componentRef.interval) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        clearInterval(componentRef.interval);
      }
      socket.io.off('disconnect', disconnectListener);
      socket.io.off('reconnect', reconnectListener);
    };
  }, []);

  useEffect(() => {
    if (socketStatus === 'close') {
      const checkIfIsOnline = () => {
        isOnlineActionDispatch();
      };
      window.addEventListener('online', checkIfIsOnline);
      window.addEventListener('offline', checkIfIsOnline);

      intervalIsOnline = setInterval(() => {
        isOnlineActionDispatch();
      }, 4000);

      return () => {
        window.removeEventListener('online', checkIfIsOnline);
        window.removeEventListener('offline', checkIfIsOnline);
        if (intervalIsOnline) {
          clearInterval(intervalIsOnline);
          intervalIsOnline = false;
        }
      };
    }
  }, [socketStatus]);

  // shouldComponentUpdate(nextProps, nextState) {
  //   return !shallowEqual(nextProps, this.props) || !shallowEqual(nextState, this.state);
  // }
  const pathRegexEditPage = pathToRegexp('/me/stories/edit/:uid');
  const resultEdit = pathRegexEditPage.exec(location.pathname);
  const isEditPostPage = resultEdit && resultEdit[1];

  useEffect(() => {
    if (location !== state.location) {
      setState({ ...state, location, navbarBorder: false });
    }

    const handleMouseupContent = () => {
      if (componentRef.timeoutCountWords) {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        clearTimeout(componentRef.timeoutCountWords);
      }

      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'boolean'... Remove this comment to see the full error message
      componentRef.timeoutCountWords = setTimeout(() => {
        const { wordsSelected } = state;
        const selection = window.getSelection();
        if (selection) {
          const text = selection.toString();
          if (text.trim()) {
            console.log(text.trim().replaceAll('\t', '').replaceAll('\n', ' '), 'text.trim()');
            const readingTimeResult = readingTime(text);
            const readingTimeResult2 = readingTime(
              text.trim().replaceAll('\t', '').replaceAll('\n', ' ')
            );
            console.log(readingTimeResult, 'readingTimeResult');
            console.log(readingTimeResult2, 'readingTimeResult2');
            setState((prev) => ({ ...prev, wordsSelected: readingTimeResult.words.total }));
            return;
          }
        }

        if (wordsSelected !== 0) {
          setState((prev) => ({ ...prev, wordsSelected: 0 }));
        }
      }, 50);
    };

    const contentNode = document.querySelector('.ProseMirror');
    if (contentNode) {
      contentNode.addEventListener('mouseup', handleMouseupContent);
      contentNode.addEventListener('keyup', handleMouseupContent);
      contentNode.addEventListener('selectstart', handleMouseupContent);
    }

    return () => {
      if (contentNode) {
        contentNode.removeEventListener('mouseup', handleMouseupContent);
        contentNode.removeEventListener('keyup', handleMouseupContent);
        contentNode.removeEventListener('selectstart', handleMouseupContent);
      }
    };
  });

  const { navbarBorder, anchorEl, anchorElPublication, wordsSelected } = state;

  const headerClassname = classNames('_headerWrapGlobal', classes.headerWrap, {
    // [classes.navUp]: !scrollUp && !isEditPostPage,
    // [classes.navDown]: scrollUp && !isEditPostPage,
    [classes.headerBorder]: navbarBorder && !isEditPostPage,
  });

  let bgColorStyles = {};
  let contrastText = {};

  if (backgroundColor) {
    const contrastThreshold = 3;

    if (backgroundColor.length === 3 || backgroundColor.length === 6) {
      bgColorStyles = { backgroundColor: `#${backgroundColor}` };
      contrastText =
        getContrastRatio(`#${backgroundColor}`, '#000') <= contrastThreshold
          ? { color: '#fff' }
          : {};
    }
  }
  const styleBg = backgroundImage.src
    ? {
        ...bgColorStyles,
        backgroundImage: `url(${backgroundImage.src})`,
        backgroundPositionX: `${backgroundImage.pos ? backgroundImage.pos.x : 50}%`,
        backgroundPositionY: `${backgroundImage.pos ? backgroundImage.pos.y : 50}%`,
      }
    : bgColorStyles;

  return (
    <>
      {!isOnline && (
        <div className={classes.wrapperIsOnline}>
          <div className={classes.containerIsOnline}>
            {isOnlineLoading ? 'Restablishing connection' : 'Your connection is down'}
          </div>
          <div className={classes.wrapperConn}>
            <Button
              disabled={isOnlineLoading}
              className={classes.buttonDownConn}
              onClick={() => {
                isOnlineActionDispatch();
              }}
              variant="outlined"
              size="small"
              color="primary"
            >
              {isOnlineLoading ? 'Reconnecting...' : 'Reconnect'}
            </Button>
            {isOnlineLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div>
      )}
      <HideOnScroll classes={classes} {...props} disableHidden={isEditPostPage}>
        <AppBar
          classes={{ root: classes.headerRoot }}
          className={headerClassname}
          position={position}
          ref={navbar}
        >
          {/* @ts-expect-error ts-migrate(2746) FIXME: This JSX tag's 'children' prop expects a single ch... Remove this comment to see the full error message */}
          <Container size="publicationSize">
            <Toolbar className={classes.toolbar}>
              <Typography component="div" color="inherit">
                <Link className={(classes as any).title} to="/">
                  <LogoSymbol className={classes.logoHeader} />
                  <Typography display="none" displayPrint="block">
                    Homepage
                  </Typography>
                </Link>
              </Typography>
              {!!separatorMainLogo && (
                <div className={classes.separatorMainLogoContainer}>
                  <span className={classes.separatorMainLogo} />
                </div>
              )}
              {contentAfterSeparator && contentAfterSeparator}
              {isEditPostPage && (
                <>
                  {wordsSelected === 0 && !!postSavedOrPost?.draftContent?.Publication?.name && (
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={`${classes.statusPost} ${classes.overflowMobileHeaderInfo}`}
                    >
                      {postSavedOrPost?.draftContent?.Publication?.uid !==
                      postSavedOrPost?.Publication?.uid
                        ? 'Draft '
                        : `${capitalize(postSavedOrPost.status)} `}
                      {intl.formatMessage({
                        id: 'in',
                        defaultMessage: 'in',
                      })}{' '}
                      <Link
                        className={classes.link}
                        to={`/${postSaved.draftContent.Publication.slug}`}
                      >
                        {postSaved.draftContent.Publication.name}
                      </Link>
                    </Typography>
                  )}
                  {wordsSelected !== 0 && (
                    <Typography variant="body2" color="textPrimary" className={classes.statusPost}>
                      {wordsSelected}{' '}
                      {intl.formatMessage({
                        id: 'words',
                        defaultMessage: 'words',
                      })}
                    </Typography>
                  )}
                  {wordsSelected === 0 && !postSavedOrPost?.draftContent?.Publication?.name && (
                    <Typography color="textPrimary" variant="body2" className={classes.statusPost}>
                      {capitalize(postSavedOrPost.status)}
                    </Typography>
                  )}
                  {/* {wordsSelected === 0 && loading && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.statusPost}
                    >
                      {intl.formatMessage({
                        id: 'Saving',
                        defaultMessage: 'Saving',
                      })}
                      ...
                    </Typography>
                  )}
                  {wordsSelected === 0 && loading === false && saved && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.statusPost}
                    >
                      {intl.formatMessage({
                        id: 'Saved',
                        defaultMessage: 'Saved',
                      })}
                    </Typography>
                  )} */}
                </>
              )}
              <div className={classes.grow} />
              {!isEditPostPage && <AppSearch />}
              {!user && (
                <Button
                  component={Link}
                  to={`/login${
                    location.pathname !== '/login'
                      ? `?redirect=${encodeURIComponent(location.pathname)}`
                      : ''
                  }`}
                  color="secondary"
                  variant="outlined"
                >
                  {intl.formatMessage({
                    id: 'sign_in',
                    defaultMessage: 'Sign in',
                  })}
                </Button>
              )}
              {user && (
                <>
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <HeaderUser />
                  </Box>
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleClickAvatar}
                    size="large"
                  >
                    <Avatar className={classes.colorAvatar}>
                      {user.avatar && user.avatar.md5 ? (
                        <img
                          className={classes.imageAvatarPublication}
                          alt="Avatar User"
                          src={getImageLink(user.avatar.md5, 'fit_c_64x64', user.avatar.format)}
                        />
                      ) : (
                        <AvatarIcon />
                      )}
                    </Avatar>
                  </IconButton>
                  {publication &&
                    user &&
                    publication.creator &&
                    (publication.creator.uid === user.uid ||
                      userCan(user, 'all') ||
                      publication.isEditor) && (
                      <>
                        <IconButton
                          className={classes.iconButton}
                          onClick={handleClickPublicationAvatar}
                          size="large"
                        >
                          <img
                            className={classes.imageAvatarPublication}
                            alt={publication.name}
                            src={getImageLink(
                              publication.avatar.md5,
                              'fit_c_64x64',
                              publication.avatar.format
                            )}
                          />
                        </IconButton>
                        <Menu
                          margin={-15}
                          anchorEl={anchorElPublication}
                          handleClosePopover={handleClosePublicationAvatar}
                        >
                          <List>
                            <ListItem>
                              <img
                                className={classes.avatarCenter}
                                alt={`Avatar of ${publication.name}`}
                                src={getImageLink(
                                  publication.avatar.md5,
                                  'fit_c_160x160',
                                  publication.avatar.format
                                )}
                                width={80}
                                height={80}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem
                              onClick={handleClosePublicationAvatar}
                              component={Link}
                              to={`/${publication.slug}/stories/create`}
                              button
                            >
                              {intl.formatMessage({
                                id: 'New_story',
                                defaultMessage: 'New story',
                              })}
                            </ListItem>
                            <Divider />
                            <ListItem
                              onClick={handleClosePublicationAvatar}
                              component={Link}
                              to={`/${publication.slug}/stories/drafts`}
                              button
                            >
                              {intl.formatMessage({
                                id: 'Stories',
                                defaultMessage: 'Stories',
                              })}
                            </ListItem>
                            <ListItem
                              onClick={handleClosePublicationAvatar}
                              component={Link}
                              to={`/${publication.slug}/stories/drafts`}
                              button
                            >
                              {intl.formatMessage({
                                id: 'Stats',
                                defaultMessage: 'Stats',
                              })}
                            </ListItem>
                            <ListItem
                              onClick={handleClosePublicationAvatar}
                              component={Link}
                              to={`/${publication.slug}/stories/drafts`}
                              button
                            >
                              {intl.formatMessage({
                                id: 'Letters',
                                defaultMessage: 'Letters',
                              })}
                            </ListItem>
                            <ListItem
                              onClick={handleClosePublicationAvatar}
                              component={Link}
                              to={`/${publication.slug}/stories/drafts`}
                              button
                            >
                              {intl.formatMessage({
                                id: 'Followers',
                                defaultMessage: 'Followers',
                              })}
                            </ListItem>
                            <Divider />
                            <ListItem
                              onClick={handleClosePublicationAvatar}
                              component={Link}
                              to={`/${publication.slug}/settings/navigation`}
                              button
                            >
                              {intl.formatMessage({
                                id: 'Navigation',
                                defaultMessage: 'Navigation',
                              })}
                            </ListItem>
                            <ListItem
                              onClick={handleClosePublicationAvatar}
                              component={Link}
                              to={`/${publication.slug}/settings/featured-pages`}
                              button
                            >
                              {intl.formatMessage({
                                id: 'Featured_pages',
                                defaultMessage: 'Featured pages',
                              })}
                            </ListItem>
                            <ListItem
                              onClick={handleClosePublicationAvatar}
                              component={Link}
                              to={`/${publication.slug}/settings`}
                              button
                            >
                              {intl.formatMessage({
                                id: ' Homepage_and_settings',
                                defaultMessage: ' Homepage and settings',
                              })}
                            </ListItem>
                          </List>
                          {/* <div className={classes.popoverArrow} /> */}
                        </Menu>
                      </>
                    )}
                  <Menu margin={-15} anchorEl={anchorEl} handleClosePopover={handleCloseAvatar}>
                    <List>
                      {postPublic &&
                        (user.UserRole.role === 'admin' || user.uid === postPublic.User.uid) && (
                          <>
                            <ListItem
                              onClick={handleCloseAvatar}
                              component={Link}
                              to={`/me/stories/edit/${postPublic.uid}`}
                              button
                            >
                              {intl.formatMessage({
                                id: 'Edit_story',
                                defaultMessage: 'Edit story',
                              })}
                            </ListItem>
                            <Divider />
                          </>
                        )}

                      {(user.UserRole.role === 'admin' || user.UserRole.role === 'editor') && (
                        <ListItem
                          onClick={handleCloseAvatar}
                          component={Link}
                          to="/me/stories/create"
                          state={{ forceNewForm: true }}
                          button
                        >
                          {intl.formatMessage({
                            id: 'New_story',
                            defaultMessage: 'New story',
                          })}
                        </ListItem>
                      )}
                      <ListItem
                        onClick={handleCloseAvatar}
                        component={Link}
                        to="/me/stories/drafts"
                        button
                      >
                        {intl.formatMessage({
                          id: 'Stories',
                          defaultMessage: 'Stories',
                        })}
                      </ListItem>
                      <Divider />
                      <ListItem
                        onClick={handleCloseAvatar}
                        button
                        component={Link}
                        to="/me/publications"
                      >
                        {intl.formatMessage({
                          id: 'Publications',
                          defaultMessage: 'Publications',
                        })}
                      </ListItem>
                      {userCan(user, 'all') && (
                        <>
                          <Divider />
                          <ListItem
                            component={Link}
                            to="/me/users"
                            onClick={handleCloseAvatar}
                            button
                          >
                            {intl.formatMessage({
                              id: 'Users',
                              defaultMessage: 'Users',
                            })}
                          </ListItem>
                          <ListItem
                            component={Link}
                            to="/me/topics"
                            onClick={handleCloseAvatar}
                            button
                          >
                            {intl.formatMessage({
                              id: 'Topics',
                              defaultMessage: 'Topics',
                            })}
                          </ListItem>
                        </>
                      )}
                      <Divider />
                      <ListItem
                        component={Link}
                        to={`/@${user.username}`}
                        onClick={handleCloseAvatar}
                        button
                      >
                        {intl.formatMessage({
                          id: 'Profile',
                          defaultMessage: 'Profile',
                        })}
                      </ListItem>
                      <ListItem
                        component={Link}
                        to="/me/settings"
                        onClick={handleCloseAvatar}
                        button
                      >
                        {intl.formatMessage({
                          id: 'Settings',
                          defaultMessage: 'Settings',
                        })}
                      </ListItem>
                      <ListItem onClick={handleLogout} button>
                        {intl.formatMessage({
                          id: 'Sign_out',
                          defaultMessage: 'Sign out',
                        })}
                      </ListItem>
                    </List>
                    {/* <div className={classes.popoverArrow} /> */}
                  </Menu>
                </>
              )}
            </Toolbar>
            {isEditPostPage && (
              <Toolbar
                sx={{ display: { sm: 'none', xs: 'flex' } }}
                className={`${classes.toolbar} ${classes.toolbarMobile}`}
              >
                {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ location: Location<State>; navigate: Navig... Remove this comment to see the full error message */}
                <HeaderUser location={location} navigate={navigate} loading={loading} />
              </Toolbar>
            )}
          </Container>
        </AppBar>
      </HideOnScroll>
      {topics && Array.isArray(topics) && (
        // @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; size: "publicationSize"... Remove this comment to see the full error message
        <Container size="publicationSize">
          <div className={classes.metaBar}>
            <nav className={classes.navTopics}>
              <Tabs
                value={false}
                variant="scrollable"
                scrollButtons
                classes={{
                  scrollButtons: classes.scrollButtons,
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                  scroller: classes.tabsScroller,
                  flexContainer: classes.tabsFlexContainer,
                }}
                allowScrollButtonsMobile
              >
                {topics.map((topic) => (
                  <Tab
                    key={topic.slug}
                    component={Link}
                    to={`/${intl.locale === 'en' ? 'topic' : 'tema'}/${topic.slug}`}
                    disableRipple
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    label={topic.name}
                  />
                ))}
                {/* <Link className={classes.topicNavLink} to="/topics">More</Link> */}
              </Tabs>
            </nav>
          </div>
        </Container>
      )}

      <div
        className={classNames(classes.headerContainerBackground, {
          [classes.headerLarge]: size === 'l',
          [classes.headerMedium]: size === 'm',
          [classes.headerSmall]: size === 's',
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          [classes[`align${align}`]]: align,
        })}
      >
        <div style={styleBg} className={classes.headerContainerBackgroundBg}>
          {backgroundImage.src && <div className={classes.overlayBackground} />}
          {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; size: "normal" | "publi... Remove this comment to see the full error message */}
          <Container
            size={publication ? 'publicationSize' : 'normal'}
            className={classes.containerAfterOverlay}
          >
            <header>
              <div className={classes.headerHero}>
                {size !== 's' && layout !== 'title' && !!headerLogo && (
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  <div className={classNames({ [classes[`logoContainer${layout}`]]: layout })}>
                    {headerLogo}
                  </div>
                )}
                {!!title && layout !== 'logo' && (
                  <Typography
                    style={contrastText}
                    className={classNames({ [classes.textWhite]: !!backgroundImage.src })}
                    variant="h1"
                  >
                    {title}
                  </Typography>
                )}
                {size !== 's' && layout !== 'logo' && !!tagline && (
                  <Typography
                    style={contrastText}
                    className={classNames(classes.tagline, {
                      [classes.textWhite]: !!backgroundImage.src,
                    })}
                    variant="h2"
                  >
                    {tagline}
                  </Typography>
                )}
              </div>
            </header>
          </Container>
        </div>
        <div className={classes.headerBackgroundAspectRatioCell}>
          <div className={classes.headerBackgroundAspectRatioFullWith} />
        </div>
      </div>
    </>
  );
};

HeaderApp.defaultProps = {
  isOnline: true,
  isOnlineLoading: false,
  position: 'static',
  backgroundColor: false,
  publication: false,
  size: 's',
  layout: 'title',
  title: '',
  tagline: '',
  backgroundImage: {},
  loading: null,
  post: false,
  postPublic: false,
  user: null,
  disableHidden: false,
  demoMode: false,
  separatorMainLogo: false,
  contentAfterSeparator: false,
  headerLogo: false,
  align: false,
  saved: true,
};

export default HeaderApp;
