/* eslint-disable react/sort-comp */
import { createRef, Component } from 'react';
import { connect } from 'react-redux';

type Props = {
  notifs: any[];
  NotifComponent: React.ReactElement;
  handleCloseNotif: (...args: any[]) => any;
  className: string;
};

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@connect((state, props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'namespace' does not exist on type '{}'.
  const { namespace } = props;
  return { notifs: (state as any).notifs[namespace] || [] };
})
export default class Notifs extends Component<Props> {
  refsitemsRefs = {};

  shouldComponentUpdate(nextProps: Props) {
    const { notifs } = this.props;
    return notifs !== nextProps.notifs;
  }

  render() {
    const { notifs, className, NotifComponent, handleCloseNotif } = this.props;
    const indexs = {};
    const calculateHeightsItems = {};
    const notifsReverse = notifs.reverse();
    return (
      <div className={`notif-container ${className}`}>
        {notifsReverse.map((notif) => {
          const action = notif.action !== undefined ? notif.action : true;
          const position = notif.position
            ? notif.position
            : {
                vertical: 'bottom',
                horizontal: 'left',
              };
          const keyNoti = JSON.stringify(position);
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (typeof indexs[keyNoti] === 'undefined') {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            calculateHeightsItems[keyNoti] = [];
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            indexs[keyNoti] = 0;
          } else {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            indexs[keyNoti] += 1;
          }

          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (!this.refsitemsRefs[notif.id]) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            this.refsitemsRefs[notif.id] = createRef();
          }

          let calculateHeights = 0;
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          calculateHeightsItems[keyNoti].forEach((item: any) => {
            if (item.current) {
              calculateHeights += item.current.clientHeight;
            }
          });

          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          calculateHeightsItems[keyNoti].push(this.refsitemsRefs[notif.id]);

          return (
            // @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'NotifComponent' does not have an... Remove this comment to see the full error message
            <NotifComponent
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              ref={this.refsitemsRefs[notif.id]}
              calculateHeights={calculateHeights}
              handleCloseNotif={handleCloseNotif}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              notifIndex={indexs[keyNoti]}
              key={`${notif.id}-notifwraper`}
              notifId={notif.id}
              message={notif.message}
              kind={notif.kind}
              position={position}
              action={action}
              actions={notif.actions}
            />
          );
        })}
      </div>
    );
  }
}
