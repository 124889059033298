import { Component } from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redi... Remove this comment to see the full error message
import provideHooks from 'redial/lib/provideHooks';
import {
  searchMy,
  isLoadedMy,
  isLoadedFollowing,
  searchFollowing,
} from 'redux/modules/publications';
import loadable from '@loadable/component';

const Publications = loadable(
  () => import(/* webpackChunkName: 'publications' */ './Publications')
);

@provideHooks({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
  fetch: async ({ store: { dispatch, getState } }) => {
    const promises = [];

    if (!isLoadedMy(getState())) {
      promises.push(dispatch(searchMy()).catch(() => null));
    }

    if (!isLoadedFollowing(getState())) {
      promises.push(dispatch(searchFollowing()).catch(() => null));
    }

    await Promise.all(promises);
  },
})
export default class PublicationsContainer extends Component {
  render() {
    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ children?: ReactNode; }' is missing the fo... Remove this comment to see the full error message
    return <Publications {...this.props} />;
  }
}
