import differenceInSeconds from 'date-fns/differenceInSeconds';
import deepmerge from 'deep-extend';
import type { Application } from '@feathersjs/feathers';

const SHARE = 'redux-webmediums/me/stories/SHARE';
const SHARE_SUCCESS = 'redux-webmediums/me/stories/SHARE_SUCCESS';
const SHARE_FAIL = 'redux-webmediums/me/stories/SHARE_FAIL';

const LOAD = 'redux-webmediums/me/stories/LOAD';
const LOAD_SUCCESS = 'redux-webmediums/me/stories/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-webmediums/me/stories/LOAD_FAIL';

const LOAD_BY_PUBLICATION = 'redux-webmediums/me/stories/LOAD_BY_PUBLICATION';
const LOAD_BY_PUBLICATION_SUCCESS = 'redux-webmediums/me/stories/LOAD_BY_PUBLICATION_SUCCESS';
const LOAD_BY_PUBLICATION_FAIL = 'redux-webmediums/me/stories/LOAD_BY_PUBLICATION_FAIL';

const SAVE = 'redux-webmediums/me/stories/SAVE';
const SAVE_SUCCESS = 'redux-webmediums/me/stories/SAVE_SUCCESS';
const SAVE_FAIL = 'redux-webmediums/me/stories/SAVE_FAIL';

const LOAD_POST = 'redux-webmediums/me/stories/LOAD_POST';
const LOAD_POST_SUCCESS = 'redux-webmediums/me/stories/LOAD_POST_SUCCESS';
const LOAD_POST_FAIL = 'redux-webmediums/me/stories/LOAD_POST_FAIL';

const LOAD_POST_BY_KEY = 'redux-webmediums/me/stories/LOAD_POST_BY_KEY';
const LOAD_POST_SUCCESS_BY_KEY = 'redux-webmediums/me/stories/LOAD_POST_SUCCESS_BY_KEY';
const LOAD_POST_FAIL_BY_KEY = 'redux-webmediums/me/stories/LOAD_POST_FAIL_BY_KEY';

const CREATE_POST = 'redux-webmediums/me/stories/CREATE_POST';
const CREATE_POST_SUCCESS = 'redux-webmediums/me/stories/CREATE_POST_SUCCESS';
const CREATE_POST_FAIL = 'redux-webmediums/me/stories/CREATE_POST_FAIL';

const SAVE_POST = 'redux-webmediums/me/stories/SAVE_POST';
const SAVE_POST_SUCCESS = 'redux-webmediums/me/stories/SAVE_POST_SUCCESS';
const SAVE_POST_FAIL = 'redux-webmediums/me/stories/SAVE_POST_FAIL';

const SAVE_POST_I18N_RESTORE_TO_UNSAVE =
  'redux-webmediums/me/stories/SAVE_POST_I18N_RESTORE_TO_UNSAVE';
const SAVE_POST_I18N_RESTORE_TO_SAVE = 'redux-webmediums/me/stories/SAVE_POST_I18N_RESTORE_TO_SAVE';
const SAVE_POST_I18N = 'redux-webmediums/me/stories/SAVE_POST_I18N';
const SAVE_POST_I18N_SUCCESS = 'redux-webmediums/me/stories/SAVE_POST_I18N_SUCCESS';
const SAVE_POST_I18N_FAIL = 'redux-webmediums/me/stories/SAVE_POST_I18N_FAIL';

const DELETE_ALL_POST_I18N = 'redux-webmediums/me/stories/DELETE_ALL_POST_I18N';
const DELETE_ALL_POST_I18N_SUCCESS = 'redux-webmediums/me/stories/DELETE_ALL_POST_I18N_SUCCESS';
const DELETE_ALL_POST_I18N_FAIL = 'redux-webmediums/me/stories/DELETE_ALL_POST_I18N_FAIL';

const DELETE_POST_I18N = 'redux-webmediums/me/stories/DELETE_POST_I18N';
const DELETE_POST_I18N_SUCCESS = 'redux-webmediums/me/stories/DELETE_POST_I18N_SUCCESS';
const DELETE_POST_I18N_FAIL = 'redux-webmediums/me/stories/DELETE_POST_I18N_FAIL';

const CHANGE_STATUS_POST_I18N = 'redux-webmediums/me/stories/CHANGE_STATUS_POST_I18N';
const CHANGE_STATUS_POST_I18N_SUCCESS =
  'redux-webmediums/me/stories/CHANGE_STATUS_POST_I18N_SUCCESS';
const CHANGE_STATUS_POST_I18N_FAIL = 'redux-webmediums/me/stories/CHANGE_STATUS_POST_I18N_FAIL';

const DELETE_ALL_UNRESTORE_POST_I18N = 'redux-webmediums/me/stories/DELETE_ALL_UNRESTORE_POST_I18N';
const DELETE_ALL_UNRESTORE_POST_I18N_SUCCESS =
  'redux-webmediums/me/stories/DELETE_ALL_UNRESTORE_POST_I18N_SUCCESS';
const DELETE_ALL_UNRESTORE_POST_I18N_FAIL =
  'redux-webmediums/me/stories/DELETE_ALL_UNRESTORE_POST_I18N_FAIL';

const DELETE_UNRESTORE_POST_I18N = 'redux-webmediums/me/stories/DELETE_UNRESTORE_POST_I18N';
const DELETE_UNRESTORE_POST_I18N_SUCCESS =
  'redux-webmediums/me/stories/DELETE_UNRESTORE_POST_I18N_SUCCESS';
const DELETE_UNRESTORE_POST_I18N_FAIL =
  'redux-webmediums/me/stories/DELETE_UNRESTORE_POST_I18N_FAIL';

const RESET_POST_LOADED = 'redux-webmediums/me/stories/RESET_POST_LOADED';
const RESET_STORIES_LOADED = 'redux-webmediums/me/stories/RESET_STORIES_LOADED';

// @ts-expect-error hide this
Promise.pending = Promise.race.bind(Promise, []);

const initialState = {
  loaded: false,
  saved: true,
  loadedPost: {},
  byPublication: {},
  storiesByPublication: {},
  storiesByPublicationUpdated: null,
  postsByKey: {},
  stories: {
    rejected: { data: [] },
    task: { data: [] },
    draft: { data: [] },
    public: { data: [] },
    scheduled: { data: [] },
    'pre-public': { data: [] },
    'no-translated': { data: [] },
    deleted: { data: [] },
  },
  post: {},
};

export default function reducer(state = initialState, action: any = {}) {
  let storiesById: any;
  switch (action.type) {
    case SHARE:
      return {
        ...state,
        loading: true,
      };
    case SHARE_SUCCESS: {
      return {
        ...state,
        loading: false,
        shared: true,
      };
    }
    case SHARE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOAD:
      return {
        ...state,
        [action.status]: {
          loading: true,
        },
      };
    case LOAD_SUCCESS: {
      if (action.result.skip === 0) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.stories[action.status].data = [];
      }
      const copy = { ...action.result };
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      copy.data = state.stories[action.status].data.concat(copy.data);

      return {
        ...state,
        [action.status]: {
          loading: false,
          loaded: true,
          lastTimeStoriesLoaded:
            action.result.skip === 0
              ? new Date()
              : // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                state[action.status].lastTimeStoriesLoaded,
        },
        stories: { ...state.stories, [action.status]: copy },
        timeSaved: null,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        [action.status]: {
          loading: false,
          loaded: false,
        },
        stories: {
          rejected: { data: [] },
          task: { data: [] },
          draft: { data: [] },
          public: { data: [] },
          scheduled: { data: [] },
          'pre-public': { data: [] },
          'no-translated': { data: [] },
          deleted: { data: [] },
        },
        error: action.error,
      };

    case RESET_STORIES_LOADED:
      return {
        ...state,
        rejected: {
          loading: false,
          loaded: false,
        },
        task: {
          loading: false,
          loaded: false,
        },
        draft: {
          loading: false,
          loaded: false,
        },
        public: {
          loading: false,
          loaded: false,
        },
        scheduled: {
          loading: false,
          loaded: false,
        },
        'pre-public': {
          loading: false,
          loaded: false,
        },
        'no-translated': {
          loading: false,
          loaded: false,
        },
        deleted: {
          loading: false,
          loaded: false,
        },
        stories: {
          rejected: { data: [] },
          task: { data: [] },
          draft: { data: [] },
          public: { data: [] },
          scheduled: { data: [] },
          'pre-public': { data: [] },
          'no-translated': { data: [] },
          deleted: { data: [] },
        },
      };

    case LOAD_BY_PUBLICATION: {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!state.storiesByPublication[action.publication]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.storiesByPublication[action.publication] = {};
      }
      const mergeData = deepmerge(state.storiesByPublication, {
        [action.publication]: {
          [action.status]: {
            loading: true,
          },
        },
      });
      return {
        ...state,
        storiesByPublication: mergeData,
      };
    }
    case LOAD_BY_PUBLICATION_SUCCESS: {
      if (action.result.skip === 0) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (!state.storiesByPublication[action.publication]) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state.storiesByPublication[action.publication] = {};
        }
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.storiesByPublication[action.publication][action.status] = {};
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.storiesByPublication[action.publication][action.status].data = [];
      }
      const copy = { ...action.result };
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      copy.data = state.storiesByPublication[action.publication][action.status].data.concat(
        copy.data
      );

      const mergeData = deepmerge(state.storiesByPublication, {
        [action.publication]: {
          [action.status]: {
            ...copy,
            loading: false,
            loaded: true,
            lastTimeStoriesLoaded:
              action.result.skip === 0
                ? new Date()
                : // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  state.storiesByPublication[action.publication][action.status]
                    .lastTimeStoriesLoaded,
          },
        },
      });
      return {
        ...state,
        storiesByPublication: mergeData,
        storiesByPublicationUpdated: new Date(),
      };
    }
    case LOAD_BY_PUBLICATION_FAIL: {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!state.storiesByPublication[action.publication]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.storiesByPublication[action.publication] = {};
      }
      const mergeData = deepmerge(state.storiesByPublication, {
        [action.publication]: {
          [action.status]: {
            loading: false,
            loaded: false,
          },
          lastTimeStoriesLoaded: null,
        },
      });
      return {
        ...state,
        storiesByPublication: {
          [action.publication]: mergeData,
        },
        error: action.error,
      };
    }

    case LOAD_POST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        post: action.result,
        draftContent: action.result.draftContent,
      };
    case LOAD_POST_FAIL:
      return {
        ...state,
        loading: false,
        loadedPost: false,
        error: action.error,
      };

    case LOAD_POST_BY_KEY:
      return {
        ...state,
        loadingPostByKey: true,
      };
    case LOAD_POST_SUCCESS_BY_KEY:
      return {
        ...state,
        loadingPostByKey: false,
        postsByKey: { [action.key]: action.result },
      };
    case LOAD_POST_FAIL_BY_KEY:
      return {
        ...state,
        loadingPostByKey: false,
        errorPostByKey: action.error,
      };

    case RESET_POST_LOADED:
      return {
        ...state,
        loading: false,
        loaded: false,
        post: {},
      };
    case CREATE_POST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        post: action.result,
      };
    case CREATE_POST_FAIL:
      return {
        ...state,
        loading: false,
        loadedPost: false,
        error: action.error,
      };

    case SAVE:
      return {
        ...state,
        loading: true,
      };
    // eslint-disable-next-line
    case SAVE_SUCCESS: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ draft: {... Remove this comment to see the full error message
      const { data } = state.stories;

      storiesById = data.reduce((obj: any, value: any) => {
        obj[value.uid] = value;
        return obj;
      }, {});

      const resultData: any = [];
      storiesById[action.index] = action.result;
      Object.keys(storiesById).forEach((key) => {
        resultData.push(storiesById[key]);
      });
      (state.stories as any).data = resultData;
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case SAVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SAVE_POST:
      return {
        ...state,
        loading: true,
      };
    // eslint-disable-next-line
    case SAVE_POST_SUCCESS:
      return {
        ...state,
        post: action.result,
        loading: false,
      };
    case SAVE_POST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SAVE_POST_I18N_RESTORE_TO_UNSAVE:
      return {
        ...state,
        saved: false,
      };
    case SAVE_POST_I18N_RESTORE_TO_SAVE:
      return {
        ...state,
        saved: true,
      };
    case SAVE_POST_I18N:
      return {
        ...state,
        loading: true,
        saved: action.publish ? state.saved : false,
      };
    // eslint-disable-next-line
    case SAVE_POST_I18N_SUCCESS:
      return {
        ...state,
        post: action.result,
        draftContent: action.draftContent || (state as any).draftContent,
        loading: false,
        saved: true,
        timeSaved: new Date(),
      };
    case SAVE_POST_I18N_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHANGE_STATUS_POST_I18N:
    case DELETE_POST_I18N: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHANGE_STATUS_POST_I18N_SUCCESS:
    case DELETE_POST_I18N_SUCCESS: {
      const story = action.storyToDelete || action.story;

      if (story && story.status !== action.status) {
        if (action.publication) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state.storiesByPublication[action.publication][story.status].total -= 1;
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state.storiesByPublication[action.publication][story.status].skip -= 1;
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          delete state.storiesByPublication[action.publication][story.status].data.splice(
            story.index,
            1
          );
        } else {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state.stories[story.status].total -= 1;
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state.stories[story.status].skip -= 1;
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          delete state.stories[story.status].data.splice(story.index, 1);
        }
      }
      return {
        ...state,
        loading: false,
        stories: {
          rejected: state.stories.rejected,
          task: state.stories.task,
          draft: state.stories.draft,
          public: state.stories.public,
          scheduled: state.stories.scheduled,
          'pre-public': state.stories['pre-public'],
          'no-translated': state.stories['no-translated'],
          deleted: state.stories.deleted,
        },
        storiesByPublication: state.storiesByPublication,
      };
    }
    case CHANGE_STATUS_POST_I18N_FAIL:
    case DELETE_POST_I18N_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case DELETE_UNRESTORE_POST_I18N: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_UNRESTORE_POST_I18N_SUCCESS: {
      if (action.storyToDelete) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.stories[action.storyToDelete.status].total -= 1;
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.stories[action.storyToDelete.status].skip -= 1;
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        delete state.stories[action.storyToDelete.status].data.splice(
          action.storyToDelete.index,
          1
        );
      }
      return {
        ...state,
        loading: false,
        stories: {
          rejected: state.stories.rejected,
          task: state.stories.task,
          draft: state.stories.draft,
          public: state.stories.public,
          scheduled: state.stories.scheduled,
          'pre-public': state.stories['pre-public'],
          'no-translated': state.stories['no-translated'],
          deleted: state.stories.deleted,
        },
      };
    }
    case DELETE_UNRESTORE_POST_I18N_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState: any, status = 'draft') {
  return (
    globalState.meStories &&
    globalState.meStories[status] &&
    globalState.meStories[status].loaded &&
    globalState.meStories[status].lastTimeStoriesLoaded &&
    differenceInSeconds(new Date(), globalState.meStories[status].lastTimeStoriesLoaded) < 5
  );
}

export function isLoadedByPublication(globalState: any, publication: any, status = 'draft') {
  return (
    globalState.meStories &&
    globalState.meStories.storiesByPublication[publication] &&
    globalState.meStories.storiesByPublication[publication][status] &&
    globalState.meStories.storiesByPublication[publication][status].loaded &&
    globalState.meStories.storiesByPublication[publication][status].lastTimeStoriesLoaded &&
    differenceInSeconds(
      new Date(),
      globalState.meStories.storiesByPublication[publication][status].lastTimeStoriesLoaded
    ) < 5
  );
}

export function resetPostLoaded() {
  return {
    type: RESET_POST_LOADED,
  };
}

export function resetStoriesLoaded() {
  return {
    type: RESET_STORIES_LOADED,
  };
}

export function load($skip = 0, status = 'draft', extraParams = {}) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    status,
    promise: ({ app }: any) => {
      const moreParams = status === 'rejected' ? { requestedRejectValidation: -1 } : {};
      return app.service('me/stories/i18n').find({
        query: {
          status,
          $sort: { ...moreParams, publishDate: -1, createdAt: -1 },
          $limit: 10,
          $skip,
          ...extraParams,
        },
      });
    },
  };
}

// eslint-disable-next-line default-param-last
export function loadByPublication($skip = 0, publication: any, status = 'draft', extraParams = {}) {
  return {
    types: [LOAD_BY_PUBLICATION, LOAD_BY_PUBLICATION_SUCCESS, LOAD_BY_PUBLICATION_FAIL],
    status,
    publication,
    promise: ({ app }: any) => {
      const extraPrams = status === 'rejected' ? { requestedRejectValidation: -1 } : {};
      return app.service('me/stories/i18n').find({
        query: {
          status,
          publication,
          $sort: { ...extraPrams, publishDate: -1, createdAt: -1 },
          $limit: 10,
          $skip,
          ...extraParams,
        },
      });
    },
  };
}

export function save(stories: any, index: any) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    uid: stories.uid,
    index,
    promise: async ({ app }: any) => app.service('me/stories/i18n').patch(stories.uid, stories),
  };
}

export function share(stories: any) {
  return {
    types: [SHARE, SHARE_SUCCESS, SHARE_FAIL],
    uid: stories.uid,
    promise: async ({ app }: any) => {
      const { timeout } = app.service('me/stories/i18n');
      app.service('me/stories/i18n').timeout = 60000;
      return app
        .service('me/stories/i18n')
        .patch(stories.uid, { socialSahre: true, ...stories })
        .then((result: any) => {
          app.service('me/stories/i18n').timeout = timeout;
          return result;
        })
        .catch((error: any) => {
          app.service('me/stories/i18n').timeout = timeout;
          throw error;
        });
    },
  };
}

export function isLoadePost(globalState: any, postId: any) {
  return (
    globalState.meStories &&
    globalState.meStories.loadedPost &&
    globalState.meStories.loadedPost[postId]
  );
}

export function create() {
  return {
    types: [LOAD_POST, LOAD_POST_SUCCESS, LOAD_POST_FAIL],
    promise: ({ app }: any) =>
      app.service('me/stories/i18n').create({
        title: '',
        subtitle: '',
        description: '',
        slug: '',
        content: [],
        draftContent: { content: null },
        lastRevisionSaved: '',
        locale: 'en',
        featureImg: '',
        kicker: '',
      }),
  };
}

export function createNew() {
  return {
    types: [CREATE_POST, CREATE_POST_SUCCESS, CREATE_POST_FAIL],
    promise: ({ app }: any) =>
      app.service('me/stories/i18n').create({
        title: '',
        subtitle: '',
        description: '',
        slug: '',
        content: [],
        draftContent: { content: null },
        lastRevisionSaved: '',
        locale: 'en',
        featureImg: '',
        kicker: '',
      }),
  };
}

export function loadDraft() {
  return {
    types: [LOAD_POST, LOAD_POST_SUCCESS, LOAD_POST_FAIL],
    promise: ({ app }: any) =>
      app
        .service('me/stories/i18n')
        .find({
          query: {
            'Stories.status': 'draft',
            $sort: { createdAt: -1 },
            $limit: 1,
          },
        })
        .then((data: any) => {
          if (data.total === 0) {
            return app.service('me/stories/i18n').create({
              title: '',
              subtitle: '',
              description: '',
              slug: '',
              content: [],
              draftContent: { content: null },
              lastRevisionSaved: '',
              locale: 'en',
              featureImg: '',
              kicker: '',
            });
          }

          return data.data[0];
        }),
  };
}

export function loadPost(postId: any) {
  return {
    types: [LOAD_POST, LOAD_POST_SUCCESS, LOAD_POST_FAIL],
    promise: ({ app }: any) =>
      app
        .service('me/stories/i18n')
        .find({
          query: {
            uid: postId,
            $sort: { createdAt: -1 },
            $limit: 1,
          },
        })
        .then((data: any) => data.data[0]),
  };
}

export function loadPostByKey(postId: any, key: any) {
  return {
    key,
    types: [LOAD_POST_BY_KEY, LOAD_POST_SUCCESS_BY_KEY, LOAD_POST_FAIL_BY_KEY],
    promise: ({ app }: any) =>
      app
        .service('me/stories/i18n')
        .find({
          query: {
            uid: postId,
            $sort: { createdAt: -1 },
            $limit: 1,
          },
        })
        .then((data: any) => data.data[0]),
  };
}

export function savePost(stories: any) {
  return {
    types: [SAVE_POST, SAVE_POST_SUCCESS, SAVE_POST_FAIL],
    uid: stories.uid,
    promise: async ({ app }: any) => app.service('me/stories/i18n').patch(stories.uid, stories),
  };
}

export function savePostI18n(stories: any, draftContent?: any): any {
  return {
    publish: stories.publish,
    types: [SAVE_POST_I18N, SAVE_POST_I18N_SUCCESS, SAVE_POST_I18N_FAIL],
    draftContent,
    promise: ({ app }: { app: Application }) => {
      // cancelable promise
      let resolve;
      let rejected;
      let cancel;
      const pending = true;
      const promise = new Promise((_resolve, _rejected) => {
        resolve = _resolve;
        rejected = _rejected;
        cancel = () => {
          // @ts-expect-error hide this
          promise.pending = false;
          // @ts-expect-error hide this
          _resolve(Promise.pending());
        };
        app
          .service('me/stories/i18n')
          .patch(stories.uid || 'create', stories)
          .then((result) => {
            // @ts-expect-error hide this
            promise.pending = false;
            _resolve(result);
          })
          .catch((error: any) => {
            // @ts-expect-error hide this
            promise.pending = false;
            _rejected(error);
          });
      });
      // @ts-expect-error hide this
      promise.cancel = cancel;
      // @ts-expect-error hide this
      promise.resolve = resolve;
      // @ts-expect-error hide this
      promise.rejected = rejected;
      // @ts-expect-error hide this
      promise.pending = pending;
      return promise;
    },
  };
}

export function restoreToUnsaveState(timeStaySaved = 2000) {
  return (dispatch: any) => {
    return setTimeout(() => dispatch({ type: SAVE_POST_I18N_RESTORE_TO_UNSAVE }), timeStaySaved);
  };
}

export function restoreToSaveState(/* timeStaySaved = 2000 */) {
  return (dispatch: any, getStore: any) => {
    const store = getStore();
    if (!store.meStories.saved) {
      return dispatch({ type: SAVE_POST_I18N_RESTORE_TO_SAVE });
    }
  };
}

export function deleteStoryAll(uid: any) {
  return {
    types: [DELETE_ALL_POST_I18N, DELETE_ALL_POST_I18N_SUCCESS, DELETE_ALL_POST_I18N_FAIL],
    uid,
    promise: async ({ app }: any) => app.service('me/stories').patch(uid, { status: 'deleted' }),
  };
}

export function deleteStory(uid: any, storyToDelete: any, publication = null) {
  return {
    types: [DELETE_POST_I18N, DELETE_POST_I18N_SUCCESS, DELETE_POST_I18N_FAIL],
    uid,
    storyToDelete,
    publication,
    promise: async ({ app }: any) =>
      app.service('me/stories/i18n').patch(uid, { status: 'deleted' }),
  };
}

export function changeStatusStory(
  uid: any,
  story: any,
  status: 'draft' | 'pre-public' | 'public' | 'deleted' | 'rejected',
  // eslint-disable-next-line default-param-last
  publication = null,
  extraData?: any
) {
  return {
    types: [CHANGE_STATUS_POST_I18N, CHANGE_STATUS_POST_I18N_SUCCESS, CHANGE_STATUS_POST_I18N_FAIL],
    uid,
    story,
    publication,
    status,
    promise: async ({ app }: any) =>
      app.service('me/stories/i18n').patch(uid, { status, ...extraData }),
  };
}

export function deleteStoryUnrestoreAll(uid: any) {
  return {
    types: [
      DELETE_ALL_UNRESTORE_POST_I18N,
      DELETE_ALL_UNRESTORE_POST_I18N_SUCCESS,
      DELETE_ALL_UNRESTORE_POST_I18N_FAIL,
    ],
    uid,
    promise: async ({ app }: any) => app.service('me/stories').remove(uid),
  };
}

export function deleteUnrestoreStory(uid: any, storyToDelete: any, publication = null) {
  return {
    types: [
      DELETE_UNRESTORE_POST_I18N,
      DELETE_UNRESTORE_POST_I18N_SUCCESS,
      DELETE_UNRESTORE_POST_I18N_FAIL,
    ],
    uid,
    storyToDelete,
    publication,
    promise: async ({ app }: any) => app.service('me/stories/i18n').remove(uid),
  };
}
